import { useField } from 'formik';
import { Radio } from 'semantic-ui-react';
import React from 'react';

const FormikRadio = (props: any) => {
  const [field, meta, helpers] = useField(props.name);

  return (
    <Radio
      {...props}
      onChange={(e, v: any) => {
        helpers.setValue(v.value)
      }}
    />
  )
}

export default FormikRadio;
