import React, { FC, useContext, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { DataContext } from '../../../context/dataContext';
import { Field, Formik, FormikValues } from 'formik';
import * as Yup from 'yup';
import { Button, Form } from 'semantic-ui-react';
import {AssetTypeOption} from './dropdown-data';
import { scrollToDefaultOptions } from '../../../helpers/scroll-to-options';

interface AssetsConciergeProps {
  loanData: any
  assetTypeOptions: AssetTypeOption[]
  setAssetTypes: Function
  hasCoborrower?: boolean
}

const AssetsConcierge: FC<AssetsConciergeProps> = observer(({
  loanData,
  assetTypeOptions,
  setAssetTypes,
  hasCoborrower
}) => {
  const { setDarkThemeOverride, setOverrideTrackerVisibility } = useContext(DataContext);

  setDarkThemeOverride(true)
  setOverrideTrackerVisibility(true)

  const borrowerNameCopy = hasCoborrower ?
    `${loanData.borrower.first_name} or ${loanData.coborrower?.first_name}`
    : loanData.borrower.first_name

  const handleClearAssetTypes = (values: FormikValues) => {
    values.assetTypes = []
    assetTypeOptions.map(option => option.checked = false)
  }

  useEffect(() => {
    window.scrollTo(scrollToDefaultOptions)
  }, [])

  useEffect(() => {
    assetTypeOptions.map(option => option.checked = false)
  }, [loanData])

  return (
    <Formik
      initialValues={{
        assetTypes: [],
        noAssets: false,

        submit: null
      }}
      validationSchema={Yup.object().shape({})}
      onSubmit={(values, {
        setErrors,
        setStatus,
        setSubmitting,
        resetForm
      }) => {
        try {
          const assetTypes = values.noAssets ? ['none'] : values.assetTypes
          setAssetTypes(assetTypes)

          setStatus({ success: true })
          setSubmitting(false)
          resetForm({})
        } catch (err: any) {
          console.error('AssetsConcierge error: ', err.message);
          setStatus({ success: false })
          setErrors({ submit: err.message })
          setSubmitting(false)
        }
      }}
    >
      {({
        values,
        handleSubmit,
        isSubmitting
      }) => (
        <form
          className="choice-form application-step-container assets-form"
          onSubmit={handleSubmit}
        >
          <div className="question-container">
            <div className="form-step">
              <Form.Field>
                <h1 className="title assets concierge">
                  {borrowerNameCopy}, please select ALL types of Assets
                  <br />
                  that apply to you.
                </h1>
                <div className="choice-container">
                  {assetTypeOptions.map((option: any) => (
                    <div
                      key={option.value}
                      className={`answer-container ${values.noAssets && 'disabled'}`}
                    >
                      <label className={`choice-answers ${option.checked} ${values.noAssets ? 'none-selected' : ''}`}>
                        <Field
                          type="checkbox"
                          name="assetTypes"
                          value={option.value}
                          disabled={values.noAssets}
                          onClick={() => values.noAssets ? null : option.checked = !option.checked}
                        />
                        <i className={`icon ${!option.checked || values.noAssets ? 'angle right unselected' : 'check selected'}`} />
                        {option.text}
                      </label>
                    </div>
                  ))}
                  <div className="answer-container">
                    <label className="choice-answers">
                      <Field
                        type="checkbox"
                        name="noAssets"
                        onClick={() => handleClearAssetTypes(values)}
                      />
                      <i className={`icon ${values.noAssets ? 'check selected' : 'angle right unselected'}`} />
                      I do not have any sources of Assets
                    </label>
                  </div>
                </div>
              </Form.Field>
            </div>
            <Button
              disabled={isSubmitting || (values.assetTypes.length === 0 && !values.noAssets)}
              className="concierge-button"
              type="submit"
            >
              Save &amp; Continue
            </Button>
          </div>
        </form>
      )}
    </Formik>
  )
})

export default AssetsConcierge
