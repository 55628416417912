import React, { FC, useContext, useEffect, useState } from 'react';
import { Button, Form, Input, Modal } from 'semantic-ui-react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Redirect, useHistory } from 'react-router-dom';
import LoaderOverlay from '../../components/LoaderOverlay/LoaderOverlay';
import { DataContext } from '../../context/dataContext';
import { useStore } from '@jmjfinancial/apis/lib';
import { observer } from 'mobx-react-lite';
import { handleError } from '../../helpers/response-handler';
import {scrollToDefaultOptions} from '../../helpers/scroll-to-options';
import PasswordValidation from '../../components/PasswordValidation/PasswordValidation';
import { clearValues } from '../../helpers/clear-values-helper';
import ForgotPasswordModal from '../../components/Modals/ForgotPasswordModal/ForgotPasswordModal';

const PasswordReset: FC = observer(() => {
  const store = useStore();
  const { authService } = store;
  const history = useHistory();
  const { user } = useContext(DataContext)
  const queryParams = new URLSearchParams(window.location.search)
  const token = queryParams.get('token') || ''

  const [characterVal, setCharacterVal] = useState<boolean>(false)
  const [numberVal, setNumberVal] = useState<boolean>(false)
  const [uppercaseVal, setUppercaseVal] = useState<boolean>(false)
  const [lowercaseVal, setLowercaseVal] = useState<boolean>(false)
  const [symbolVal, setSymbolVal] = useState<boolean>(false)
  const [showModal, setShowModal] = useState<boolean>(false)
  const [errorModal, setErrorModal] = useState<boolean>(false)

  const handleValidatePassword = (password: string) => {
    setCharacterVal(characterVal => password.length >= 8);
    setNumberVal(numberVal => /\d/.test(password));
    setUppercaseVal(uppercaseVal => /[A-Z]/.test(password));
    setLowercaseVal(lowercaseVal => /[a-z]/.test(password));
    setSymbolVal(symbolVal => /\W|_/g.test(password))
  }

  const handleClearValidatePassword = () => {
    setCharacterVal(characterVal => false);
    setNumberVal(numberVal => false);
    setUppercaseVal(uppercaseVal => false);
    setLowercaseVal(lowercaseVal => false);
    setSymbolVal(symbolVal => false)
  }

  useEffect(() => {
    window.scrollTo(scrollToDefaultOptions)
  }, [])

  return (
    !user ? (
      <div className="landing-container">
        <div className="landing-content">
          <h1 className="title">Enter New Password</h1>
          <Formik
            initialValues={{
              password: '',
              confirmPassword: '',
              submit: null
            }}
            validationSchema={Yup.object().shape({
              password:
                Yup.string()
                  .min(8, ' ')
                  .max(255)
                  .matches(
                    /^(?:(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*["!#$%&'()*+,\-.\\/:;<=>?@[\]^_`{|}~]).*)$/,
                    'Password requirements not met'
                  )
                  .required('Password is required'),
              confirmPassword:
                Yup.string()
                  .min(8, ' ')
                  .max(225)
                  .oneOf([Yup.ref('password'), null], 'Passwords must match')
                  .required('Password confirmation is required')
            })}
            validateOnBlur={false}
            onSubmit={async (values, {
              setErrors,
              setStatus,
              setSubmitting
            }) => {
              try {
                await authService.reset(values.password, token).then(res => {
                  if (res.data.message === "password updated") {
                    setStatus({ success: true });
                    setSubmitting(false);
                  } else if (res.data.error === "Link not valid or expired. Try generating a new link.") {
                    setErrorModal(true);
                  }
                  setShowModal(true)
                });

                clearValues(values, [
                  'password',
                  'confirmPassword'
                ])

                handleClearValidatePassword()
              } catch (err: any) {
                handleError(err);
                setStatus({ success: false });
                setErrors({ submit: err.message });
                setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <>
                <LoaderOverlay
                  active={isSubmitting}
                  label="Resetting password..."
                />
                <form
                  noValidate
                  onSubmit={handleSubmit}
                  className="auth-form"
                >
                  <div className="error-container">
                    {(touched.password && errors.password)
                      || (touched.confirmPassword && errors.confirmPassword)
                      || errors.submit
                    }
                  </div>
                  <Form.Field>
                    <Input
                      name="password"
                      placeholder="New Password"
                      type="password"
                      className="auth-field"
                      value={values.password}
                      onBlur={handleBlur}
                      onChange={(e) => {
                        handleChange(e)
                        handleValidatePassword(e.target.value)
                      }}
                      error={!!(touched.password && errors.password)}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Input
                      name="confirmPassword"
                      placeholder="Confirm New Password"
                      type="password"
                      className="auth-field"
                      value={values.confirmPassword}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={!!(touched.confirmPassword && errors.confirmPassword)}
                    />
                  </Form.Field>
                  <PasswordValidation
                    characterVal={characterVal}
                    numberVal={numberVal}
                    uppercaseVal={uppercaseVal}
                    lowercaseVal={lowercaseVal}
                    symbolVal={symbolVal}
                    headerText="New password must contain at least:"
                  />
                  <ForgotPasswordModal
                    showModal={showModal}
                    setShowModal={setShowModal}
                    errorModal={errorModal}
                    password={true}
                  />
                  <div className="landing-controls form-controls">
                    <Button
                      type="submit"
                      className="landing-button auth-button"
                    >
                      Submit
                    </Button>
                  </div>
                </form>
              </>
            )}
          </Formik>
        </div>
        <div className="landing-graphic" />
      </div>
    ) : <Redirect to={'/dashboard'} />
  )
});

export default PasswordReset;
