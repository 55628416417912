import React from 'react';
import { handleError } from '../../helpers/response-handler';

export class ErrorBoundary extends React.Component {
  state = { hasError: false }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  componentDidCatch(error: any, errorInfo: any) {
    // You can also log the error to an error reporting service
    handleError(error, 'ErrorBoundary: ')
    handleError(errorInfo, 'ErrorBoundary Info: ')
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Oops! Looks like an error occurred. No worries, we've saved your place. <br/>Please log back in or contact support if the error persists.</h1>;
    }

    return this.props.children; 
  }
}
