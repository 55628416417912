import React, { FC, useContext, useState } from 'react';
import * as Yup from 'yup';
import { Form } from 'semantic-ui-react';
import {Formik} from 'formik';
import { AssetFormProps } from '../EditAssetForm';
import { currencyMask } from '../../../../helpers/currency-mask-input';
import _ from 'lodash';
import { useStore } from '@jmjfinancial/apis/lib';
import { DataContext } from '../../../../context/dataContext';
import ApplicationFormFooter from '../../shared/ApplicationFormFooter';
import AssetFormHeader from './AssetFormHeader';
import {AssetTypeEnumReverse, BasicAssetType} from '../asset-types';
import FormikMaskedInput from '../../../../components/Formik/FormikMaskedInput';

const BasicAssetForm: FC<AssetFormProps> = ({
  loanData,
  onCancelClick,
  updateAssetFormView,
  basicAssetType,
  conciergeMode,
  hasCoborrower,
  saveForm,
  setShowForm,
  formData
}) => {
  const store = useStore();
  const { loansService } = store;

  const { activeLoan } = useContext(DataContext);

  const [disableOnErrors, setDisableOnErrors] = useState<boolean>(false);
  const [showErrorContainer, setShowErrorContainer] = useState<boolean>(false);

  const balanceLabelCopy = (assetType: BasicAssetType): string => {
    switch (assetType) {
      case 'checkingOrSavings':
      default:
        return `What is the estimated combined value of all your checking & savings accounts?`
      case 'stocksOrBonds':
        return 'Total estimated amount of stocks and/or bonds being liquidated?'
      case 'retirement':
        return 'What is the total estimated amount of retirement funds being liquidated?'
      case 'gift':
        return 'What is the total amount of gifts received?'
    }
  }

  return (
    <>
      <AssetFormHeader
        ownerName={loanData.borrower.first_name}
        basicAssetType={basicAssetType}
        conciergeMode={conciergeMode}
        hasCoborrower={hasCoborrower}
      />
      <Formik
        initialValues={{
          amount: formData?.amount || '',

          submit: null
        }}
        validationSchema={Yup.object().shape({
          amount: Yup.string().required('Amount is required.')
        })}
        onSubmit={(values, {
          setErrors,
          setStatus,
          setSubmitting,
          resetForm
        }) => {
          try {
            const basicAssetKey = AssetTypeEnumReverse[basicAssetType as keyof typeof AssetTypeEnumReverse]

            const changedData = {
              [basicAssetKey]: values.amount.replace(/[$,]/g, '')
            }

            if (conciergeMode) {
              const dataSubmit = _.merge(loanData, { borrower: changedData })
              loansService.updateLoan(activeLoan, dataSubmit).then((res: any) => {
                if (res.success) {
                  updateAssetFormView(true)
                  setStatus({ success: true });
                  setSubmitting(false);
                  resetForm({});
                }
                else {
                  setStatus({ success: false });
                  setErrors({ submit: res.errors?.base[0] || res.error });
                  setSubmitting(false);
                }
              })
            }
            else {
              saveForm({
                basic_asset_type: basicAssetType as string,
                amount: values.amount.replace(/[$,]/g, '')
              })

              setStatus({ success: true });
              setSubmitting(false);
              resetForm({});
              setShowForm(false)
            }
          } catch (error: any) {
            console.log(`Asset source error: ${error.message}`);
            setStatus({ success: false });
            setErrors({ submit: error.message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values
        }) => {
          return (
            <form
              className="application-form"
              onSubmit={handleSubmit}
            >
              <div className="form-step">
                <Form.Field>
                  <label>{balanceLabelCopy(basicAssetType)}</label>
                  <FormikMaskedInput
                    id="balance"
                    name="amount"
                    placeholder={'Account Balance'}
                    type="text"
                    value={values.amount}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    mask={currencyMask}
                    guide={false}
                    error={!!(touched.amount && errors.amount)}
                  />
                </Form.Field>
              </div>
              <ApplicationFormFooter
                onCancelClick={onCancelClick}
                nextDisabled={isSubmitting || disableOnErrors}
                errors={errors}
                showErrorContainer={showErrorContainer}
                setShowErrorContainer={setShowErrorContainer}
                setDisableOnErrors={setDisableOnErrors}
                errorMessage={errors.submit ? errors.submit : undefined}
              />
            </form>
          )}
        }
      </Formik>
    </>
  )
}

export default BasicAssetForm
