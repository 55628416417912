import _ from 'lodash';
import React, { FC, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Icon, Modal } from 'semantic-ui-react';
import { DataContext } from '../../context/dataContext';
import { useStore } from '@jmjfinancial/apis/lib';
import { ApplicationRoutes } from '../../views/LoanApplication/index';
import './income-modal.scss';

interface IncomeModalProps {
  setShowModal: Function;
  showModal: boolean;
  setShowForm: Function;
  tableData: any;
  loanData: any;
  modalHeader: string;
  modalBody: string;
  conciergeMode: any;
  incomeTypes: any;
  updateIncomeFormView: Function;
}

const IncomeModal: FC<IncomeModalProps> = ({
  setShowModal,
  showModal,
  setShowForm,
  tableData,
  loanData,
  modalHeader,
  modalBody,
  conciergeMode,
  incomeTypes,
  updateIncomeFormView,
}) => {
  const store = useStore();
  const { loansService } = store;

  const { activeLoan, pathname, reviewMode } = useContext(DataContext);
  const history = useHistory();

  const handleNextClick = () => {
    if (reviewMode) {
      history.push('/application/review-and-submit')
    }
    else {
      history.push('/application/assets')
    }
  }

  const handleSubmit = async () => {
    setShowModal(false);

    if (conciergeMode && incomeTypes.length > 0 ) {
      updateIncomeFormView();
    }
    else {
      const incomesArray: any = [];
      const employmentsArray: any = [];

      tableData.forEach((income: any) => {
        if (income.income_type === 'W-2 Wage Earner' || income.income_type === "Self Employed") {
          employmentsArray.push(income)
        } else {
          incomesArray.push(income)
        }
      })

      const updatedLoan = {
        application: {
          current_step: ApplicationRoutes[pathname as keyof typeof ApplicationRoutes] + 1,
          completed_steps: {
            employment_and_income_done: true

          }
        },
        incomes: incomesArray,
        employments: employmentsArray
      }

      const dataSubmit = _.merge(loanData, updatedLoan);

      await loansService.updateLoan(activeLoan, dataSubmit);
      handleNextClick();
    }
  }

  return (
    <Modal
      dimmer="blurring"
      className="default-modal income-modal"
      open={showModal}
      onClose={() => setShowModal(false)}
      onOpen={() => setShowModal(true)}
    >
      <Icon
        name="close"
        className="close-icon"
        onClick={() => setShowModal(false)}
      />
      <span className="modal-header">{modalHeader}</span>
      <span className="modal-subheader">{modalBody}</span>
      <div className="modal-footer">
        <Button
          type="button"
          autoFocus
          className="alternate-button orange"
          onClick={() => handleSubmit()}
        >
          No
        </Button>
        <Button
          type="button"
          className="alternate-button blue"
          onClick={() => {
            if (conciergeMode) {
              setShowModal(false)
            } else {
              setShowModal(false)
              setShowForm(true)
            }
          }}
        >
          Yes
        </Button>
      </div>
    </Modal>
  )
}

export default IncomeModal;
