import {AssetTypeText, AssetType} from './asset-types';

export interface AssetTypeOption {
  text: AssetTypeText
  value: AssetType
  checked?: boolean
}

export const assetOptions: AssetTypeOption[] = [
  {
    text: 'Checking & Savings Account',
    value: 'checkingOrSavings',
    checked: false
  },
  {
    text: 'Liquidating Stocks and/or Bonds',
    value: 'stocksOrBonds',
    checked: false
  },
  {
    text: 'Liquidating or borrowing from a retirement account',
    value: 'retirement',
    checked: false
  },
  {
    text: 'Gift',
    value: 'gift',
    checked: false
  }
]
