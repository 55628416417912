import { Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, {FC, useContext, useEffect} from 'react';
import { DataContext } from '../../../context/dataContext';
import * as Yup from 'yup';
import { Button, Form } from 'semantic-ui-react';
import './real-estate-owned.scss';
import {scrollToDefaultOptions} from '../../../helpers/scroll-to-options';

interface REOConciergeProps {
  loanData: any
  showREOForm: Function;
}

const REOConcierge: FC<REOConciergeProps> = observer(({
  loanData,
  showREOForm,
}) => {
  const { setDarkThemeOverride, setOverrideTrackerVisibility } = useContext(DataContext);

  const borrowerName = loanData.borrower.first_name

  setDarkThemeOverride(true)
  setOverrideTrackerVisibility(true)

  useEffect(() => {
    window.scrollTo(scrollToDefaultOptions)
  }, [])

  return (
    <Formik
      initialValues={{
        submit: null
      }}
      validationSchema={Yup.object().shape({})}
      onSubmit={(values, {
        setErrors,
        setStatus,
        setSubmitting,
        resetForm
      }) => {
        try {
          setStatus({ success: true });
          setSubmitting(false);
          resetForm({});

          showREOForm(true);
        }
        catch (error: any) {
          setStatus({ success: false });
          setErrors({ submit: error.message });
          setSubmitting(false);
        }
      }}
    >
      {({handleSubmit}) => (
        <div className="application-step-container real-estate-owned-container">
          <form className="application-form" onSubmit={handleSubmit}>
            <div className="form-step">
              <Form.Field>
                <h1 className="title choice-form concierge">
                  {borrowerName}, let's discuss all the real estate you currently own.
                </h1>
              </Form.Field>
            </div>
            <Button
              color="orange"
              type="submit"
            >
              Save &amp; Continue
            </Button>
          </form>
        </div>
      )}
    </Formik>
  )
})

export default REOConcierge;
