import React, { FC, useEffect, useRef } from 'react';
import { Button, Form } from 'semantic-ui-react';
import { Formik } from 'formik';
import FormikRadio from '../../../components/Formik/FormikRadio';
import FormikErrorMessage from '../../../components/Formik/ErrorMessage';
import * as Yup from 'yup';
import { observer } from 'mobx-react-lite';
import { scrollIntoViewDefaultOptions } from '../../../helpers/scroll-to-options';

interface AddAnotherInstitutionFormProps {
  setRefreshAssetsView: Function
  setShowAddAnotherForm: Function
}

const AddAnotherInstitutionForm: FC<AddAnotherInstitutionFormProps> = observer(({
  setRefreshAssetsView,
  setShowAddAnotherForm
}) => {
  const formRef = useRef<HTMLFormElement>(null)

  useEffect(() => {
    if (formRef && formRef.current) {
      formRef.current.scrollIntoView(scrollIntoViewDefaultOptions)
    }
  }, [])

  return (
    <Formik
      initialValues={{
        additionalSource: null,

        submit: null
      }}
      validationSchema={Yup.object().shape({
        additionalSource: Yup.string().nullable().required('This field is required')
      })}
      onSubmit={(values, {
        setErrors,
        setStatus,
        setSubmitting,
        resetForm,
      }) => {
        try {
          if (values.additionalSource === 'false') {
            setRefreshAssetsView((refreshAssetsView: boolean) => true)
          }
          setStatus({ success: true })
          setSubmitting(false)
          resetForm()
          setShowAddAnotherForm((showAddAnotherForm: boolean) => false)
        } catch (err: any) {
          console.error('AddAnotherInstitutionForm: ', err.message);
          setStatus({ success: false });
          setErrors({ submit: err.message });
          setSubmitting(false);
        }
      }}
    >
      {({
        handleSubmit,
        isSubmitting,
        values
      }) => (
        <form
          ref={formRef}
          onSubmit={handleSubmit}
          className="application-form"
        >
          <div className="form-step">
            <Form.Field>
              <label>Do you have accounts at any additional banks?</label>
              <div className="radio-group">
                <FormikRadio
                  label="Yes"
                  name="additionalSource"
                  value="true"
                  checked={values.additionalSource === 'true'}
                />
                <FormikRadio
                  label="No"
                  name="additionalSource"
                  value="false"
                  checked={values.additionalSource === 'false'}
                />
              </div>
              <FormikErrorMessage name="additionalSource" />
            </Form.Field>
          </div>
          <div className="button-container additional-sources">
            <Button
              disabled={isSubmitting || values.additionalSource === null}
              color="blue"
              type="submit"
            >
              Save &amp; Continue
            </Button>
          </div>
        </form>
      )}
    </Formik>
  )
})

export default AddAnotherInstitutionForm
