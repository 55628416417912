import { FC, useState } from 'react';
import { Button, Form, Icon, Input, List, Modal } from 'semantic-ui-react';
import * as Yup from 'yup';
import { Formik, FormikErrors } from 'formik';
import '../../styles/_modals.scss'
import {ModalProps} from '../../types/modal';
import { observer } from 'mobx-react-lite';
import { useStore } from '@jmjfinancial/apis/lib';
import { clearValues } from '../../helpers/clear-values-helper';
import LoaderOverlay from '../../components/LoaderOverlay/LoaderOverlay';
import PasswordValidation from '../../components/PasswordValidation/PasswordValidation';
import { number } from 'yup/lib/locale';

const PasswordResetModal: FC<ModalProps> = observer(({
  showModal,
  setShowModal
}) => {
  const store = useStore()
  const { authService } = store

  const [modalMessage, setModalMessage] = useState<string>()
  const [isSavingChanges, setIsSavingChanges] = useState<boolean>(false)
  const [passwordUpdated, setPasswordUpdated] = useState<boolean>(false)
  const [characterVal, setCharacterVal] = useState<boolean>(false)
  const [numberVal, setNumberVal] = useState<boolean>(false)
  const [uppercaseVal, setUppercaseVal] = useState<boolean>(false)
  const [lowercaseVal, setLowercaseVal] = useState<boolean>(false)
  const [symbolVal, setSymbolVal] = useState<boolean>(false)
  const [disableFields, setDisableFields] = useState<boolean>(false)

  const handleCancelResetPassword = (errors?: FormikErrors<{
    currentPassword: string;
    newPassword: string;
    confirmPassword: string;
    submit: null;
  }>) => {
    setShowModal(false)
    setModalMessage('')
    setPasswordUpdated(passwordUpdated => false)
    errors && (errors = {})
    setCharacterVal(characterVal => false)
    setNumberVal(numberVal => false)
    setUppercaseVal(uppercaseVal => false)
    setLowercaseVal(lowercaseVal => false)
    setSymbolVal(symbolVal => false)
    setDisableFields(disableFields => false)
  }

  const handleValidatePassword = (password: string) => {
    setCharacterVal(characterVal => password.length >= 8);
    setNumberVal(numberVal => /\d/.test(password));
    setUppercaseVal(uppercaseVal => /[A-Z]/.test(password));
    setLowercaseVal(lowercaseVal => /[a-z]/.test(password));
    setSymbolVal(symbolVal => /\W|_/g.test(password))
  }

  return (
    <>
      {showModal && (
        <Modal
          dimmer="blurring"
          className="default-modal reset-password-modal"
          open={showModal}
          onClose={() => handleCancelResetPassword()}
          onOpen={() => setShowModal(true)}
        >
          <LoaderOverlay
            active={isSavingChanges}
            label="Saving Changes..."
            modal={true}
          />
          <Icon
            name="close"
            className="close-icon"
            onClick={() => handleCancelResetPassword()}
          />
          <Formik
            enableReinitialize
            initialValues={{
              currentPassword: '',
              newPassword: '',
              confirmPassword: '',
              submit: null
            }}
            validationSchema={Yup.object().shape({
              currentPassword: Yup
                .string()
                .required('Current Password is required'),
              newPassword: Yup
                .string()
                .min(8, 'Password must be at least 8 characters')
                .max(255)
                .notOneOf([Yup.ref('currentPassword')], 'New password cannot be the same as current password')
                .matches(
                  /^(?:(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*["!#$%&'()*+,\-.\\/:;<=>?@[\]^_`{|}~]).*)$/,
                  'Password requirements not met'
                )
                .required('New Password is required'),
              confirmPassword: Yup
                .string()
                .min(8, 'Password must be at least 8 characters')
                .max(255)
                .oneOf([Yup.ref('newPassword'), null], 'New password must match.')
                .required('Password confirmation is required'),
            })}
            validateOnBlur={false}
            onSubmit={(values, {
              setErrors,
              setStatus,
              setSubmitting,
            }) => {
              try {
                setIsSavingChanges(isSavingChanges => true)
                setPasswordUpdated(passwordUpdated => true)
                authService.resetPasswordInApp(values.currentPassword, values.newPassword)
                  .then((response: any) => {

                    if (response.status === 200) {
                      setStatus({ success: true })
                      setModalMessage('Password reset successfully')
                      clearValues(values, ['currentPassword', 'newPassword', 'confirmPassword'])
                    }
                    else {
                      setErrors({ submit: response.response.data.message })
                    }

                    setIsSavingChanges(isSavingChanges => false)
                  })
              } catch (err: any) {
                setErrors({ submit: err.response.data.message })
                setStatus({ success: false })
                setSubmitting(false)
              }
            }}
          >
            {({
              values,
              errors,
              handleSubmit,
              handleBlur,
              handleChange,
              isSubmitting,
              touched
            }) => (
              <form
                onSubmit={handleSubmit}
                className="password-reset-form"
              >
                <h2 className="title">Reset Password</h2>
                <div className="subtitle text-grey">
                  <PasswordValidation
                    characterVal={characterVal}
                    numberVal={numberVal}
                    uppercaseVal={uppercaseVal}
                    lowercaseVal={lowercaseVal}
                    symbolVal={symbolVal}
                    headerText="New password must contain at least:"
                  />
                </div>
                <div className="modal-content">
                  <Form.Field>
                    <Input
                      disabled={disableFields}
                      name="currentPassword"
                      placeholder="Current Password"
                      type="password"
                      value={values.currentPassword}
                      onBlur={handleBlur}
                      onChange={(e) => {
                        handleChange(e)
                        setModalMessage('')
                        setPasswordUpdated(passwordUpdated => false)
                      }}
                      error={!!(errors.currentPassword && touched.currentPassword)}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Input
                      disabled={disableFields}
                      name="newPassword"
                      placeholder="New Password"
                      type="password"
                      value={values.newPassword}
                      onBlur={handleBlur}
                      onChange={(e) => {
                        handleChange(e)
                        setModalMessage('')
                        setPasswordUpdated(passwordUpdated => false)
                        handleValidatePassword(e.target.value)
                      }}
                      error={!!(errors.newPassword && touched.newPassword)}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Input
                      disabled={disableFields}
                      name="confirmPassword"
                      placeholder="Confirm New Password"
                      type="password"
                      value={values.confirmPassword}
                      onBlur={handleBlur}
                      onChange={(e) => {
                        handleChange(e)
                        setModalMessage('')
                        setPasswordUpdated(passwordUpdated => false)
                      }}
                      error={!!(errors.confirmPassword && touched.confirmPassword)}
                    />
                  </Form.Field>
                </div>
                <div className="modal-footer">
                  <Button
                    type="button"
                    className="alternative-button"
                    onClick={() => handleCancelResetPassword(errors)}
                  >
                    {passwordUpdated ? 'Done' : 'Cancel'}
                  </Button>
                  <div className="message-container">
                    {
                      (touched.currentPassword && errors.currentPassword)
                      || (touched.newPassword && errors.newPassword)
                      || (touched.confirmPassword && errors.confirmPassword)
                      || errors.submit
                    }
                    <span className={errors.submit === undefined ? 'success-message' : ''}>{modalMessage}</span>
                  </div>
                  <Button
                    disabled={values.currentPassword === '' || Object.keys(errors).length > 0}
                    type="submit"
                    className="save-button"
                  >
                    Save Changes
                  </Button>
                </div>
              </form>
            )}
          </Formik>
        </Modal>
      )}
    </>
  )
})

export default PasswordResetModal
