import { useField } from 'formik';
import { Input } from 'semantic-ui-react';
import MaskedInput from 'react-text-mask';
import _ from 'lodash';

const FormikMaskedInput = (props: any) => {
  const [field, , helpers] = useField(props.name);

  return (
    <>
      {props.label && <label>{props.label}</label>}
      <Input className={props.className} error={props.error}>
        <MaskedInput
          {..._.omit(props, ['error'])}
          value={field.value}
          onChange={(e: any) => helpers.setValue(e.target.value)}
        />
      </Input>
    </>
  )
}

export default FormikMaskedInput;
