import React, { FC } from 'react'
import { isMobileOnly } from 'react-device-detect'
import { Popup, Icon, Header } from 'semantic-ui-react'
import {SemanticICONS} from 'semantic-ui-react/dist/commonjs/generic';

interface PopupProps {
  defaultIcon?: SemanticICONS
  iconClass?: string
  headerLabel?: string
  content: JSX.Element | string
  additionalClass?: string
  trigger?: JSX.Element
  on?: 'hover' | 'click' | 'focus' | ('hover' | 'click' | 'focus')[]
  offset?: [number, number?]
}

const HelperPopup: FC<PopupProps> = ({
  defaultIcon = 'info',
  iconClass = 'info circle large',
  headerLabel,
  content,
  additionalClass,
  trigger = (
    <Icon
      name={defaultIcon}
      className={`icon ${iconClass}`}
    />
  ),
  on = 'click',
  offset = [0, 0]
}) => {
  return (
    <Popup
      className={`popup-container ${additionalClass} ${isMobileOnly ? 'is-mobile' : ''}`}
      trigger={trigger}
      position={isMobileOnly ? 'bottom center' : 'bottom left'}
      basic
      wide
      on={on}
      offset={offset}
    >
      {headerLabel && <Header as="h2" content={`${headerLabel}`} />}
      {content}
    </Popup>
  )
}

export default HelperPopup
