import React, {FC} from 'react';
import {InvoiceProps} from './index';

const InvoiceComponent: FC<InvoiceProps> = ({
  loanData,
  invoice,
  invoiceId
}) => {
  const invoiceStatus = invoice?.status === 'paid' ? 'paid' : 'Due'

  const invoiceDueDate = () => {
    return invoiceStatus === 'paid' ? (
      <span className="invoice-due-date">
        Paid {new Date(invoice?.updated_at).toLocaleDateString()}
      </span>
    ) : (
      <span
        className={`
          invoice-due-date
          ${invoice?.overdue && 'invoice-overdue'}
        `}
      >
        Due {new Date(invoice?.due_by_date).toLocaleDateString()}
        {invoice?.overdue && ' - Past Due'}
      </span>
    )
  }

  return (
    <div className="invoice-container">
      <div className="invoice-header">
        <span className="invoice-title">Invoice from JMJ Financial</span>
        <span className="invoice-amount">${invoice?.amount}</span>
        {invoiceDueDate()}
      </div>
      <div className="invoice-context">
        <div className="context-row">
          <label>To</label>
          <span>{loanData?.borrower.first_name} {loanData?.borrower.last_name}</span>
        </div>
        <div className="context-row">
          <label>From</label>
          <span>JMJ Financial</span>
        </div>
        <div className="context-row">
          <label>Memo</label>
          <span>{invoice?.description}</span>
        </div>
      </div>
      <div className="invoice-charges">
        <span className="invoice-number">Invoice #{invoiceId}</span>
        {invoice?.line_items.map((lineItem: any, index: number) => (
          <div
            key={`${lineItem.name}-${index}`}
            className="charge-row"
          >
            <label>{lineItem.name}</label>
            <span>${lineItem.billed_amount}</span>
          </div>
        ))}
        <div className="charge-row total-row">
          <label>Amount {invoiceStatus}</label>
          <span>${invoice?.amount}</span>
        </div>
      </div>
      <div className="invoice-footer">
        <span>Questions? Contact us at <a href="mailto:info@jmj.me" target="_blank">info@jmj.me</a></span>
      </div>
    </div>
  )
}

export default InvoiceComponent
