import React, {FC} from 'react';

export interface DashSubDetails {
  label: string
}

const DashSubDetailsComponent: FC<DashSubDetails> = ({
  label,
}) => {
  return (
    <div className="dash-action sub-detail">
      <label>{label}</label>
    </div>
  )
}

export default DashSubDetailsComponent
