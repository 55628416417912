import React, {FC, useContext, useEffect, useRef, useState} from 'react';
import {AddRemoveFormProps} from '../../../components/AddRemoveTable/AddRemoveTable';
import {DataContext} from '../../../context/dataContext';
import {useStore} from '@jmjfinancial/apis/lib';
import {scrollIntoViewDefaultOptions} from '../../../helpers/scroll-to-options';
import {FieldArray, Formik, getIn} from 'formik';
import {Form, Input, Radio} from 'semantic-ui-react';
import ApplicationFormFooter from '../../LoanApplication/shared/ApplicationFormFooter';
import {assetWorksheetOptions, OwnerOption, retirementOptions, statementFrequencyOptions} from './dropdown-data';
import {currencyMask} from '../../../helpers/currency-mask-input';
import {
  HiveAssetWorksheetType,
  HiveRetirementType,
  HiveStatementFrequencyType
} from './asset-worksheet-types';
import FormikSelect from '../../../components/Formik/FormikSelect';
import {getPluralizedName} from '../../../helpers/pluralize-name';
import _ from 'lodash';
import * as Yup from 'yup';
import LoaderOverlay from '../../../components/LoaderOverlay/LoaderOverlay';
import {ACCOUNT_NUMBER_MASK} from '../../../helpers/regex-helper';
import FormikMaskedInput from '../../../components/Formik/FormikMaskedInput';
import AddAnotherInstitutionForm from './AddAnotherInstitutionForm';

export interface EditAssetWorksheetFormProps extends AddRemoveFormProps {
  loanData: any
  setRefreshAssetsView: Function
  conciergeMode?: boolean
  formData?: any
}

export interface WorksheetAsset {
  institution: string
  ownerDisplayName: string
  otherOwner?: string
  type: HiveAssetWorksheetType | HiveRetirementType
  retirementType?: HiveRetirementType
  otherType?: string
  accountNumber: string
  accountBalance: string
  statementFrequency: HiveStatementFrequencyType
  addAnother?: string
}

const EditAssetWorksheetForm: FC<EditAssetWorksheetFormProps> = ({
  loanData,
  setRefreshAssetsView,
  conciergeMode,
  saveForm,
  setShowForm,
  formData
}) => {
  const store = useStore();
  const { loansService } = store;

  const { activeLoan, setDarkThemeOverride } = useContext(DataContext)

  const [assets] = useState<WorksheetAsset[]>(formData && [{
    institution: formData.institution,
    ownerDisplayName: formData.owner_display_name === formData.other_owner_name ? 'other' : formData.owner_display_name,
    otherOwner: formData.other_owner_name,
    type: (
      (
        formData.asset_type === 'retirement_401k'
        || formData.asset_type === 'ira'
        || formData.asset_type === 'retirement_403b'
        || formData.asset_type === 'retirement_457b'
        || formData.asset_type === 'retirement_other'
      ) ? 'retirement_funds' : formData.asset_type
    ),
    retirementType: (
      (
        formData.asset_type === 'retirement_401k'
        || formData.asset_type === 'ira'
        || formData.asset_type === 'retirement_403b'
        || formData.asset_type === 'retirement_457b'
        || formData.asset_type === 'retirement_other'
      ) ? formData.asset_type : undefined
    ),
    otherType: formData.other_asset_type,
    accountNumber: formData.account_number,
    accountBalance: formData.total_value,
    statementFrequency: formData.statement_frequency,
    addAnother: 'false'
  }])
  const [ownerOptions, setOwnerOptions] = useState<OwnerOption[]>([])
  const [disableOnErrors, setDisableOnErrors] = useState<boolean>(false)
  const [showErrorContainer, setShowErrorContainer] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState(false)
  const [showAddAnotherForm, setShowAddAnotherForm] = useState<boolean>(false)

  const formRef = useRef<HTMLDivElement>(null)

  setDarkThemeOverride(false)

  const handleCancelClick = () => {
    if (conciergeMode) {
      setRefreshAssetsView((refreshAssetsView: boolean) => true)
    }
    else {
      setShowForm((showForm: boolean) => false)
    }
  }

  /**
   * Builds owner dropdown options by conditionally adding co-borrower
   * and self employments if they exist
   */
  const handleGenerateOwnerOptions = () => {
    let generatedOwnerOptions: OwnerOption[] = []
    // Adds all self employments
    let selfEmployments: OwnerOption[] = loanData.employments
      .filter((employment: any) => employment.self_employed)
      .map((employment: any) => (
        {
          text: employment.employer_name,
          value: employment.employer_name,
          borrowerid: employment.borrower_id,
          employmentid: employment.id
        }
      ))
      // Adds coborrowers name to the text/value values if there is a duplicate borrower self employment name
      .filter((employment: any, index: number, self: any) => {
        const duplicateFound = index !== self.findIndex((e: any) => (
          e.text === employment.text
        ))

        if (duplicateFound && loanData.coborrower) {
          const pluralizedName = getPluralizedName(employment.owner === 'borrower' ? loanData.borrower.first_name : loanData.coborrower?.first_name, false)
          employment.text = `${employment.text} (${pluralizedName})`
          employment.value = `${employment.value} (${pluralizedName})`
        }

        return employment
      })

    generatedOwnerOptions = [
      {
        text: `${loanData.borrower.first_name} ${loanData.borrower.last_name}`,
        value: `${loanData.borrower.first_name} ${loanData.borrower.last_name}`,
        borrowerid: loanData.borrower.id
      },
      ...selfEmployments,
      {
        text: 'Other',
        value: 'other',
        borrowerid: loanData.borrower.id
      }
    ]

    if (loanData.coborrower) {
      generatedOwnerOptions.splice(1, 0, {
        text: `${loanData.coborrower.first_name} ${loanData.coborrower.last_name}`,
        value: `${loanData.coborrower.first_name} ${loanData.coborrower.last_name}`,
        borrowerid: loanData.coborrower.id
      })
      generatedOwnerOptions.splice(2, 0, {
        text: 'Shared',
        value: 'shared',
        borrowerid: loanData.borrower.id
      })
    }

    setOwnerOptions(ownerOptions => generatedOwnerOptions)
  }

  useEffect(() => {
    handleGenerateOwnerOptions()
  }, [loanData])

  useEffect(() => {
    if (formRef && formRef.current) {
      formRef.current.scrollIntoView(scrollIntoViewDefaultOptions)
    }
  }, [])

  return showAddAnotherForm ? (
    <AddAnotherInstitutionForm
      setRefreshAssetsView={setRefreshAssetsView}
      setShowAddAnotherForm={setShowAddAnotherForm}
    />
  ) : (
    <>
      <LoaderOverlay
        active={isLoading}
        label="Saving your assets..."
        modal
      />
      <div
        ref={formRef}
        className="form-fields-container assets-form-container"
      >
        <Formik
          initialValues={{
            assets: assets || [{
              institution: ''
            }],
            submit: null
          }}
          validationSchema={Yup.object().shape({
            assets: Yup.array().of(Yup.object().shape({
              institution: Yup.string().required('Institution is required.'),
              ownerDisplayName: Yup.string().required('Account holder is required.'),
              otherOwner: Yup.string().nullable().when('ownerDisplayName', {
                is: 'other',
                then: Yup.string().required('Other account name is required.')
              }),
              type: Yup.string().required('Type of account is required.'),
              retirementType: Yup.string().when('type', {
                is: 'retirement_fund',
                then: Yup.string().required('Type of retirement is required.')
              }),
              otherType: Yup.string().nullable().when(['type', 'retirementType'], {
                is: (
                  type: HiveAssetWorksheetType,
                  retirementType: HiveRetirementType
                ) => (
                  type === 'other'
                  || retirementType === 'retirement_other'
                ),
                then: Yup.string().required('Other account type is required.')
              }),
              accountNumber: Yup.string()
                .min(4, 'Last 4 digits of account number only')
                .max(4, 'Last 4 digits of account number only')
                .required('Last 4 digits of account number are required.'),
              accountBalance: Yup.string().required('Account balance is required.'),
              statementFrequency: Yup.string().required('Statement frequency is required.'),
              addAnother: Yup.string().required('Additional account selection is required.')
            }))
          })}
          onSubmit={(values, {
            setErrors,
            setStatus,
            setSubmitting,
            resetForm
          }) => {
            setIsLoading((isLoading: boolean) => true)
            const changedData = values.assets.map((asset: WorksheetAsset) => {
              const borrowerId = ownerOptions.find(option => option.value === asset.ownerDisplayName)?.borrowerid
              const employmentId = ownerOptions.find(option => option.value === asset.ownerDisplayName)?.employmentid
              const owner = borrowerId === loanData.coborrower?.id ? 'coborrower' : 'borrower'
              const assetType = asset.retirementType || asset.type
              const otherAssetType = asset.type === 'other' || asset.retirementType === 'retirement_other' ? asset.otherType : null
              const otherOwnerName = asset.ownerDisplayName === 'other' ? asset.otherOwner : null

              return {
                institution: asset.institution,
                owner_display_name: otherOwnerName || asset.ownerDisplayName,
                owner: owner,
                borrower_id: borrowerId,
                employment_id: employmentId,
                asset_type: assetType,
                other_asset_type: otherAssetType,
                account_number: asset.accountNumber,
                total_value: asset.accountBalance.replace(/[$,]/g, ''),
                shared_asset: asset.ownerDisplayName === 'shared',
                statement_frequency: asset.statementFrequency,
                other_owner_name: otherOwnerName
              }
            })

            if (conciergeMode) {
              loanData.assets = []
              const dataSubmit = _.merge(loanData, { assets: changedData })
              loansService.updateLoan(activeLoan, dataSubmit).then((res: any) => {
                setIsLoading((isLoading: boolean) => false)
                if (res.success) {
                  setStatus({ success: true })
                  setSubmitting(false)
                  resetForm()
                  setShowErrorContainer(showErrorContainer => false)
                  setShowAddAnotherForm(showAddAnotherForm => true)
                }
                else {
                  setStatus({ success: false })
                  setErrors({ submit: res.errors?.base[0] || res.error })
                  setSubmitting(false)
                }
              })
            }
            else {
              setIsLoading((isLoading: boolean) => false)
              if (changedData.length <= 1) {
                saveForm(changedData[0])
              }
              else {
                saveForm(changedData)
              }
              setStatus({ success: true });
              setSubmitting(false);
              resetForm({});
              setShowForm(false)
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values
          }) => {
            return (
              <form
                className="application-form asset-worksheet-form"
                onSubmit={handleSubmit}
              >
                <h1 className="title text-light-blue">Asset Worksheet</h1>
                <div className="form-step">
                  <FieldArray
                    name="assets"
                    render={arrayHelpers => (
                      <>
                        {values.assets.map((asset: any, index: number) => (
                          <React.Fragment key={`asset-${index}`}>
                            {index === 0 && (
                              <Form.Field>
                                <label>{conciergeMode ? 'Great. For your first ' : 'For this '}account, where do you bank?</label>
                                <Input
                                  className="small"
                                  name={`assets[${index}].institution`}
                                  placeholder="Name of Bank"
                                  type="text"
                                  value={asset.institution}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  error={
                                    !!(getIn(touched, `assets[${index}].institution`) && getIn(errors, `assets[${index}].institution`))
                                    || (showErrorContainer && !values.assets[index].institution)
                                  }
                                />
                              </Form.Field>
                            )}
                            {asset.institution && (
                              <>
                                <Form.Field>
                                  <label>Okay, who's the account holder?</label>
                                  <FormikSelect
                                    selectOnBlur={false}
                                    className="large"
                                    clearable
                                    name={`assets[${index}].ownerDisplayName`}
                                    placeholder="Account Owner"
                                    options={ownerOptions}
                                    value={asset.ownerDisplayName}
                                    onChange={handleChange}
                                    error={
                                      !!(getIn(touched, `assets[${index}].ownerDisplayName`) && getIn(errors, `assets[${index}].ownerDisplayName`))
                                      || (showErrorContainer && !values.assets[index].ownerDisplayName)
                                    }
                                  />
                                </Form.Field>
                                {asset.ownerDisplayName === 'other' && (
                                  <Form.Field>
                                    <label>Okay, who holds this other account?</label>
                                    <Input
                                      className="small"
                                      name={`assets[${index}].otherOwner`}
                                      placeholder="Account Holder Name"
                                      type="text"
                                      value={asset.otherOwner}
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      error={
                                        !!(getIn(touched, `assets[${index}].otherOwner`) && getIn(errors, `assets[${index}].otherOwner`))
                                        || (showErrorContainer && !values.assets[index].otherOwner)
                                      }
                                    />
                                  </Form.Field>
                                )}
                                <Form.Field>
                                  <label>Type of Account</label>
                                  <FormikSelect
                                    selectOnBlur={false}
                                    className="large"
                                    clearable
                                    name={`assets[${index}].type`}
                                    placeholder="Type of Account"
                                    options={assetWorksheetOptions}
                                    value={asset.type}
                                    onChange={handleChange}
                                    error={
                                      !!(getIn(touched, `assets[${index}].type`) && getIn(errors, `assets[${index}].type`))
                                      || (showErrorContainer && !values.assets[index].type)
                                    }
                                  />
                                </Form.Field>
                                {asset.type === 'retirement_funds' && (
                                  <Form.Field>
                                    <label>Okay, what type of retirement account?</label>
                                    <FormikSelect
                                      selectOnBlur={false}
                                      className="large"
                                      clearable
                                      name={`assets[${index}].retirementType`}
                                      placeholder="Type of Retirement Account"
                                      options={retirementOptions}
                                      value={asset.retirementType}
                                      onChange={handleChange}
                                      error={
                                        !!(getIn(touched, `assets[${index}].retirementType`) && getIn(errors, `assets[${index}].retirementType`))
                                        || (showErrorContainer && !values.assets[index].retirementType)
                                      }
                                    />
                                  </Form.Field>
                                )}
                                {(asset.type === 'other' || asset.retirementType === 'retirement_other') && (
                                  <Form.Field>
                                    <label>Okay, what type of account is this?</label>
                                    <Input
                                      className="small"
                                      name={`assets[${index}].otherType`}
                                      placeholder="Account Type"
                                      type="text"
                                      value={asset.otherType}
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      error={
                                        !!(getIn(touched, `assets[${index}].otherType`) && getIn(errors, `assets[${index}].otherType`))
                                        || (showErrorContainer && !values.assets[index].otherType)
                                      }
                                    />
                                  </Form.Field>
                                )}
                                <Form.Field>
                                  <label>{asset.type === 'other' && 'Got it. '}What are the last 4 digits of the this account number?</label>
                                  <FormikMaskedInput
                                    className="small"
                                    name={`assets[${index}].accountNumber`}
                                    placeholder="1234"
                                    type="text"
                                    value={asset.accountNumber}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    mask={ACCOUNT_NUMBER_MASK}
                                    guide={false}
                                    error={
                                      !!(getIn(touched, `assets[${index}].accountNumber`) && getIn(errors, `assets[${index}].accountNumber`))
                                      || (showErrorContainer && !values.assets[index].accountNumber)
                                    }
                                  />
                                </Form.Field>
                                <Form.Field>
                                  <label>What's the current balance of this account?</label>
                                  <FormikMaskedInput
                                    name={`assets[${index}].accountBalance`}
                                    placeholder="$0.00"
                                    type="text"
                                    value={asset.accountBalance}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    mask={currencyMask}
                                    guide={false}
                                    error={
                                      !!(getIn(touched, `assets[${index}].accountBalance`) && getIn(errors, `assets[${index}].accountBalance`))
                                      || (showErrorContainer && !values.assets[index].accountBalance)
                                    }
                                  />
                                </Form.Field>
                                <Form.Field>
                                  <label>How often do you receive statements on this account?</label>
                                  <FormikSelect
                                    selectOnBlur={false}
                                    className="large"
                                    clearable
                                    name={`assets[${index}].statementFrequency`}
                                    placeholder="Statement Frequency"
                                    options={statementFrequencyOptions}
                                    value={asset.statementFrequency}
                                    onChange={handleChange}
                                    error={
                                      !!(getIn(touched, `assets[${index}].statementFrequency`) && getIn(errors, `assets[${index}].statementFrequency`))
                                      || (showErrorContainer && !values.assets[index].statementFrequency)
                                    }
                                  />
                                </Form.Field>
                                {!formData && (
                                  <Form.Field>
                                    <label>Any additional accounts with {asset.institution}?</label>
                                    <div
                                      className={`
                                        radio-group
                                        ${showErrorContainer && !values.assets[index].addAnother && 'radio-error'}
                                      `}
                                    >
                                      <Radio
                                        id={`assets[${index}].addAnotherTrue`}
                                        label="Yes"
                                        name={`assets[${index}].addAnother`}
                                        value="true"
                                        checked={values.assets[index].addAnother === "true"}
                                        className={getIn(errors, `assets[${index}].addAnother`) && 'has-error'}
                                        onChange={handleChange}
                                        // Only add another asset if the current value is not true
                                        onClick={() => values.assets[index].addAnother !== "true" && arrayHelpers.push({ institution: asset.institution })}
                                      />
                                      <Radio
                                        id={`assets[${index}].addAnotherFalse`}
                                        label="No"
                                        name={`assets[${index}].addAnother`}
                                        value="false"
                                        checked={values.assets[index].addAnother === "false"}
                                        className={getIn(errors, `assets[${index}].addAnother`) && 'has-error'}
                                        onChange={handleChange}
                                        // Only remove asset object if it is not the last in the `values.assets` array
                                        onClick={() => values.assets.length !== index + 1 && arrayHelpers.remove(index + 1)}
                                      />
                                    </div>
                                  </Form.Field>
                                )}
                              </>
                            )}
                          </React.Fragment>
                        ))}
                      </>
                    )}
                  />
                </div>
                <ApplicationFormFooter
                  onCancelClick={handleCancelClick}
                  nextButtonLabel="Save & Continue"
                  nextDisabled={isSubmitting || disableOnErrors}
                  errors={errors}
                  showErrorContainer={showErrorContainer}
                  setShowErrorContainer={setShowErrorContainer}
                  setDisableOnErrors={setDisableOnErrors}
                  errorMessage={errors.submit ? errors.submit : undefined}
                />
              </form>
            )}
          }
        </Formik>
      </div>
    </>
  )
}

export default EditAssetWorksheetForm
