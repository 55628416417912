import { Field, FieldArray, FormikErrors, FormikHandlers, FormikTouched, FormikValues } from 'formik';
import { values } from 'lodash';
import React, {FC} from 'react';
import MaskedInput from 'react-text-mask';
import { Form, Input, Radio } from 'semantic-ui-react';
import FormikDropdown from '../../../../components/Formik/FormikDropdown';
import { currencyMask } from '../../../../helpers/currency-mask-input';
import { salaryFrequencyOptions } from '../dropdown-data';
import { initialPaymentTypeOptions } from './W2';

interface W2PaymentTypesProps {
  showEndDate: boolean | undefined;
  values: FormikValues;
  touched: FormikTouched<any>;
  errors: FormikErrors<any>;
  handleBlur: FormikHandlers['handleBlur'];
  handleChange: FormikHandlers['handleChange'];
  setFieldValue: any;
  showErrorContainer: boolean;
  clearValues: Function;
}

const W2PaymentTypes: FC<W2PaymentTypesProps> = ({
  showEndDate,
  values,
  touched,
  errors,
  handleBlur,
  handleChange,
  setFieldValue,
  showErrorContainer,
  clearValues,
}) => {
  return (
    <FieldArray
      name="paymentTypes"
      render={arrayHelpers => (
        <>
          <div className="form-step">
            <Form.Field>
              <label>How {showEndDate && values.currentEmployer !== 'true' ? `were` : `are`} you paid? Select ALL that apply.</label>
              <div className="payment-types-container">
                <div className={`radio-group ${showErrorContainer && values.paymentType === '' && 'radio-error'}`}>
                  <Radio
                    label="Salary"
                    id="paymentTypeSalary"
                    name="paymentType"
                    value="salary"
                    checked={values.paymentType === 'salary'}
                    className={errors.paymentType && 'has-error'}
                    onClick={() => clearValues( values, ['hourlyIncome', 'hoursAverage'])}
                    onChange={handleChange}
                  />
                    <Radio
                    label="Hourly"
                    id="paymentTypeHourly"
                    name="paymentType"
                    value="hourly"
                    checked={values.paymentType === 'hourly'}
                    className={errors.paymentType && 'has-error'}
                    onClick={() => clearValues( values, ['salaryIncome', 'paymentFrequency'])}
                    onChange={handleChange}
                  />
                </div>
                <div className="payment-options-container">
                  {initialPaymentTypeOptions.map((payment: any) => (
                    <div
                      key={payment.value}
                      className="payment-types"
                    >
                      <label className={`${values.paymentTypes.find((type: string) => type === payment.value)  ? 'checked' : 'none'}`}>
                        <Field
                          type="checkbox"
                          name="paymentTypes"
                          value={payment.value}
                          checked={payment.checked}
                          onClick={() => {
                            payment.checked = !payment.checked
                            clearValues(values, [`${payment.value}Amount`])
                          }}
                        />
                        {payment.value}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            </Form.Field>
          </div>
          {values.paymentType === 'salary' && (
            <>
              <div className="form-step">
                <Form.Field>
                  <label>What {((!showEndDate && !values.endDate) || values.currentEmployer === 'true') ? 'is' : 'was'} your annual salary?</label>
                  <Input
                    className="small"
                    error={!!(showErrorContainer && values.salaryIncome === '')}
                  >
                    <MaskedInput
                      mask={currencyMask}
                      name="salaryIncome"
                      placeholder="$"
                      type="text"
                      value={values.salaryIncome}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Input>
                </Form.Field>
              </div>
              <div className="form-step">
                <Form.Field>
                  <label>How often {((!showEndDate && !values.endDate) || values.currentEmployer === 'true') ? 'is' : 'was'} it received?</label>
                  <FormikDropdown
                    className="small"
                    fluid
                    selection
                    name="paymentFrequency"
                    placeholder="Bi-Weekly, Monthly, Quarterly"
                    value={values.paymentFrequency}
                    options={salaryFrequencyOptions}
                    error={!!(showErrorContainer && values.paymentFrequency === '')}
                  />
                </Form.Field>
              </div>
            </>
          )}
          {values.paymentType === 'hourly' && (
            <>
              <div className="form-step">
                <Form.Field>
                  <label>What {((!showEndDate && !values.endDate) || values.currentEmployer === 'true') ? 'is' : 'was'} your hourly rate?</label>
                    <Input
                      className="small"
                      error={!!(showErrorContainer && values.hourlyIncome === '')}
                    >
                      <MaskedInput
                        mask={currencyMask}
                        name="hourlyIncome"
                        placeholder="$"
                        type="text"
                        value={values.hourlyIncome}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                  </Input>
                </Form.Field>
              </div>
              <div className="form-step">
                <Form.Field>
                  <label>How many regular hours {((!showEndDate && !values.endDate) || values.currentEmployer === 'true') ? 'do' : 'did'} you work on average per week?</label>
                  <Input
                    className="small"
                    name="hoursAverage"
                    placeholder="Hours per week"
                    type="text"
                    value={values.hoursAverage}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={!!(showErrorContainer && values.hoursAverage === '')}
                  />
                </Form.Field>
              </div>
            </>
          )}
          {values.paymentTypes.find((type: string) => type === 'other') && (
              <div className="form-step">
                <Form.Field>
                  <label>What does your other income consist of?</label>
                  <Input
                    name="otherIncome"
                    placeholder="Other Income Description"
                    type="text"
                    value={values.otherIncome}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={!!(showErrorContainer && values.otherIncome === '')}
                  />
                </Form.Field>
              </div>
            )}
          {values.paymentTypes.length > 0 && (
            <div className="form-step">
              <Form.Field>
                <label>How much do you average per month in the following categories?</label>
                {values.paymentTypes.map((type: any, index: number) => (
                  <div className="monthly-average">
                    <label>{type}:</label>
                    <Input
                      className='small'
                      error={!!(showErrorContainer && values[`${type}Amount`] === '')}
                    >
                      <MaskedInput
                        mask={currencyMask}
                        name={`${type}Amount`}
                        placeholder="$"
                        type="text"
                        value={values[`${type}Amount`]}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </Input>
                  </div>
                ))}
              </Form.Field>
            </div>
          )}
        </>
      )}
    />
  )
}

export default W2PaymentTypes;