import React, {FC, useContext, useEffect, useState} from 'react';
import AddRemoveTable, { HeaderLabels } from '../../../components/AddRemoveTable/AddRemoveTable';
import { useStore } from '@jmjfinancial/apis/lib';
import { DataContext } from '../../../context/dataContext';
import { useHistory } from 'react-router-dom';
import { getHeaderText, reduceDisplayedTableData } from '../../../helpers/table-helper';
import _ from 'lodash';
import {scrollToDefaultOptions} from '../../../helpers/scroll-to-options';
import EditAssetWorksheetForm from './EditAssetWorksheetForm';
import ApplicationFormFooter from '../../LoanApplication/shared/ApplicationFormFooter';
import LoaderOverlay from '../../../components/LoaderOverlay/LoaderOverlay';

interface AssetsTableViewProps {
  loanData: any
  setRefreshAssetsView: Function
}

const HEADER_LABELS: HeaderLabels[] = [
  {
    text: 'Owner',
    value: 'owner_display_name'
  },
  {
    text: 'Institution Name',
    value: 'institution'
  },
  {
    text: 'Account Type',
    value: 'asset_type'
  },
  {
    text: 'Account #',
    value: 'account_number'
  },
  {
    text: 'Account Value',
    value: 'total_value'
  }
];

const AssetsTableView: FC<AssetsTableViewProps> = ({
  loanData,
  setRefreshAssetsView
}) => {
  const store = useStore();
  const { loansService, tasksService } = store;

  const { activeLoan, setDarkThemeOverride } = useContext(DataContext);
  const [tableData, setTableData] = useState<any>(loanData.assets);
  const [showForm, setShowForm] = useState(false);
  const [editFormData, setEditFormData] = useState<any>();
  const [editRowIndex, setEditRowIndex] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [disableOnErrors, setDisableOnErrors] = useState<boolean>(false);
  const [showErrorContainer, setShowErrorContainer] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>()
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();

  setDarkThemeOverride(false)

  const queryParams = new URLSearchParams(window.location.search)
  const taskId = parseInt(queryParams.get('taskId') as string)

  const handlePreviousClick = () => {
    history.push('/dashboard')
  }

  const handleNextClick = () => {
    history.push('/dashboard')
  }

  const handleAddRow = (row: any) => {
    let newTableData = [...tableData]

    // Because there can be multiple rows passed at once,
    // this iterates over each and pushes the object to the table
    if (row.length) {
      for (const obj of row) {
        newTableData.push(obj)
      }
    }
    else {
      newTableData.push(row)
    }
    setTableData(newTableData)
  }

  const handleRemoveRow = (index: number) => {
    let newTableData = [...tableData];
    newTableData[index].to_be_deleted = true;
    setTableData(newTableData);
  }

  const handleShowForm = (visible: boolean) => {
    setEditFormData((editFormData: any) => undefined);
    setShowForm(visible);
  }

  const handleEditRow = (index: number) => {
    setEditRowIndex(index);
    setEditFormData({ ...tableData[index] });
    setShowForm(true);
  }

  const handleUpdateRow = (row: any) => {
    const updatedTableData = [...tableData];
    updatedTableData[editRowIndex] = row;
    setTableData(updatedTableData);
  }

  const handleSubmit = async () => {
    setErrorMessage(errorMessage => '')
    setIsSubmitting(isSubmitting => true)
    setIsLoading((isLoading: boolean) => true)
    const dataSubmit = _.merge(loanData, { assets: tableData });
    loansService.updateLoan(activeLoan, dataSubmit).then((res: any) => {
      if (res.success) {
        const isDeletingAllAssets = tableData.every((row: any) => row.to_be_deleted)

        if (!isDeletingAllAssets && taskId) {
          tasksService.setTaskCompleted(loanData.borrower.borrower_pair_id, taskId).then()
        }
        setIsSubmitting(isSubmitting => false)

        setIsLoading((isLoading: boolean) => false)
        handleNextClick();
      }
      else {
        setIsSubmitting(isSubmitting => false)
        setErrorMessage(res.errors.base[0])
        setIsLoading((isLoading: boolean) => false)
      }
    })
  }

  /**
   * Checks if key exists in `tableData` and if it does, adds the column
   * if it is not already added. If it does not exist, the column is removed
   * if it has been added.
   * @param key hive key of display label
   * @param headerLabelText display label of hive key
   * @param insertAfterLabelText label to insert new label after
   */
  const updateTableColumns = (key: string, headerLabelText: string, insertAfterLabelText: string) => {
    const tableDataHasKey = tableData.find((row: any) => row[key])
    const headerHasLabel = HEADER_LABELS.find(header => header.text === headerLabelText)

    if (tableDataHasKey && !headerHasLabel) {
      const insertIndex = HEADER_LABELS.findIndex(header => header.text === insertAfterLabelText) + 1

      HEADER_LABELS.splice(insertIndex, 0, {
        text: headerLabelText,
        value: key
      })
    }
    else if (!tableDataHasKey && headerHasLabel) {
      const removeIndex = HEADER_LABELS.findIndex(header => header.text === headerLabelText)

      HEADER_LABELS.splice(removeIndex, 1)
    }
  }

  useEffect(() => {
    updateTableColumns('other_asset_type', 'Other Account Type', 'Account Type')
  }, [tableData])

  useEffect(() => {
    if (!showForm && tableData.filter((row: any) => row.to_be_deleted).length === 0) {
      window.scrollTo(scrollToDefaultOptions)
    }
  }, [showForm])

  return (
    <>
      <LoaderOverlay active={isLoading} />
      <div className="application-step-container assets-worksheet-table">
        <AddRemoveTable
          headerLabels={getHeaderText(HEADER_LABELS)}
          table={reduceDisplayedTableData(tableData, HEADER_LABELS)}
          removeTableRow={handleRemoveRow}
          addButtonLabel="Add additional Institution/Asset"
          showForm={showForm}
          setShowForm={handleShowForm}
          editTableRow={handleEditRow}
          loanData={loanData}
          form={
            <div className="application-step-container assets-step-container">
              <EditAssetWorksheetForm
                loanData={loanData}
                setRefreshAssetsView={setRefreshAssetsView}
                setShowForm={handleShowForm}
                saveForm={!editFormData ? handleAddRow : handleUpdateRow}
                formData={editFormData}
              />
            </div>
          }
        />
        {showForm ? null : (
          <ApplicationFormFooter
            onCancelClick={handlePreviousClick}
            nextButtonLabel="Save & Continue"
            nextDisabled={isSubmitting}
            onNextClick={handleSubmit}
            errors={errorMessage}
            showErrorContainer={showErrorContainer}
            setShowErrorContainer={setShowErrorContainer}
            setDisableOnErrors={setDisableOnErrors}
            errorMessage={errorMessage || undefined}
          />
        )}
      </div>
    </>
  )
}

export default AssetsTableView
