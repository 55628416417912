import React, {FC, useContext, useEffect, useState} from 'react';
import * as Yup from 'yup';
import {Button, Form} from 'semantic-ui-react';
import {Field, Formik, FormikValues} from 'formik';
import {observer} from 'mobx-react-lite';
import {DataContext} from '../../../context/dataContext';
import _ from 'lodash';
import {scrollToDefaultOptions} from '../../../helpers/scroll-to-options';

interface IncomesConciergeProps {
  loanData: any
  setIncomeTypes: Function
  coborrowerConcierge: boolean
}

const initialIncomeTypeOptions = [
  {
    text: 'I receive a W2 and have no ownership in the company.',
    value: 'W-2 Wage Earner',
    checked: false
  },
  {
    text: 'I am self-employed. (This applies if you own ANY percentage of the company)',
    value: 'Self Employed',
    checked: false
  },
  {
    text: 'I receive retirement income.',
    value: 'retirement_account',
    checked: false
  },
  {
    text: 'I receive alimony income.',
    value: 'alimony',
    checked: false
  },
  {
    text: 'I receive child support income.',
    value: 'child_support',
    checked: false
  },
  {
    text: 'I receive other types of income.',
    value: 'other',
    checked: false
  }
]

const IncomesConcierge: FC<IncomesConciergeProps> = observer(({
  loanData,
  setIncomeTypes,
  coborrowerConcierge, 
}) => {
  const { setDarkThemeOverride, setOverrideTrackerVisibility } = useContext(DataContext);
  const [ownerName, setOwnerName] = useState<string>('')
  const [incomeTypeOptions, setIncomeTypeOptions] = useState<Array<any>>(_.cloneDeep(initialIncomeTypeOptions))

  setDarkThemeOverride(true)
  setOverrideTrackerVisibility(true)

  const handleClearIncomeTypes = (values: FormikValues) => {
    values.incomeTypes = []
    incomeTypeOptions.map(option => option.checked = false)
  }

  useEffect(() => {
    console.log('IncomesConcierge: coborrowerConcierge', coborrowerConcierge)
    setOwnerName(coborrowerConcierge ? loanData.coborrower.first_name : loanData.borrower.first_name)
  }, [coborrowerConcierge])

  useEffect(() => {
    window.scrollTo(scrollToDefaultOptions)
  }, [])

  return (
    <Formik
      enableReinitialize
      initialValues={{
        incomeTypes: [],
        noIncomes: false,

        submit: null
      }}
      validationSchema={Yup.object().shape({})}
      onSubmit={(values, {
        setErrors,
        setStatus,
        setSubmitting,
        resetForm
      }) => {
        try {
          const incomeTypes = values.noIncomes ? ['None'] : values.incomeTypes
          setIncomeTypes(incomeTypes)

          setStatus({ success: true })
          setSubmitting(false)
          resetForm({})
          setIncomeTypeOptions(_.cloneDeep(initialIncomeTypeOptions))
        } catch (err: any) {
          console.error('IncomesConcierge error: ', err.message);
          setStatus({ success: false })
          setErrors({ submit: err.message })
          setSubmitting(false)
        }
      }}
    >
      {({
        values,
        handleSubmit,
        isSubmitting
      }) => (
        <form
          className="choice-form application-step-container employment-and-income"
          onSubmit={handleSubmit}
        >
          <div className="question-container">
            <div className="form-step">
              <Form.Field>
                <h1 className="title concierge">
                  Please select ALL types of employment
                  <br />
                  &amp; income that apply to {ownerName}.
                </h1>
                <div className="choice-container">
                  {incomeTypeOptions.map((option: any) => (
                    <div
                      key={option.value}
                      className={`answer-container ${values.noIncomes && 'disabled'}`}
                    >
                      <label className={`choice-answers ${option.checked} ${values.noIncomes ? 'none-selected' : ''}`}>
                        <Field
                          className={option.value}
                          type="checkbox"
                          name="incomeTypes"
                          value={option.value}
                          disabled={values.noIncomes}
                          onClick={() => values.noIncomes ? null : option.checked = !option.checked}
                        />
                       <i className={`icon ${!option.checked || values.noIncomes ? 'angle right unselected' : 'check selected'}`} />
                        {option.text}
                      </label>
                    </div>
                  ))}
                  <div className="answer-container">
                    <label className="choice-answers">
                      <Field
                        type="checkbox"
                        name="noIncomes"
                        onClick={() => handleClearIncomeTypes(values)}
                      />
                      <i className={`icon ${values.noIncomes ? 'check selected' : 'angle right unselected'}`} />
                      I have no current sources of income.
                    </label>
                  </div>
                </div>
              </Form.Field>
            </div>
            <Button
              disabled={isSubmitting || (values.incomeTypes.length === 0 && !values.noIncomes)}
              className="concierge-button"
              type="submit"
            >
              Save &amp; Continue
            </Button>
          </div>
        </form>
      )}
    </Formik>
  )
})

export default IncomesConcierge
