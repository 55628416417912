import _ from 'lodash'
import {AssetWorksheetTypeEnum} from '../views/Worksheets/Assets/asset-worksheet-types';
import {AssetTypeLabelEnum} from '../views/LoanApplication/Assets/asset-types';

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
})

export const tableValuesPipe = (key: string, value: any, loanData: any) => {
  switch (key) {
    case 'amount':
      // indexOf returns the index of the value that is found otherwise -1 if it does not exist
      if (["0.00", "0.0", 0].indexOf(value) > -1) {
        return '-'
      }
      else {
        return formatter.format(value)
      }
    case 'mobile_phone':
      return value.replace(/^.{2}(\d{3})(\d{3})(\d{4})/, "($1) $2-$3")
    case 'owner':
      if (value === 'borrower') {
        return loanData.borrower.first_name
      }
      else if (value === 'coborrower') {
        return loanData.coborrower.first_name
      }
      break
    case 'currently_employed':
      if (value === true) {
        return 'Yes'
      }
      else if (value === false) {
        return 'No'
      }
      else {
        return '-'
      }
    case 'total_value':
      return formatter.format(value)
    case 'shared_residence':
    case 'shared_asset':
      return value?.toString() === 'true' && <i className="check icon" />
    case 'years_at_address':
    case 'months_at_address':
      return value?.toString()
    case 'gse_property_type':
      if (value === 'sfr') {
        return 'Single Family Residence'
      }
      else if (value === 'condo') {
        return 'Condominium'
      }
      else {
        return _.startCase(value)
      }
    case 'basic_asset_type':
      return AssetTypeLabelEnum[value as keyof typeof AssetTypeLabelEnum]
    case 'income_type':
      switch (value){
        case 'social_security':
          return 'Social Security'
        case 'fund_401k':
          return '401k'
        case 'ira':
          return 'IRA'
        case 'ira_roth':
          return 'Roth IRA'
        case 'child_support':
          return 'Child Support'
        default:
          return value.charAt(0).toUpperCase() + value.slice(1);
      }
    // Assets Worksheet
    case 'asset_type':
      return AssetWorksheetTypeEnum[value as keyof typeof AssetWorksheetTypeEnum]
    default:
      return value ? value.toString() : '-'
  }
}
