import React, {FC, useEffect} from 'react';
import {Document} from '../../types/document';

interface DocumentPreviewProps {
  document: Document
  className?: string
}

const DocumentPreview: FC<DocumentPreviewProps> = ({
  document,
  className = 'document-preview'
}) => {
  useEffect(() => {
    return () => {
      if (window.stop !== undefined) {
        window.stop();
      }
    }
  })

  return document.type === 'application/pdf' ? (
    <embed
      className={className}
      src={document.url}
      title={document.fileName}
    />
  ) : (
    <img
      className={className}
      src={document.url}
      alt={document.fileName}
    />
  )
}

export default DocumentPreview
