import React, {FC} from 'react';
import {ReviewSection} from './index';
import {Button} from 'semantic-ui-react';
import {useHistory} from 'react-router-dom';

interface SummarySectionProps {
  section: ReviewSection;
  subSection?: boolean;
  coapplicantMode?: boolean
}

const SummarySection: FC<SummarySectionProps> = ({
  section,
  subSection,
  coapplicantMode
}) => {
  const history = useHistory();

  return (
    <div className={`summary-section${subSection ? ' is-subsection' : ''}`}>
      <h3 className="summary-section-title">{section.title}</h3>
      <div className="details-container">
        {section.sectionDetails?.map((detail, index) => (
          <div
            key={`${detail.label}-${index}`}
            className="detail"
          >
            <label>{detail.label}</label>
            <span className="value">{detail.value || '-'}</span>
          </div>
        ))}
      </div>
      <div className="summary-section-footer">
        <Button
          type="button"
          className="alternate-button"
          onClick={() => history.push(`${section.route}`)}
          disabled={(coapplicantMode || section.route === undefined) && section.title === 'Loan Details'}
          aria-label={`Edit ${section.title}`}
        >
          Edit
        </Button>
      </div>
      {section.subSection && (
        <SummarySection
          key={section.title}
          section={section.subSection}
          subSection
        />
      )}
    </div>
  )
}

export default SummarySection
