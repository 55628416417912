import React, {FC} from 'react';
import {IncomeCopy, IncomeType} from '../index';
import {getPluralizedName} from '../../../../helpers/pluralize-name';

interface IncomeFormHeaderProps {
  ownerName: string
  incomeType: IncomeType
  duplicateIncomeType?: boolean
  conciergeMode?: boolean
}

const IncomeFormHeader: FC<IncomeFormHeaderProps> = ({
  ownerName,
  incomeType,
  duplicateIncomeType,
  conciergeMode
}) => {
  const pluralizedOwnerName = getPluralizedName(ownerName)
  const incomeCopy = IncomeCopy[incomeType as keyof typeof IncomeCopy]

  const headerCopy = (): string => {
    return `${pluralizedOwnerName} ${duplicateIncomeType ? 'Additional ' : ''}${incomeCopy}`
  }

  const subheaderCopy = (): string => {
    if (duplicateIncomeType) {
      return `Tell us more about ${pluralizedOwnerName} other ${incomeCopy}`
    }
    else {
      return `Got it. Let's learn a bit more about your ${incomeCopy === "Other Income" ? "other" : incomeCopy} income.`
    }
  }

  return conciergeMode ? (
    <>
      <h1 className="title has-subtitle text-light-blue">{headerCopy()}</h1>
      <h3 className="subtitle text-grey">{subheaderCopy()}</h3>
    </>
  ) : <h2 className="section-title text-light-blue">{pluralizedOwnerName} {incomeCopy}</h2>
}

export default IncomeFormHeader
