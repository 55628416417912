import React, { FC, useState, useContext, useEffect } from 'react';
import * as Yup from 'yup';
import LoaderOverlay from '../../../components/LoaderOverlay/LoaderOverlay';
import { Button, Form, Input, Radio } from 'semantic-ui-react';
import { Formik } from 'formik';
import FormikDatePicker from '../../../components/Formik/FormikDatePicker';
import { DataContext } from '../../../context/dataContext';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { useStore } from '@jmjfinancial/apis/lib';
import { citizenshipOptions, suffixOptions } from './data';
import { useHistory } from 'react-router';
import _ from 'lodash';
import MaskedInput from 'react-text-mask';
import { ApplicationRoutes } from '../index';
import FormikErrorMessage from '../../../components/Formik/ErrorMessage';
import FormikDropdown from '../../../components/Formik/FormikDropdown';
import ErrorContainer from '../../../components/ErrorContainer/index';
import DependentFields from './DependentFields';
import './borrower-info.scss';
import {scrollToDefaultOptions} from '../../../helpers/scroll-to-options';
import { getMaxBirthdate, getMinBirthdate } from '../../../helpers/date-helpers';
import HelperPopup from '../../../components/Popup/Popup';
import {SOCIAL_SECURITY_MASK} from '../../../helpers/regex-helper';

const CoBorrowerInformation: FC = observer(() => {
  const store = useStore();
  const { loansService } = store;

  const { activeLoan, pathname, reviewMode } = useContext(DataContext)
  const [loanData, setLoanData] = useState<any>()
  const [isLoading, setIsLoading] = useState(true)
  const [disableOnErrors, setDisableOnErrors] = useState<boolean>(false)
  const [showErrorContainer, setShowErrorContainer] = useState<boolean>(false)
  const [coborrowerName, setCoBorrowerName] = useState<string>('')

  const history = useHistory();

  const handlePreviousClick = () => {
    if (reviewMode) {
      history.push('/application/review-and-submit')
    }
    else {
      history.push('/application/auth-and-consent-co-borrower');
    }
  }

  const handleNextClick = () => {
    if (reviewMode) {
      history.push('/application/review-and-submit')
    }
    else {
      history.push('/application/residences');
    }
  }

  useEffect(() => {
    loansService.getLoan(activeLoan)
      .then(loan => {
        setLoanData({ ...loan.data.form_data })
        setCoBorrowerName(loan.data.form_data.coborrower?.first_name || 'your coborrower')
        setIsLoading(false);
      })
  }, [activeLoan, loansService])

  useEffect(() => {
    window.scrollTo(scrollToDefaultOptions)
  }, [])

  return (
    <div className="application-step-container coborrower-info-container">
      <LoaderOverlay active={isLoading} />
      {!isLoading && (
        <>
          <h1 className="title has-subtitle text-light-blue">CoBorrower Information</h1>
          <h3 className="subtitle text-grey borrower-info-subtitle">{`Next, we want to collect some details about ${coborrowerName} that will help us process this application`}</h3>
          <Formik
            initialValues={{
              // Spouse info
              firstName: loanData.coborrower?.first_name || '',
              lastName: loanData.coborrower?.last_name || '',
              suffix: loanData.coborrower?.suffix_to_name || '',
              mobile: loanData.coborrower?.mobile_phone || '',
              email: loanData.coborrower?.email || '',
              ssn: loanData.coborrower?.ssn || '',
              dob: loanData.coborrower?.date_of_birth ? moment(loanData.coborrower?.date_of_birth).toDate() : '',
              citizenship: loanData.coborrower?.citizenship || '',
              legalNameMatch: loanData.coborrower?.legal_name_match?.toString() || '',

              coborrowerDependentsMatch: loanData.coborrower?.coborrower_dependents_match?.toString() || '',
              dependentCount: loanData.borrower.dependent_count,
              hasDependents: loanData.coborrower?.coborrower_dependents_match?.toString() === 'false'
                ? loanData.coborrower?.dependent_count > 0
                  ? 'true'
                  : 'false'
                : '',
              dependents: loanData.coborrower?.dependent_ages_description || [],
              submit: null
            }}
            validationSchema={Yup.object().shape({
              // Spouse info
              firstName: Yup.string().required(' '),
              lastName: Yup.string().required(' '),
              email: Yup.string().email().required(' '),
              suffix: Yup.string(),
              ssn: Yup.string()
                .matches(/\d{3}-?\d{2}-?\d{4}$/, 'Social Security Number must be 9 digits')
                .required(' '),
              dob: Yup.date()
                .min(getMinBirthdate(), 'Invalid date')
                .max(getMaxBirthdate(), 'Must be 18 years old or older')
                .required(' '),
              citizenship: Yup.string().required(' '),
              coborrowerDependentsMatch: Yup.string().required(' '),
              dependentCount: Yup.number().max(20, 'You cannot have more than 20 dependents').when('hasDependents', {
                is: 'true',
                then: Yup.number().required('This field is required')
              }),
              hasDependents: Yup.string().when('coborrowerDependentsMatch', {
                is: 'false',
                then: Yup.string().required('This field is required')
              }),
              dependents: Yup.array().when(['hasDependents', 'dependentCount'], {
                is: (hasDependents: string, dependentCount: number) => hasDependents === 'true' && dependentCount > 0,
                then: Yup.array().of(
                  Yup.number()
                    .required(' ')
                    .min(1, 'Invalid number')
                    .max(115, 'Invalid number')
                )
              })
            })}
            onSubmit={async (values, {
              setErrors,
              setStatus,
              setSubmitting
            }) => {
              try {
                const changedData = {
                  application: {
                    current_step: ApplicationRoutes[pathname as keyof typeof ApplicationRoutes] + 1,
                    completed_steps: {
                      co_borrower_info_done: true
                    }
                  },
                  coborrower: {
                    id: loanData.coborrower?.id,
                    first_name: values.firstName,
                    last_name: values.lastName,
                    suffix_to_name: values.suffix,
                    email: values.email,
                    mobile_phone: values.mobile,
                    ssn: values.ssn.replace(/-/g, ''),
                    date_of_birth: values.dob,
                    citizenship: values.citizenship,
                    dependent_count: values.hasDependents === 'false' ? 0 : values.dependents?.length,
                    legal_name_match: values.legalNameMatch,
                    coborrower_dependents_match: values.coborrowerDependentsMatch
                  }
                }

                let dataSubmit = _.merge(loanData, changedData)

                dataSubmit.coborrower.dependent_ages_description =
                  values.dependents !== ['']
                    && values.hasDependents !== 'false'
                    ? [...values.dependents]
                    : []

                await loansService.updateLoan(activeLoan, dataSubmit)

                setStatus({ success: true })
                setSubmitting(false)

                handleNextClick();
              } catch (err: any) {
                console.error('Application error: ', err.message);
                setStatus({ success: false });
                setErrors({ submit: err.message });
                setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              setFieldValue
            }) => (
              <form
                onSubmit={handleSubmit}
                className="application-form borrower-info"
              >
                <div className="form-step">
                  <Form.Field>
                    <label>
                      Does the name on {coborrowerName}'s license appear as&nbsp;
                      <span className="text-blue">
                        {loanData.coborrower.first_name} {loanData.coborrower.last_name}{loanData.coborrower.suffix ? ` ${loanData.coborrower.suffix}` : ''}
                      </span>
                      ?
                    </label>
                    <div className={`radio-group ${showErrorContainer && values.legalNameMatch === '' && 'radio-error'}`}>
                      <Radio
                        id="legalNameMatchTrue"
                        className={errors.legalNameMatch && 'has-error'}
                        label="Yes"
                        name="legalNameMatch"
                        value="true"
                        checked={values.legalNameMatch === "true"}
                        onChange={handleChange}
                      />
                      <Radio
                        id="legalNameMatchFalse"
                        className={errors.legalNameMatch && 'has-error'}
                        label="No"
                        name="legalNameMatch"
                        value="false"
                        checked={values.legalNameMatch === "false"}
                        onChange={handleChange}
                      />
                    </div>
                  </Form.Field>
                  {values.legalNameMatch === 'false' && (
                    <>
                      <Form.Field className="legal-name-label">
                        <label>Okay, what is the name on {coborrowerName}'s driver's license?</label>
                      </Form.Field>
                      <Form.Field>
                        <div className="borrower-name">
                          <Input
                            className="small"
                            name="firstName"
                            placeholder="First"
                            type="text"
                            value={values.firstName}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            error={!!(touched.firstName && errors.firstName) || !!(showErrorContainer && values.firstName === '')}
                          />
                          <Input
                            className="small"
                            name="lastName"
                            placeholder="Last"
                            type="text"
                            value={values.lastName}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            error={!!(touched.lastName && errors.lastName) || !!(showErrorContainer && values.lastName === '')}
                          />
                          <FormikDropdown
                            className="small"
                            options={suffixOptions}
                            name="suffix"
                            placeholder="Suffix (Optional)"
                            type="text"
                            value={values.suffix}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            error={!!(touched.suffix && errors.suffix)}
                          />
                        </div>
                      </Form.Field>
                    </>
                  )}
                  <div className="form-step">
                    <Form.Field>
                      <label>{`What is ${coborrowerName}'s email address?`}</label>
                      <Input
                        className="small"
                        name="email"
                        placeholder="Email"
                        type="text"
                        value={values.email}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={!!(touched.email && errors.email) || !!(showErrorContainer && values.email === '')}
                      />
                    </Form.Field>
                  </div>
                  <div className="form-step">
                    <Form.Field>
                      <label>{`What is ${coborrowerName}'s social security number?`}</label>
                      <Input
                        className={`field-with-error-container small ${!!(touched.ssn && errors.ssn) || !!(showErrorContainer && values.ssn === '') ? 'error' : ''}`}
                      >
                        <MaskedInput
                          mask={SOCIAL_SECURITY_MASK}
                          name="ssn"
                          type="text"
                          placeholderChar={`\u2000`}
                          placeholder="000-00-0000"
                          value={values.ssn}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          guide={false}
                        />
                        <FormikErrorMessage name="ssn" />
                      </Input>
                    </Form.Field>
                  </div>
                  <div className="form-step">
                    <Form.Field>
                      <div className="label-popup-container">
                        <label>{`What is ${coborrowerName}'s date of birth?`}</label>
                        <HelperPopup
                          headerLabel="Minimum Age Required"
                          content={
                            <span>Your spouse must be 18 years or older to be added to a JMJ loan.</span>
                          }
                        />
                      </div>
                      <div className="birth-date">
                        <FormikDatePicker
                          className="small"
                          name="dob"
                          placeholder="MM/DD/YYYY"
                          format="MM/DD/YYYY"
                          maxDate={getMaxBirthdate()}
                          icon="calendar alternate outline"
                          value={values.dob}
                          error={!!(touched.dob && errors.dob) || !!(showErrorContainer && (errors.dob || values.dob === '' || values.dob === null))}
                        />
                        {showErrorContainer && values.dob !== null ? <span className="error-message">{errors.dob}</span> : null}
                      </div>
                    </Form.Field>
                  </div>
                  <div className="form-step">
                    <Form.Field>
                      <label>{`What is ${coborrowerName}'s citizenship?`}</label>
                      <FormikDropdown
                        className="small"
                        fluid
                        selection
                        name="citizenship"
                        placeholder="Spouse Citizenship"
                        options={citizenshipOptions}
                        value={values.citizenship}
                        error={!!(touched.citizenship && errors.citizenship) || !!(showErrorContainer && values.citizenship === '')}
                      />
                    </Form.Field>
                  </div>
                  <div className="form-step">
                    <Form.Field>
                      <label>{`Does ${coborrowerName} have the same dependents as you?`}</label>
                      <div className={`radio-group ${showErrorContainer && values.coborrowerDependentsMatch !== null ? 'radio-error' : ''}`}>
                        <Radio
                          id="coborrowerDependentsMatchTrue"
                          label="Yes"
                          className={`${(touched.coborrowerDependentsMatch && errors.coborrowerDependentsMatch) ? 'has-error' : null}`}
                          name="coborrowerDependentsMatch"
                          value="true"
                          checked={values.coborrowerDependentsMatch === 'true'}
                          onClick={() => {
                            values.hasDependents = ''
                            values.dependents = loanData.borrower.dependent_ages_description || []
                          }}
                          onChange={handleChange}
                        />
                        <Radio
                          id="coborrowerDependentsMatchFalse"
                          label="No"
                          className={`${(touched.coborrowerDependentsMatch && errors.coborrowerDependentsMatch) ? 'has-error' : null}`}
                          name="coborrowerDependentsMatch"
                          value="false"
                          checked={values.coborrowerDependentsMatch === 'false'}
                          onClick={() => {
                            values.hasDependents = ''
                            values.dependents = []
                          }}
                          onChange={handleChange}
                        />
                      </div>
                      <FormikErrorMessage name="coborrowerDependentsMatch" />
                    </Form.Field>
                  </div>
                  {values.coborrowerDependentsMatch === 'false' && (
                    <DependentFields
                      values={values}
                      touched={touched}
                      errors={errors}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      setFieldValue={setFieldValue}
                      showErrorContainer={showErrorContainer}
                      hasCoborrower
                      coborrowerName={coborrowerName}
                    />
                  )}
                </div>
                <div className="application-step-footer coborrower">
                  <Button
                    type="button"
                    onClick={handlePreviousClick}
                  >
                    Previous
                  </Button>
                  <ErrorContainer
                    errors={errors}
                    showErrorContainer={showErrorContainer}
                    setDisableOnErrors={setDisableOnErrors}
                  />
                  <Button
                    disabled={isSubmitting || disableOnErrors}
                    className="save"
                    color="blue"
                    type="submit"
                    onClick={() => setShowErrorContainer(true)}
                  >
                    Save &amp; Continue
                  </Button>
                </div>
              </form>
            )}
          </Formik>
        </>
      )}
    </div>
  )
})

export default CoBorrowerInformation;
