import React, { FC, useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from '@jmjfinancial/apis/lib';
import { DataContext } from '../../../context/dataContext';
import LoaderOverlay from '../../../components/LoaderOverlay/LoaderOverlay';
import AssetsConcierge from './AssetsConcierge';
import EditAssetForm from './EditAssetForm';
import AssetsTableView from './AssetsTableView';
import './assets.scss';
import {assetOptions, AssetTypeOption} from './dropdown-data';
import {AssetType, SimpleAssetType} from './asset-types';

export const simpleAssets: SimpleAssetType[] = [
  "estimated_bank_accounts_amount",
  "estimated_stocks_and_bonds_amount",
  "estimated_retirement_accounts_amount",
  "estimated_gifts_amount"
]

const AssetsV3: FC = observer(() => {
  const store = useStore();
  const { loansService } = store;
  const { activeLoan, setDarkThemeOverride, setOverrideTrackerVisibility } = useContext(DataContext);

  const [isLoading, setIsLoading] = useState(true);
  const [loanData, setLoanData] = useState<any>();
  const [hasAssets, setHasAssets] = useState(false);
  // Controls how forms display/handle data
  const [conciergeMode, setConciergeMode] = useState(!hasAssets);
  // Used as a flag to force soft refreshes of the view
  const [refreshAssetsView, setRefreshAssetsView] = useState(false)
  // Array of asset types set in concierge to iterate through
  const [assetTypes, setAssetTypes] = useState<AssetType[]>([])
  // Index controlling the current income type from `incomeTypes`
  const [assetIndex, setAssetIndex] = useState<number>(0)
  const [hasCoborrower, setHasCoborrower] = useState(false);
  const [assetTypeOptions, setAssetTypeOptions] = useState<AssetTypeOption[]>(assetOptions)


  setDarkThemeOverride(true)
  setOverrideTrackerVisibility(true)

  const borrowerHasAssets = (borrower: any) => {
    return simpleAssets.reduce((prevAsset, currentAsset) => {
      if (!prevAsset && borrower.hasOwnProperty(currentAsset)) {
        prevAsset = true
      }
      return prevAsset
    }, false)
  }

  const AssetsView: FC = () => {
    // If assets exist, show table view
    // Set hasAssets if the array is full or data in borrower model
    if (hasAssets) {
      return (
        <AssetsTableView
          loanData={loanData}
          assetTypeOptions={assetTypeOptions}
          assetTypes={assetTypes}
          setAssetTypes={setAssetTypes}
          assetIndex={assetIndex}
          setAssetIndex={setAssetIndex}
          setRefreshAssetsView={setRefreshAssetsView}
          conciergeMode={conciergeMode}
          hasCoborrower={hasCoborrower}
        />
      )
    }
    // If assetTypes exist, show form
    // This logic is still the same throughout both iterations of assets, array and through the borrower model
    else if (assetTypes.length > 0) {
      console.log('assetTypes: ', assetTypes)
      return (
        <div className="application-step-container assets-step-container">
          <EditAssetForm
            loanData={loanData}
            assetTypeOptions={assetTypeOptions}
            assetTypes={assetTypes}
            setAssetTypes={setAssetTypes}
            assetIndex={assetIndex}
            setAssetIndex={setAssetIndex}
            setRefreshAssetsView={setRefreshAssetsView}
            conciergeMode={conciergeMode}
            hasCoborrower={hasCoborrower}
            setShowForm={() => { }}
            saveForm={() => { }}
          />
        </div>
      )
    }
    // If neither assets nor assetTypes exist, start concierge
    else {
      return (
        <div className="assets-concierge">
          <AssetsConcierge
            loanData={loanData}
            assetTypeOptions={assetTypeOptions}
            setAssetTypes={setAssetTypes}
            hasCoborrower={hasCoborrower}
          />
        </div>
      )
    }
  }

  useEffect(() => {
    console.log('index useEffect()')
    setIsLoading(true)
    loansService.getLoan(activeLoan).then(l => {
      const loan = l.data.form_data
      setLoanData(loan)
      // If refi, removes last option from `assetTypeOptions`
      setAssetTypeOptions(assetTypeOptions => loan.loan.loan_purpose_type === "purchase"
        ? assetOptions : assetOptions.slice(0, -1)
      )
      setHasAssets(borrowerHasAssets(loan.borrower))
      setHasCoborrower(!!loan.coborrower)
      setConciergeMode(!(borrowerHasAssets(loan.borrower)))
      setRefreshAssetsView(false)
      setIsLoading(false)
    })
  }, [loansService, activeLoan, refreshAssetsView])

  return (
    <>
      <LoaderOverlay active={isLoading} />
      {!isLoading && <AssetsView />}
    </>
  )
})

export default AssetsV3
