import React, { FC, useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import { Button, Form, Input } from 'semantic-ui-react';
import * as Yup from 'yup';
import _ from 'lodash';
import '../../../components/PageHeader/index.scss';
import './section0.scss';
import { observer } from 'mobx-react-lite';
import { DataContext } from '../../../context/dataContext';
import { useStore } from '@jmjfinancial/apis/lib';
import { ApplicationRoutes } from '..';
import AddressInput from '../../../components/AddressInput/AddressInput';
import FormikDropdown from '../../../components/Formik/FormikDropdown';
import usStates from '../../../data/us-states';
import ErrorContainer from '../../../components/ErrorContainer';
import LoaderOverlay from '../../../components/LoaderOverlay/LoaderOverlay';
import {scrollToDefaultOptions} from '../../../helpers/scroll-to-options';

const stageOptions = [
  {
    text: 'I am currently in-contract.',
    value: 'contract',
    key: 'stage'
  },
  {
    text: 'My offer\'s been made but not yet accepted.',
    value: 'offerMade',
    key: 'stage'
  },
  {
    text: 'I\'ve found a home but have not made an offer.',
    value: 'foundHome',
    key: 'stage'
  },
  {
    text: 'I want to get pre-approved.',
    value: 'prequalify',
    key: 'stage'
  }
]

const timeframeOptions = [
  {
    text: 'ASAP',
    value: 'ASAP',
    key: 'timeframe'
  },
  {
    text: 'Within 3 months or so.',
    value: '3months',
    key: 'timeframe'
  },
  {
    text: 'Within 6 months or so.',
    value: '6months',
    key: 'timeframe'
  },
  {
    text: 'I\'m not quite sure.',
    value: 'noTimeframe',
    key: 'timeframe'
  }
]

const usageOptions = [
  {
    text: 'Primary residence',
    value: 'primary',
    key: 'usage'
  },
  {
    text: 'Second home',
    value: 'secondary',
    key: 'usage'
  },
  {
    text: 'Investment',
    value: 'investment',
    key: 'usage'
  }
]

const renderButton = (
  values: any,
  setShowErrorContainer: Function,
  disableOnErrors: boolean
) => {
  switch (values.stage) {
    case 'contract':
    case 'offerMade':
      return (
        <Button
          type="submit"
          className="concierge-button"
          disabled={
            values.stage === undefined ||
            values.usage === undefined ||
            disableOnErrors
          }
          onClick={() => setShowErrorContainer(true)}
        >
          Save &amp; Continue
        </Button>
      )
    case 'foundHome':
    case 'prequalify':
      return (
        <Button
          type="submit"
          className="concierge-button"
          disabled={
            values.stage === undefined ||
            values.timeframe === undefined ||
            values.usage === undefined ||
            disableOnErrors
          }
          onClick={() => setShowErrorContainer(true)}
        >
          Save &amp; Continue
        </Button>
      )
    default:
      return (
        <Button
          type="submit"
          className="concierge-button"
          disabled={true}
          onClick={() => setShowErrorContainer(true)}
        >
          Save &amp; Continue
        </Button>
      )
  }
}

const Step2Purchase: FC = observer(() => {
  const store = useStore();
  const { loansService } = store;
  const {
    activeLoan,
    pathname,
    setDarkThemeOverride,
    setOverrideTrackerVisibility
  } = useContext(DataContext);

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [loanData, setLoanData] = useState<any>();
  const [stageSelected, setStageSelected] = useState<number>()
  const [timeframeSelected, setTimeframeSelected] = useState<number>()
  const [usageSelected, setUsageSelected] = useState<number>()
  const [showErrorContainer, setShowErrorContainer] = useState<boolean>(false)
  const [disableOnErrors, setDisableOnErrors] = useState<boolean>(false)

  const history = useHistory()

  setDarkThemeOverride(true);
  setOverrideTrackerVisibility(true);

  let newPurchaseCity: string;
  let newPurchaseState: string;
  let newPostalCode: number;

  const choiceSelection = (
    resetForm: Function,
    setFieldValue: Function,
    key: string,
    value: string,
    index: number
  ) => {
    resetForm()
    setFieldValue(key, value)
    setStageSelected(index)
    setTimeframeSelected(timeframeSelected => undefined)
    setUsageSelected(usageSelected => undefined)
    setShowErrorContainer(false)
  }

  useEffect(() => {
    setIsLoading(true)
    loansService.getLoan(activeLoan)
      .then((l: any) => {
        const loan = l.data.form_data
        if (loan.borrower.id !== loan.loan.principal_borrower_id) {
          history.push('/application/borrower-info')
        }
        setLoanData({ ...loan })
        setIsLoading(false)
      })
  }, [activeLoan, loansService])

  useEffect(() => {
    window.scrollTo(scrollToDefaultOptions)
  }, [])

  return (
    <>
      <LoaderOverlay active={isLoading} />
      {!isLoading && (
        <Formik
          initialValues={{
            stage: undefined,
            timeframe: undefined,
            usage: undefined,
            purchaseLocation: '',
            street: '',
            unit: '',
            city: '',
            state: '',
            postal: '',
            county: '',
            submit: null
          }}
          validationSchema={Yup.object().shape({
            stage: Yup.string().required(),
            usage: Yup.string().when('stage', {
              is: 'contract' || 'offerMade',
              then: Yup.string().required()
            }),
            timeframe: Yup.string().when('stage', {
              is: (stage: string) =>
                stage === 'foundHome' ||
                stage === 'prequalify',
              then: Yup.string().required()
            }),
            street: Yup.string().min(2, 'Invalid address entered, minimum of 2 characters required').when('stage', {
              is: 'contract',
              then: Yup.string().required()
            }),
            unit: Yup.string(),
            city: Yup.string().required(),
            state: Yup.string().required(),
            postal: Yup.string().when('stage', {
              is: 'contract',
              then: Yup.string().required(' ').matches(/^[0-9]{5}$/, 'Zipcode must be exactly 5 digits')
            })
          })}
          onSubmit={async (values, {
            setErrors,
            setStatus,
            setSubmitting
          }) => {
            try {
              const changedData = {
                application: {
                  // Sets to step 3 (borrower-info) to skip refinance section
                  current_step: ApplicationRoutes[pathname as keyof typeof ApplicationRoutes] + 2
                },
                loan: {
                  loan_purpose_type: "purchase",
                  purchase_plan: values.stage,
                  purchase_timeframe: values.timeframe,
                  refi_goal: null
                },
                property: {
                  usage: values.usage,
                  address_street_line: values.street,
                  address_unit: values.unit,
                  address_city: newPurchaseCity || values.city,
                  address_state: newPurchaseState || values.state,
                  address_postal: newPostalCode || values.postal,
                  address_county: values.county
                }
              }

              const dataSubmit = _.merge(loanData, changedData)

              await loansService.updateLoan(activeLoan, dataSubmit);

              setDarkThemeOverride(false);
              setOverrideTrackerVisibility(false);

              history.push('/application/borrower-info')
            } catch (err: any) {
              console.error('Application error: ', err.message);
              setStatus({ success: false })
              setErrors({ submit: err.message })
              setSubmitting(false)
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            setFieldValue,
            resetForm,
            values
          }) => (
            <>
              <form
                className="choice-form section-0"
                onSubmit={handleSubmit}
              >
                <div className="question-container">
                  <div className="form-step">
                    <Form.Field>
                      <h1 className="title-instructions">
                        Where are you in the home <br /> buying process?
                      </h1>
                      <div className="choice-container">
                        {stageOptions.map((choice: any, index: number) => (
                          <>
                            <button
                              type="button"
                              className={`choice-answers ${choice.value} ${stageSelected === index ? 'selected' : 'unselected'}`}
                              tabIndex={0}
                              onKeyPress={stageSelected !== index ? () => {
                                choiceSelection(
                                  resetForm,
                                  setFieldValue,
                                  choice.key,
                                  choice.value,
                                  index
                                )
                              } : () => null
                              }
                              onClick={stageSelected !== index ? () => {
                                choiceSelection(
                                  resetForm,
                                  setFieldValue,
                                  choice.key,
                                  choice.value,
                                  index
                                )
                              } : () => null
                              }
                            >
                              <span>{choice.text}</span>
                              <i className={`icon small ${stageSelected === index ? 'check selected' : 'angle right unselected'}`} />
                            </button>
                          </>
                        ))}
                      </div>
                    </Form.Field>
                  </div>
                  {(values.stage === 'foundHome' || values.stage === 'prequalify') && (
                    <div className="form-step">
                      <Form.Field>
                        <h1 className="title-instructions">
                          When would you like to purchase?
                        </h1>
                        <div className="choice-container">
                          {timeframeOptions.map((choice: any, index: number) => (
                            <button
                              type="button"
                              className={`choice-answers ${choice.value} ${timeframeSelected === index ? 'selected' : 'unselected'}`}
                              tabIndex={0}
                              onKeyPress={() => {
                                setFieldValue(choice.key, choice.value)
                                setTimeframeSelected(index)
                                setShowErrorContainer(false)
                              }}
                              onClick={() => {
                                setFieldValue(choice.key, choice.value)
                                setTimeframeSelected(index)
                                setShowErrorContainer(false)
                              }}
                            >
                              <span>{choice.text}</span>
                              <i className={`icon small ${timeframeSelected === index ? 'check selected' : 'angle right unselected'}`} />
                            </button>
                          ))}
                        </div>
                      </Form.Field>
                    </div>
                  )}
                  {(values.timeframe !== undefined ||
                    values.stage === 'contract' ||
                    values.stage === 'offerMade')
                    && (
                      <div className="form-step">
                        <Form.Field>
                          <h1 className="title-instructions">
                            What's your plan for this property?
                          </h1>
                          <div className="choice-container">
                            {usageOptions.map((choice: any, index: number) => (
                              <button
                                type="button"
                                className={`choice-answers ${choice.value} ${usageSelected === index ? 'selected' : 'unselected'}`}
                                tabIndex={0}
                                onKeyPress={() => {
                                  setFieldValue(choice.key, choice.value)
                                  setUsageSelected(index)
                                  setShowErrorContainer(false)
                                }}
                                onClick={() => {
                                  setFieldValue(choice.key, choice.value)
                                  setUsageSelected(index)
                                  setShowErrorContainer(false)
                                }}
                              >
                                <span>{choice.text}</span>
                                <i className={`icon small ${usageSelected === index ? 'check selected' : 'angle right unselected'}`} />
                              </button>
                            ))}
                          </div>
                        </Form.Field>
                      </div>
                    )}
                  {(values.stage === 'foundHome' && values.usage && values.timeframe
                    || values.stage === 'prequalify' && values.usage && values.timeframe
                    || values.stage === 'offerMade' && values.usage)
                    && (
                      <div className="form-step">
                        <Form.Field>
                          <h1 className="title-instructions">
                            Where are you looking?
                          </h1>
                          <div className="area-container">
                            <Input
                              className={`city-input ${(!!(touched.city && errors.city) || !!(showErrorContainer && values.city === '')) ? 'dark-theme-error' : ''}`}
                              name="city"
                              placeholder="City"
                              type="text"
                              value={values.city}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              error={!!(touched.city && errors.city)}
                            />
                            <FormikDropdown
                              className={`state-input ${!!(touched.state && errors.state) || !!(showErrorContainer && values.state === '')
                                ? 'dark-theme-error' : ''}`}
                              fluid
                              search
                              selection
                              name="state"
                              placeholder="State"
                              value={values.state}
                              options={usStates}
                              error={!!(touched.state && errors.state)}
                            />
                          </div>
                        </Form.Field>
                      </div>
                    )}
                  {(values.stage === 'contract' && values.usage) && (
                    <div className="form-step">
                      <Form.Field className="address">
                        <h1 className="title-instructions">
                          Great, you're in contract on a <br /> property. What's the address?
                        </h1>
                        <AddressInput
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          touched={touched}
                          errors={errors}
                          showLabel={false}
                          darkTheme={true}
                          showErrorContainer={showErrorContainer}
                          values={values}
                          setFieldValue={setFieldValue}
                        />
                      </Form.Field>
                    </div>
                  )}
                  <div className="application-step-footer purchase-footer">
                    {renderButton(values, setShowErrorContainer, disableOnErrors)}
                    <div className="error-container">
                      <ErrorContainer
                        errors={errors}
                        showErrorContainer={showErrorContainer}
                        setDisableOnErrors={setDisableOnErrors}
                      />
                    </div>
                  </div>
                </div>
              </form>
            </>
          )}
        </Formik>
      )}
    </>
  )
})

export default Step2Purchase;
