import { useField } from 'formik';
import { Dropdown } from 'semantic-ui-react';
import React from 'react';

const FormikDropdown = (props: any) => {
  const [field, meta, helpers] = useField(props.name);

  return (
    <Dropdown
      {...props}
      clearable={props.clearable !== undefined ? props.clearable : true}
      fluid
      selection
      selectOnBlur={false}
      upward={false}
      error={props.error}
      options={props.options}
      value={field.value}
      onChange={(e, v) => helpers.setValue(v.value)}
    />
  )
}

export default FormikDropdown;
