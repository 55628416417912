export const clearValues = (values: any, resetValues: Array<string>) => {
  resetValues.map((resetValue: string) => {
    for (let value in values) {
      if (value.includes(resetValue)) {
        if (values[value] instanceof Array) {
          values[value] = []
        }
        else {
          values[value] = ''
        }
      }
    }
  })
}
