import React, {FC, useContext, useEffect, useRef, useState} from 'react';
import { useHistory } from 'react-router-dom';
import BasicAssetForm from './AssetForms/BasicAsset';
import { AddRemoveFormProps } from '../../../components/AddRemoveTable/AddRemoveTable';
import AssetTypeSelection from './AssetForms/AssetTypeSelection';
import { DataContext } from '../../../context/dataContext';
import { ApplicationRoutes } from '../index';
import _ from 'lodash';
import { useStore } from '@jmjfinancial/apis/lib';
import {scrollIntoViewDefaultOptions} from '../../../helpers/scroll-to-options';
import {AssetTypeOption} from './dropdown-data';
import {AssetType, BasicAssetType} from './asset-types';

export interface AssetFormProps extends AddRemoveFormProps {
  loanData: any
  onCancelClick: Function
  updateAssetFormView: Function
  basicAssetType: BasicAssetType
  hasCoborrower?: boolean
  conciergeMode?: boolean
  formData?: any
}

export interface EditAssetFormProps extends AddRemoveFormProps {
  loanData: any
  assetTypeOptions: AssetTypeOption[]
  assetTypes: AssetType[]
  setAssetTypes: Function
  assetIndex: number
  setAssetIndex: Function
  setRefreshAssetsView: Function
  conciergeMode?: boolean
  hasCoborrower?: boolean
  formData?: any
  tableData?: any
}

const EditAssetForm: FC<EditAssetFormProps> = ({
  loanData,
  assetTypeOptions,
  assetTypes,
  setAssetTypes,
  assetIndex,
  setAssetIndex,
  setRefreshAssetsView,
  conciergeMode,
  hasCoborrower,
  setShowForm,
  saveForm,
  formData,
  tableData
}) => {
  const store = useStore();
  const history = useHistory();
  const { loansService } = store;

  const {
    activeLoan,
    pathname,
    reviewMode,
    setDarkThemeOverride,
    setOverrideTrackerVisibility
  } = useContext(DataContext)

  const [assetType, setAssetType] = useState<AssetType>('')

  const formRef = useRef<HTMLDivElement>(null)

  setDarkThemeOverride(false)
  setOverrideTrackerVisibility(false)

  const handleCancelClick = () => {
    if (formData) {
      setShowForm(false)
    }
    else {
      removeAssetType()
    }
  }

  // Remove current asset type
  const removeAssetType = () => {
    const updatedAssetTypes = [...assetTypes]
    updatedAssetTypes.splice((assetIndex), 1)
    console.log('removeAssetType: ', updatedAssetTypes)
    setAssetTypes(updatedAssetTypes)
  }

  const updateAssetFormView = (incrementIndex = true) => {
    // If current asset index is outside of the range of assetTypes, initiate a soft reload from index file
    if (assetIndex >= assetTypes.length) {
      // Force a soft refresh from main index view
      setRefreshAssetsView(true)
    }

    if (incrementIndex) {
      incrementAssetIndex()
    }
  }

  // Advances iteration over `assetTypes` array
  const incrementAssetIndex = () => {
    const nextIndex = assetIndex + 1
    console.log('incrementAssetIndex: nextIndex', nextIndex)
    setAssetIndex(nextIndex)
  }

  const navigateToNextStep = () => {
    if (reviewMode) {
      history.push('/application/review-and-submit')
    }
    else {
      const updatedLoan = {
        application: {
          current_step: ApplicationRoutes[pathname as keyof typeof ApplicationRoutes] + 1,
          completed_steps: {
            assets_done: true
          }
        }
      }

      const dataSubmit = _.merge(loanData, updatedLoan)
      loansService.updateLoan(activeLoan, dataSubmit).then(() => history.push('/application/reo'))
    }
  }

  useEffect(() => {
    console.log('EditAssetForm useEffect() assetType: ', assetTypes[assetIndex])
    // Sets assetType from formData when editing/adding from table view
    if (formData) {
      setAssetType(assetType => formData.basic_asset_type)
    }
    else {
      // Checks to see if all asset types have been iterated through
      if (assetIndex >= assetTypes.length) {
        console.log('assetIndex >= assetTypes.length')

        setRefreshAssetsView(true)
      }

      // Checks if there are no asset types
      if (assetTypes[assetIndex] === 'none') {
        console.log("assetTypes[assetIndex] === 'none'")
        removeAssetType()

        navigateToNextStep()
      }
      // Sets new assetType anytime assetTypes or assetIndex is updated
      else {
        setAssetType(assetTypes[assetIndex])
      }
    }
  }, [assetTypes, assetIndex, formData])

  useEffect(() => {
    if (formRef && formRef.current) {
      formRef.current.scrollIntoView(scrollIntoViewDefaultOptions)
    }
  }, [])

  return (
    // Only show AssetTypeSelection form if not in concierge mode or edit mode
    <div ref={formRef}>
      {!conciergeMode && !formData.basic_asset_type && (
        <AssetTypeSelection
          assetTypeOptions={assetTypeOptions}
          assetType={assetType}
          setAssetType={setAssetType}
          onCancelClick={handleCancelClick}
          tableData={tableData}
        />
      )}
      {assetType && (
        <div className="form-fields-container assets-form-container">
          <BasicAssetForm
            loanData={loanData}
            onCancelClick={handleCancelClick}
            updateAssetFormView={updateAssetFormView}
            basicAssetType={assetType || formData.basic_asset_type}
            hasCoborrower={hasCoborrower}
            conciergeMode={conciergeMode}
            setShowForm={setShowForm}
            saveForm={saveForm}
            formData={formData}
          />
        </div>
      )}
    </div>
  )
}

export default EditAssetForm

