import React, { FC, useContext, useEffect, useState } from 'react';
import { Button, Form, Input } from 'semantic-ui-react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import {useStore} from '@jmjfinancial/apis/lib';
import LoaderOverlay from '../../components/LoaderOverlay/LoaderOverlay';
import { DataContext } from '../../context/dataContext';
import { observer } from 'mobx-react-lite';
import { handleError } from '../../helpers/response-handler';
import { ApplicationRoutes } from '../LoanApplication';
import { getLastUpdatedLoan } from '../../helpers/loan-helper';
import {scrollToDefaultOptions} from '../../helpers/scroll-to-options';

const Login: FC = observer(() => {
  const store = useStore();
  const history = useHistory();
  const { authService, loansService } = store;
  const {
    user,
    setUser,
    setActiveLoan,
    setAllLoans,
    referrer
  } = useContext(DataContext)

  const [isLoading, setIsLoading] = useState(true);
  const [loaderLabel, setLoaderLabel] = useState<string>()
  const authError = 'Incorrect email or password.'

  const startNewLoan = () => {
    console.log('starting new loan...')
    loansService.createLoan({}, referrer).then(newLoan => {
      loansService.setActiveLoan(newLoan.data.loan_id);
      setActiveLoan(newLoan.data.loan_id)

      history.push('/application/start')
    })
  }

  const handleLoanSelect = (loanId: number) => {
    loansService.setActiveLoan(loanId);
    setActiveLoan(loanId)
  }

  useEffect(() => {
    if (user) {
      loansService.getCustomer()
        .then(async customerData => {
          setIsLoading(false)
          if (!customerData.data) {
            setUser(null)
            authService.logout()
          }
          else {
            history.push('/dashboard');
          }
        })
    }
    else {
      setIsLoading(false)
    }
  }, [setUser, loansService, authService, history])

  useEffect(() => {
    window.scrollTo(scrollToDefaultOptions)
  }, [])

  return (
    <>
      <LoaderOverlay active={isLoading} label={loaderLabel} dark />
      <div className="landing-container">
        <div className="landing-content">
          <h1 className="title">Welcome Back</h1>
          <Formik
            initialValues={{
              email: '',
              password: '',
              submit: null
            }}
            validationSchema={Yup.object().shape({
              email:
                Yup.string()
                  .email('Must be a valid email')
                  .max(255)
                  .required('Email is required'),
              password:
                Yup.string()
                  .min(8, 'Password must be at least 8 characters')
                  .max(255)
                  .required('Password is required'),
            })}
            validateOnBlur={false}
            onSubmit={async (values, {
              setErrors,
              setStatus,
              setSubmitting
            }) => {
              try {
                setLoaderLabel('Logging in...')
                setIsLoading(true)

                await authService.login(values.email, values.password).then(res => {
                  if (res.data.success) {
                    setStatus({ success: true })
                    setUser(authService.getCurrentUser());
                    setLoaderLabel('Retrieving your account information...')

                    loansService.getCustomer()
                      .then(customerData => {
                        const customerLoans = customerData.data.loans

                        // Create a new loan just in case a user registered and managed to not start a new loan
                        if (customerLoans.length === 0) {
                          setSubmitting(false);
                          setIsLoading(false)
                          startNewLoan()
                        }
                        else {
                          setLoaderLabel('Retrieving your loans...')
                          loansService.getAllLoans(customerLoans).then(loans => {
                            console.log('finished getting all loans')
                            setAllLoans(loans)

                            const lastUpdatedLoan = getLastUpdatedLoan(loans)

                            handleLoanSelect(lastUpdatedLoan.loan.id)
                            setSubmitting(false);
                            setIsLoading(false)

                            if (lastUpdatedLoan.application.submitted) {
                              console.log('loan submitted, routing to dashboard')
                              history.push('/dashboard');
                            }
                            else {
                              const loanCurrentStep = lastUpdatedLoan.application.current_step
                              console.log('routing to loans last step, route: ', ApplicationRoutes[loanCurrentStep])
                              history.push(ApplicationRoutes[loanCurrentStep])
                            }
                          })
                        }
                      })
                  }
                  else {
                    setIsLoading(false)
                    setStatus({ success: false });
                    setErrors({ submit: authError });
                    setSubmitting(false);
                  }
                });
              } catch (err: any) {
                handleError(err)
                setIsLoading(false)
                setStatus({ success: false });
                setErrors({ submit: authError });
                setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form
                noValidate
                onSubmit={handleSubmit}
                className="auth-form"
              >
                <div className="error-container">
                  {(touched.email && errors.email)
                    || (touched.password && errors.password)
                    || errors.submit
                  }
                </div>
                <Form.Field>
                  <Input
                    name="email"
                    placeholder="Your Email"
                    type="email"
                    className="auth-field"
                    value={values.email}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={Boolean(touched.email && errors.email)}
                  />
                </Form.Field>
                <Form.Field>
                  <Input
                    name="password"
                    placeholder="Password"
                    type="password"
                    className="auth-field"
                    value={values.password}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={Boolean(touched.password && errors.password)}
                  />
                </Form.Field>
                <div className="landing-controls form-controls">
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    className="landing-button auth-button"
                  >
                    Sign In
                  </Button>
                  <Button
                    disabled={isSubmitting}
                    className="landing-button auth-button inverted"
                    onClick={() => { history.push('/forgot-password') }}
                  >
                    Forgot Password
                  </Button>
                </div>
              </form>
            )}
          </Formik>
        </div>
        <div className="landing-graphic" />
      </div>
    </>
  )
});

export default Login;
