import React, {FC, useEffect, useRef, useState} from 'react';
import {Field, FormikTouched, FormikValues} from 'formik';
import {Form} from 'semantic-ui-react';
import FormikDropdown from '../../../../../components/Formik/FormikDropdown';
import {ownershipPercentOptions, taxFilingMethodOptions} from '../../dropdown-data';
import {clearValues} from '../../../../../helpers/clear-values-helper';
import W2YearlyTotalFields from './W2YearlyTotalFields';
import {FormikErrors} from 'formik/dist/types';
import {taxReturnOptions, YearOption} from '../../../../../data/tax-return-options';
import {currentYear} from '../../../../../helpers/date-helpers';

export interface EntityFormProps {
  values: FormikValues
  touched: FormikTouched<FormikValues>
  errors: FormikErrors<FormikValues>
  handleBlur: any
  handleChange: any
  setFieldTouched: any
  showErrorContainer: boolean
}

const EntityForm: FC<EntityFormProps> = ({
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  setFieldTouched,
  showErrorContainer
}) => {
  const [taxFiledYearBusinessOptions, setTaxFiledYearBusinessOptions] = useState<YearOption[]>([
    ...taxReturnOptions,
    {
      text: 'No recent taxes filed',
      value: '0'
    }
  ])
  const [yearsW2Received, setYearsW2Received] = useState<YearOption[]>(taxReturnOptions)

  const refStartingIncomeYear = useRef<string>(values.taxFiledYearBusiness || values.personalTaxFiled)
  const refStartDate = useRef(values.startDate)

  const companyText = values.companyName ? values.companyName : 'this company'

  const previousYearNoneOption: YearOption = {
    text: 'No recent taxes filed',
    value: '0'
  }

  const handleClearIncomeYears = (values: FormikValues) => {
    values.incomeYears = []
    taxReturnOptions.map(selection => selection.checked = false)
  }

  const handleClearTotalIncomeFields = (values: FormikValues) => {
    values.yearToDateAmount = ''
    values.previousYearIncome = ''
    values.previousTwoYearsIncome = ''
  }

  const showBusinessTaxDrop = (values: FormikValues) => {
    if (values.entityType === 'llc' || values.entityType === 'partnership') {
      if (values.ownershipPercent === '100') {
        return values.taxFilingMethod === 'separate'
      }
    }

    return !!values.ownershipPercent
  }

  // Keeps ref values up to date
  useEffect(() => {
    refStartingIncomeYear.current = values.taxFiledYearBusiness || values.personalTaxFiled
    refStartDate.current = values.startDate
  })

  useEffect(() => {
    // If "no recent taxes" has been selected, base W2 fields based on personalTaxFiled
    if (refStartingIncomeYear.current === '0') {
      setYearsW2Received([
        {
          text: values.personalTaxFiled,
          value: values.personalTaxFiled,
          checked: false
        },
        {
          text: `${values.personalTaxFiled - 1}`,
          value: `${values.personalTaxFiled - 1}`,
          checked: false
        }
      ])
    }
    else {
      setYearsW2Received([
        {
          text: refStartingIncomeYear.current,
          value: refStartingIncomeYear.current,
          checked: false
        },
        {
          text: `${parseInt(refStartingIncomeYear.current) - 1}`,
          value: `${parseInt(refStartingIncomeYear.current) - 1}`,
          checked: false
        }
      ])
    }
  }, [refStartingIncomeYear.current])

  useEffect(() => {
    if (refStartDate.current) {
      // Sets dropdown options based on startDate to prevent showing options that are before the startDate
      setTaxFiledYearBusinessOptions(taxFiledYearBusinessOptions => [
        ...taxReturnOptions.filter((option) => parseInt(option.value) >= refStartDate.current.getFullYear()),
        previousYearNoneOption
      ])
    }
  }, [refStartDate.current])

  return values.entityType !== 'soleProprietor' ? (
    <>
      <Form.Field>
        <label>What's your percentage of ownership?</label>
        <FormikDropdown
          className="small"
          fluid
          selection
          name="ownershipPercent"
          placeholder="%"
          options={ownershipPercentOptions}
          onFocus={() => clearValues(values, [
            'taxFilingMethod',
            'taxFiledYearBusiness',
            'previousYearIncome',
            'previousTwoYearsIncome',
          ])}
          value={values.ownershipPercent}
          error={!!(touched.ownershipPercent && errors.ownershipPercent) || (showErrorContainer && values.ownershipPercent === '')}
        />
      </Form.Field>
      {(values.entityType === 'llc' || values.entityType === 'partnership')
      && values.ownershipPercent === '100'
      && refStartDate.current
      && refStartDate.current.getFullYear() < currentYear
      && (
        <Form.Field>
          <label>Got it. Do you file separate tax returns for {companyText} or is the income listed on your personal tax returns Schedule E?</label>
          <FormikDropdown
            className="small"
            fluid
            selection
            name="taxFilingMethod"
            placeholder="Separate, Personal..."
            options={taxFilingMethodOptions}
            onClick={() => clearValues(values, ['taxFiledYearBusiness'])}
            value={values.taxFilingMethod}
            error={!!(touched.taxFilingMethod && errors.taxFilingMethod) || (showErrorContainer && values.taxFilingMethod === '')}
          />
        </Form.Field>
      )}
      {showBusinessTaxDrop(values)
      && taxFiledYearBusinessOptions.length > 1
      && refStartDate.current
      && refStartDate.current.getFullYear() < currentYear
      && (
        <Form.Field>
          <label>
            Great, what's the most recent year
            {(values.entityType !== 'c-corp' && values.ownershipPercent === 'less than 25') ? ' a K-1 was received ' : ' of taxes that were filed '}
            for {companyText}?
          </label>
          <FormikDropdown
            className="small"
            fluid
            selection
            name="taxFiledYearBusiness"
            placeholder={(values.entityType !== 'c-corp' && values.ownershipPercent === 'less than 25') ? 'Year K-1 Was Received' : 'Tax Year Filed'}
            options={taxFiledYearBusinessOptions}
            value={values.taxFiledYearBusiness}
            onClick={() => handleClearTotalIncomeFields(values)}
            error={!!(touched.taxFiledYearBusiness && errors.taxFiledYearBusiness) || (showErrorContainer && values.taxFiledYearBusiness === '')}
          />
        </Form.Field>
      )}
      {refStartingIncomeYear.current
      && refStartDate.current
      && yearsW2Received[0].value >= refStartDate.current?.getFullYear()
      && (
        <Form.Field>
          <label>Next, select all years that you received a W2 from {companyText}</label>
          <div className="payment-types-container">
            <div className="payment-options-container">
              {yearsW2Received.map((option: YearOption, index: number) => option.value >= refStartDate.current?.getFullYear() ? (
                <div
                  key={`${option.value}+${index}`}
                  className="payment-types"
                >
                  <label
                    className={`
                      ${values.incomeYears.find((value: any) => value.toString() === option.value)  ? 'checked' : 'none'}
                      ${((touched.incomeYears && errors.incomeYears) || (touched.noW2 && errors.noW2))
                        || (showErrorContainer && (errors.incomeYears || !values.incomeYears))
                        ? 'has-error' : ''}
                    `}
                  >
                    <Field
                      type="checkbox"
                      name="incomeYears"
                      value={option.value}
                      checked={option.checked}
                      onClick={() => {
                        setFieldTouched('incomeYears')
                        clearValues(values, ['noW2'])
                      }}
                    />
                    {option.value}
                  </label>
                </div>
              ) : <React.Fragment key={`${option.value}+${index}`} />)}
              <div className="payment-types">
                <label
                  className={`
                    ${values.noW2 ? 'checked' : 'none'}
                    ${((touched.noW2 && errors.noW2) || (touched.incomeYears && errors.incomeYears))
                      || (showErrorContainer && (errors.incomeYears || (!values.incomeYears && !values.noW2)))
                      ? 'has-error' : ''}
                  `}
                >
                  <Field
                    type="checkbox"
                    name="noW2"
                    onClick={() => {
                      setFieldTouched('noW2')
                      handleClearIncomeYears(values)
                    }}
                  />
                  None of the above
                </label>
              </div>
            </div>
          </div>
        </Form.Field>
      )}
      <W2YearlyTotalFields
        values={values}
        handleBlur={handleBlur}
        handleChange={handleChange}
        showErrorContainer={showErrorContainer}
        companyText={companyText}
        refStartingIncomeYear={refStartingIncomeYear}
        refStartDate={refStartDate}
      />
    </>
  ) : (
    <W2YearlyTotalFields
      values={values}
      handleBlur={handleBlur}
      handleChange={handleChange}
      showErrorContainer={showErrorContainer}
      companyText={companyText}
      refStartingIncomeYear={refStartingIncomeYear}
      refStartDate={refStartDate}
    />
  )
}

export default EntityForm
