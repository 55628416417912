import React, { FC, useContext, useState, useEffect } from 'react';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import _ from 'lodash';
import { Form, Input, Radio } from 'semantic-ui-react';
import AddressInput from '../../../../components/AddressInput/AddressInput';
import MaskedInput from 'react-text-mask';
import FormikDatePicker from '../../../../components/Formik/FormikDatePicker';
import FormikRadio from '../../../../components/Formik/FormikRadio';
import FormikDropdown from '../../../../components/Formik/FormikDropdown';
import { ownershipYearOptions, salaryFrequencyOptions } from '../dropdown-data';
import { currencyMask } from '../../../../helpers/currency-mask-input';
import { observer } from 'mobx-react-lite';
import { useStore } from '@jmjfinancial/apis/lib';
import { DataContext } from '../../../../context/dataContext';
import IncomeFormHeader from './IncomeFormHeader';
import ApplicationFormFooter from '../../shared/ApplicationFormFooter';
import { IncomeFormProps } from '../EditIncomeForm';
import { clearValues } from '../../../../helpers/clear-values-helper';
import {valuesAreIdentical, valuesAreUnique} from '../../../../helpers/uniqueness-helper';
import FormikErrorMessage from '../../../../components/Formik/ErrorMessage';
import { PHONE_MATCH, PHONE_INPUT_MASK, PHONE_MASK } from '../../../../helpers/regex-helper';
import IncomeModal from '../../../../components/IncomesModal/IncomeModal';
import W2PaymentTypes from './W2PaymentTypes';

interface W2IncomeFormProps extends IncomeFormProps {
  setShowEndDate: Function;
  incomeTypes: any;
}

type PaymentType = 'bonus' | 'commission' | 'overtime' | 'tips' | 'other'

interface PaymentTypeOption {
  value: PaymentType
  checked?: boolean
}

export const initialPaymentTypeOptions: PaymentTypeOption[] = [
  {
    value: 'bonus',
    checked: false
  },
  {
    value: 'commission',
    checked: false
  },
  {
    value: 'overtime',
    checked: false
  },
  {
    value: 'tips',
    checked: false
  },
  {
    value: 'other',
    checked: false
  }
]

const W2IncomeForm: FC<W2IncomeFormProps> = observer(({
  loanData,
  onCancelClick,
  updateIncomeFormView,
  owner,
  ownerName,
  incomeType,
  duplicateIncomeType,
  conciergeMode,
  saveForm,
  setShowForm,
  formData,
  showEndDate,
  setShowEndDate,
  tableData,
  incomeTypes,
}) => {
  const store = useStore();
  const { loansService } = store;
  const currentDate = new Date();

  const { activeLoan } = useContext(DataContext);

  const [disableOnErrors, setDisableOnErrors] = useState<boolean>(false)
  const [showErrorContainer, setShowErrorContainer] = useState<boolean>(false);
  const [previousCalendarYear, setPreviousCalendarYear] = useState<number>(moment(new Date()).year() - 1);
  const [showModal, setShowModal] = useState<boolean>(false);



  const checkForCoborrower = (employments: any) => {
    const coborrowerCheck = employments.find((income: any) => income.owner === 'coborrower')
    if (owner === 'coborrower' && (coborrowerCheck === undefined || !coborrowerCheck)) {
      setShowEndDate(false);
    }
  }

  const handleShowForm = (visible: boolean) => {
    setShowForm(visible);
  }

  const valuesToClear = [
    "employerName",
    "currentEmployer",
    "street",
    "unit",
    "city",
    "state",
    "postal",
    "employerPhone",
    "positionTitle",
    "startDate",
    "yearsInLineOfWork",
  ]

  const twoYearHistory = (values: any) => {
    const today = Date.now()
    if (moment(moment(today)).diff(values.endDate, "months") > 24 ) {
      setShowModal(true)
      // Might need a refactor because there is a random issue clearing the endDate field with the clearValues function in conciergeMode.
      values.endDate = null
      clearValues(values, valuesToClear);
    }
  }

  useEffect(() => {
    checkForCoborrower(loanData.employments)
  }, [])

  const modalHeaderText = "Only Recent Employment Required!"
  const modalBodyText = "We don't need information about income received more than two years ago. If you have received any other W2 income within the last two years, then select 'Yes' to include the income on the form. If you are done with income, select 'No'."

  return (
    <>
      <IncomeFormHeader
        ownerName={ownerName}
        incomeType={incomeType}
        duplicateIncomeType={duplicateIncomeType}
        conciergeMode={conciergeMode}
      />
      <IncomeModal
        showModal={showModal}
        setShowModal={setShowModal}
        setShowForm={handleShowForm}
        tableData={tableData}
        loanData={loanData}
        modalHeader={modalHeaderText}
        modalBody={modalBodyText}
        incomeTypes={incomeTypes}
        updateIncomeFormView={updateIncomeFormView}
        conciergeMode={conciergeMode}
      />
      <Formik
        initialValues={{
          paymentTypes: formData?.payment_types || [],
          employerName: formData?.employer_name || '',
          currentEmployer: formData?.currently_employed?.toString() || '',
          street: formData?.address_street_line || '',
          unit: formData?.address_unit || '',
          city: formData?.address_city || '',
          state: formData?.address_state || '',
          postal: formData?.address_postal || '',
          county: formData?.address_county || '',
          employerPhone: formData?.phone_number?.replace(/^\+[0-9]/, '') || '',
          positionTitle: formData?.position_description || '',
          startDate: formData?.start_date ? new Date(formData?.start_date) : '',
          yearsInLineOfWork: formData?.time_in_line_of_work_years || '',
          endDate: formData?.end_date ? new Date(formData?.end_date) : '',
          monthlyIncome: formData?.amount?.toString() || '',
          otherIncome: formData?.other_income?.toString() || '',
          overtimeAmount: formData?.overtime_amount?.toString() || '',
          bonusAmount: formData?.bonus_amount?.toString() || '',
          commissionAmount: formData?.commission_amount?.toString() || '',
          tipsAmount: formData?.tip_amount?.toString() || '',
          otherAmount: formData?.other_amount?.toString() || '',

          paymentType: formData?.payment_type || '',
          salaryIncome: formData?.salary_rate?.toString() || '',
          paymentFrequency: formData?.payment_frequency || '',
          hourlyIncome: formData?.hourly_rate?.toString() || '',
          hoursAverage: formData?.hours_averaged?.toString() || '',

          currentEmployment: !showEndDate,
          conciergeMode: conciergeMode,

          previousCalendarYear: previousCalendarYear,

          submit: null
        }}
        validationSchema={Yup.object().shape({
          employerName: Yup.string().required(' '),
          currentEmployer: Yup.string().when('conciergeMode', {
            is: false,
            then: Yup.string().required(' ')
          }),
          street: Yup.string().min(2, 'Invalid address entered, minimum of 2 characters required').required(' '),
          unit: Yup.string(),
          city: Yup.string().required(' '),
          state: Yup.string().required(' '),
          postal: Yup.string()
            .nullable()
            .matches(/^[0-9]{5}$/, 'Must be exactly 5 digits')
            .required(' '),
          employerPhone: Yup.string()
            .matches(PHONE_MASK, 'Phone number must be 10 digits')
            .matches(PHONE_MATCH, 'Area code cannot start with 1')
            .required(' '),
          positionTitle: Yup.string().required(),
          startDate: Yup.string().required(),
          yearsInLineOfWork: Yup.number().required(),
          endDate: Yup.string().when(['currentEmployment', 'currentEmployer'], {
            is: (currentEmployment: boolean, currentEmployer: string) => (currentEmployment === false && currentEmployer !== 'true'),
            then: Yup.string().required()
          }),
          paymentType: Yup.string().required(' '),
          paymentTypes: Yup.array().required(' '),
          salaryIncome: Yup.string().when(['paymentType', 'currentEmployment', 'currentEmployer'], {
            is: (paymentType: string, currentEmployment: boolean, currentEmployer: string) => (paymentType === 'salary' && (currentEmployment === true || currentEmployer === 'true')),
            then: Yup.string().required(' ')
          }),
          otherIncome: Yup.string().when(['paymentTypes'], {
            is: (paymentTypes: any) => paymentTypes.find((type: string) => type === 'other'),
            then: Yup.string().required(' ')
          }),
          paymentFrequency:  Yup.string().when(['paymentType', 'currentEmployment', 'currentEmployer'], {
            is: (paymentType: string, currentEmployment: boolean, currentEmployer: string) => (paymentType === 'salary' && (currentEmployment === true || currentEmployer === 'true')),
            then: Yup.string().required(' ')
          }),
          hourlyIncome:  Yup.string().when(['paymentType', 'currentEmployment', 'currentEmployer'], {
            is: (paymentType: string, currentEmployment: boolean, currentEmployer: string) => (paymentType === 'hourly' && (currentEmployment === true || currentEmployer === 'true')),
            then: Yup.string().required(' '),
          }),
          hoursAverage:  Yup.string().when(['paymentType', 'currentEmployment', 'currentEmployer'], {
            is: (paymentType: string, currentEmployment: boolean, currentEmployer: string) => (paymentType === 'hourly' && (currentEmployment === true || currentEmployer === 'true')),
            then: Yup.string().required(' '),
          }),
          overtimeAmount: Yup.string().when(['paymentTypes'], {
            is: (paymentTypes: any) => paymentTypes.find((type: string) => type === 'overtime'),
            then: Yup.string().required(' ')
          }),
          bonusAmount: Yup.string().when(['paymentTypes'], {
            is: (paymentTypes: any) => paymentTypes.find((type: string) => type === 'bonus'),
            then: Yup.string().required(' ')
          }),
          commissionAmount: Yup.string().when(['paymentTypes'], {
            is: (paymentTypes: any) => paymentTypes.find((type: string) => type === 'commission'),
            then: Yup.string().required(' ')
          }),
          tipsAmount: Yup.string().when(['paymentTypes'], {
            is: (paymentTypes: any) => paymentTypes.find((type: string) => type === 'tips'),
            then: Yup.string().required(' ')
          }),
          otherAmount: Yup.string().when(['paymentTypes'], {
            is: (paymentTypes: any) => paymentTypes.find((type: string) => type === 'other'),
            then: Yup.string().required(' ')
          }),
        })}
        onSubmit={(values, {
          setErrors,
          setStatus,
          setSubmitting,
          resetForm,
        }) => {
          try {

            // This functions solves the issue of adding a monthly value from HIVE and calculates the monthly value of an income ONLY if the income years overlap with the last three calendar years. Otherwise, the user will not be asked to submit an income for income that is less than 3 years old and will display as $0 in the table.
            const getMonthlyPaymentAmount = (values: any) => {
              if (values.paymentType === 'hourly') {
                if (values.hourlyIncome) {
                  return (values.hourlyIncome.replace(/[$,]/g, '') * values.hoursAverage) * 4;
                }
                else {
                  return 0;
                }
              }
              else {
                if (values.salaryIncome) {
                  return values.salaryIncome.replace(/[$,]/g, '') / 12;
                }
                else {
                  return 0;
                }
              }
            }

            const getTotalAmount = (amount: number, values: any) => {
              let commissions = 0

              if (values.salaryIncome) {
                commissions = (parseInt(values.overtimeAmount?.replace(/[$,]/g, '')) || 0) +
                  (parseInt(values.bonusAmount?.replace(/[$,]/g, '')) || 0) +
                  (parseInt(values.commissionAmount?.replace(/[$,]/g, '')) || 0)
              }

              return commissions + amount
            }

            const changedData = {
              owner: owner,
              id: formData?.id || null,
              income_type: 'W-2 Wage Earner',
              self_employed: false,
              current_employer: values.currentEmployer,
              employer_name: values.employerName,
              position_description: values.positionTitle,
              start_date: new Date(values.startDate),
              end_date: values.endDate ? new Date(values.endDate) : null,
              phone_number: values.employerPhone.replace(/\D/g, ''),
              time_on_job_term_years: !values.endDate
                ? moment(moment(currentDate)).diff(values.startDate, 'years')
                : moment(moment(values.endDate)).diff(values.startDate, 'years'),
              time_on_job_term_months: !values.endDate
                ? moment(moment(currentDate)).diff(values.startDate, 'months')
                : moment(moment(values.endDate)).diff(values.startDate, 'months'),
              time_in_line_of_work_years: values.yearsInLineOfWork,

              address_street_line: values.street,
              address_unit: values.unit,
              address_city: values.city,
              address_state: values.state,
              address_postal: values.postal,
              address_county: values.county,

              overtime_amount: values.overtimeAmount?.replace(/[$,]/g, ''),
              commission_amount: values.commissionAmount?.replace(/[$,]/g, ''),
              bonus_amount: values.bonusAmount.replace(/[$,]/g, ''),
              tip_amount: values.tipsAmount.replace(/[$,]/g, ''),
              other_amount: values.otherAmount.replace(/[$,]/g, ''),
              other_income: values.otherIncome,

              payment_type: values.paymentType,
              payment_types: values.paymentTypes,
              salary_rate: values.salaryIncome?.replace(/[$,]/g, ''),
              payment_frequency: values.paymentFrequency,
              hourly_rate: values.hourlyIncome?.replace(/[$,]/g, ''),
              hours_averaged: values.hoursAverage,

              // non formik values that are being calculated and sent to Hive
              base_pay_amount: getMonthlyPaymentAmount(values),
              amount: getTotalAmount(getMonthlyPaymentAmount(values), values),
              currently_employed: values.currentEmployer === '' ? !showEndDate : values.currentEmployer,
            }

            if (conciergeMode) {
              loanData.employments = []
              loanData.incomes = []
              const dataSubmit = _.merge(loanData, { employments: [changedData] })
              loansService.updateLoan(activeLoan, dataSubmit).then((res: any) => {
                if (res.success) {
                  // If concierge mode, do not increment index
                  updateIncomeFormView(false)
                  setStatus({ success: true });
                  setSubmitting(false);
                  resetForm({});
                }
                else {
                  setStatus({ success: false });
                  // TODO: Replace ternary comparative value with something other than undefined
                  setErrors({ submit: res.error === undefined
                      ? `You have already added "${values.positionTitle} at ${values.employerName}"`
                      : res.errors?.base[0] || res.error
                  });
                  setSubmitting(false);
                }
              })

            }
            else {
              let skipUniquenessCheck = false

              if (formData.income_type) {
                skipUniquenessCheck = valuesAreIdentical(
                  [
                    formData.employer_name,
                    formData.position_description,
                    new Date(formData.start_date).toISOString().split('T')[0]
                  ],
                  [
                    values.employerName,
                    values.positionTitle,
                    new Date(values.startDate).toISOString().split('T')[0]
                  ]
                )
              }

              if (skipUniquenessCheck || (!skipUniquenessCheck && valuesAreUnique(
                [
                  owner === 'borrower' ? loanData.borrower.id : loanData.coborrower.id,
                  values.employerName,
                  values.positionTitle,
                  new Date(values.startDate).toISOString().split('T')[0]
                ],
                loanData,
                'employments',
                [
                  'borrower_id',
                  'employer_name',
                  'position_description',
                  'start_date'
                ],
                tableData
              ))) {
                saveForm(changedData)
                setStatus({ success: true });
                setSubmitting(false);
                resetForm({});
                setShowForm(false)
              }
              else {
                setStatus({ success: false });
                setErrors({ submit: `You have already added "${values.positionTitle} at ${values.employerName}"` });
                setSubmitting(false);
              }
            }
          } catch (err: any) {
            console.error('Income source error: ', err.message);
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          touched,
          values
        }) => (
          <form
            onSubmit={handleSubmit}
            className="application-form"
          >
            {!conciergeMode && (
              <div className="form-step">
                <Form.Field>
                  <label>Are you currently employed here?</label>
                  <div className={`radio-group ${showErrorContainer && values.currentEmployer === '' && 'radio-error'}`}>
                    <FormikRadio
                      label="Yes"
                      name="currentEmployer"
                      value='true'
                      checked={values.currentEmployer === 'true'}
                      className={errors.currentEmployer && 'has-error'}
                      onClick={() => clearValues(values, ['endDate'])}
                    />
                    <FormikRadio
                      label="No"
                      name="currentEmployer"
                      value='false'
                      checked={values.currentEmployer === 'false'}
                      className={errors.currentEmployer && 'has-error'}
                    />
                  </div>
                </Form.Field>
              </div>
            )}
            <div className="form-step">
              <Form.Field>
                <label>{showEndDate && values.currentEmployer !== 'true' ? `What was ` : `What's `}the name of your employer?</label>
                <Input
                  name="employerName"
                  placeholder="Employer Name"
                  type="text"
                  value={values.employerName}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={!!(showErrorContainer && values.employerName === '')}
                />
              </Form.Field>
            </div>
            <AddressInput
              handleBlur={handleBlur}
              handleChange={handleChange}
              touched={touched}
              errors={errors}
              label="What's the address?"
              showErrorContainer={showErrorContainer}
              setFieldValue={setFieldValue}
              values={values}
              companyAddress={true}
            />
            <div className="form-step">
              <Form.Field>
                <label>What's the corporate or HR phone number?</label>
                  <Input
                    className="small"
                    error={!!(showErrorContainer && values.employerPhone === '')}
                  >
                    <MaskedInput
                      mask={PHONE_INPUT_MASK}
                      guide={false}
                      name="employerPhone"
                      placeholderChar={`\u2000`}
                      placeholder="Employer Phone"
                      type="text"
                      value={values.employerPhone}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  <FormikErrorMessage name="employerPhone" />
                </Input>
              </Form.Field>
            </div>
            <div className="form-step">
              <Form.Field>
                <label>{showEndDate && values.currentEmployer !== 'true' ? `What was ` : `What's `}your position?</label>
                <Input
                  name="positionTitle"
                  placeholder="Position"
                  type="text"
                  value={values.positionTitle}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={!!(showErrorContainer && values.positionTitle === '')}
                />
              </Form.Field>
            </div>
            <div className="form-step">
              <Form.Field>
                <label>What date did you start?</label>
                <FormikDatePicker
                  className="small"
                  name="startDate"
                  icon="calendar alternate outline"
                  placeholder="MM/DD/YYYY"
                  format="MM-DD-YYYY"
                  maxDate={new Date()}
                  value={values.startDate}
                  error={!!(showErrorContainer && values.startDate === '')}
                />
              </Form.Field>
            </div>
            {/* If not current entry, ask for end date.
            Current entry is the first entry in the E&I array.
            if coborrower and their first entry in the array, don't show endDate. */}
            {showEndDate && values.currentEmployer !== 'true' && (
              <div className="form-step">
                <Form.Field>
                  <label>End date at Employer </label>
                  <FormikDatePicker
                    className="small"
                    name="endDate"
                    icon="calendar alternate outline"
                    placeholder="MM/DD/YYYY"
                    format="MM-DD-YYYY"
                    maxDate={new Date()}
                    value={values.endDate}
                    error={!!(showErrorContainer && values.endDate === '')}
                  />
                </Form.Field>
              </div>
            )}
            {values.endDate && twoYearHistory(values)}
            <div className="form-step">
              <Form.Field>
                <label>How many years {showEndDate && values.currentEmployer !== 'true' ? 'were you' : 'have you been'} in this line of work?</label>
                <FormikDropdown
                  className="small"
                  fluid
                  selection
                  name="yearsInLineOfWork"
                  placeholder="Years"
                  value={values.yearsInLineOfWork}
                  options={ownershipYearOptions}
                  error={!!(showErrorContainer && values.yearsInLineOfWork === '')}
                />
              </Form.Field>
            </div>
            <W2PaymentTypes
              showEndDate={showEndDate}
              values={values}
              touched={touched}
              errors={errors}
              handleBlur={handleBlur}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
              showErrorContainer={showErrorContainer}
              clearValues={clearValues}
            />
            <ApplicationFormFooter
              onCancelClick={onCancelClick}
              nextDisabled={isSubmitting || disableOnErrors}
              errors={errors}
              showErrorContainer={showErrorContainer}
              setShowErrorContainer={setShowErrorContainer}
              setDisableOnErrors={setDisableOnErrors}
              errorMessage={errors.submit ? errors.submit : undefined}
            />
          </form>
        )}
      </Formik>
    </>
  )
})

export default W2IncomeForm
