import React, {FC} from 'react';
import {Icon, SemanticICONS} from 'semantic-ui-react';

export interface DashDetail {
  label: string,
  icon?: SemanticICONS
  onClick?: Function
  href?: string
}

const DashDetailComponent: FC<DashDetail> = ({
  label,
  icon,
  onClick,
  href
}) => {
  if (href) {
    return (
      <a
        target="_blank"
        className="dash-detail is-clickable"
        href={href}
      >
        <label>{label}</label>
      </a>
    )
  }
  else {
    return (
      <div
        className={`dash-detail ${onClick ? 'is-clickable' : ''}`}
        onClick={onClick ? (event) => {
          event.stopPropagation()
          onClick()
        } : () => null}
      >
        {icon && (
          <Icon
            name={icon}
          />
        )}
        <label>{label}</label>
      </div>
    )
  }
}

export default DashDetailComponent
