import React, { FC, useContext, useEffect, useRef, useState } from 'react';
import {Button, Dropdown, DropdownProps, Form, Radio} from 'semantic-ui-react';
import * as Yup from 'yup';
import {Formik, FormikErrors, FormikTouched, FormikValues, useField} from 'formik';
import { useStore } from '@jmjfinancial/apis/lib';
import { DataContext } from '../../../../context/dataContext';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import ErrorContainer from '../../../../components/ErrorContainer';
import { scrollIntoViewDefaultOptions } from '../../../../helpers/scroll-to-options';
import { valuesAreIdentical, valuesAreUnique } from '../../../../helpers/uniqueness-helper';
import PurchasePrimaryPath from './PurchasePrimaryPath';
import PropertyUsagePath from './PropertyUsagePath';
import {lastYear, twoYearsAgo} from '../../../../helpers/date-helpers';
import FormikMaskedInput from '../../../../components/Formik/FormikMaskedInput';
import {currencyMask} from '../../../../helpers/currency-mask-input';
import FormikDropdown from '../../../../components/Formik/FormikDropdown';
import {
  financingOptions,
  impoundOptions,
  propertyTypeOptions
} from '../reo-options';
import {clearValues} from '../../../../helpers/clear-values-helper';
import AddressInput from '../../../../components/AddressInput/AddressInput';
import HelperPopup from '../../../../components/Popup/Popup';

export interface REOSubPathProps {
  values: FormikValues
  errors: FormikErrors<any>
  handleBlur: Function
  handleChange: Function
  touched: FormikTouched<any>
  showErrorContainer: boolean
  hasPrimaryResidence?: boolean
}

interface REOPathFormProps {
  loanData: any
  incrementREOIndex: Function
  conciergeMode: boolean,
  setShowForm: Function
  handleFormCancelClick: Function
  saveForm: Function
  formData?: any
  tableData?: any
  borrowerData?: any
}

type PathType = 'purchasePrimary' | 'propertyUsage'

const REOPathForm: FC<REOPathFormProps> = observer(({
  loanData,
  incrementREOIndex,
  conciergeMode,
  setShowForm,
  handleFormCancelClick,
  saveForm,
  formData,
  tableData,
  borrowerData
}) => {
  const store = useStore();
  const { loansService } = store;

  const { activeLoan } = useContext(DataContext);

  const [disableOnErrors, setDisableOnErrors] = useState<boolean>(false);
  const [showErrorContainer, setShowErrorContainer] = useState<boolean>(false);
  const [path, setPath] = useState<PathType>('propertyUsage')
  const [currentPropertyIsOwned, setCurrentPropertyIsOwned] = useState<boolean>(false)
  const [hasPrimaryResidence, setHasPrimaryResidence] = useState<boolean>(false)

  const formRef = useRef<HTMLFormElement>(null)
  const currentPropertyIsOwnedRef = useRef<boolean>(currentPropertyIsOwned)
  const pathRef = useRef<PathType>(path)

  const setCurrentPath = () => {
    const loanType = loanData?.loan?.loan_purpose_type
    const propertyUsage = loanData?.property?.usage

    if (loanType === 'purchase' && currentPropertyIsOwnedRef.current) {
      setPath(path => 'purchasePrimary')
    }
    else {
      setPath(path => 'propertyUsage')
    }
  }

  useEffect(() => {
    if (formRef && formRef.current) {
      formRef.current.scrollIntoView(scrollIntoViewDefaultOptions)
    }
  }, [])

  useEffect(() => {
    currentPropertyIsOwnedRef.current = currentPropertyIsOwned
    pathRef.current = path
  })

  useEffect(() => {
    const activeREO = loanData.residences.find((residence: any) => residence.id === formData.residence_id)

    setCurrentPropertyIsOwned(currentPropertyIsOwned => activeREO?.current && activeREO?.still_own_residence)
  }, [loanData?.residences])

  useEffect(() => {
    setCurrentPath()
  }, [loanData?.loan?.loan_purpose_type, loanData?.property?.usage, currentPropertyIsOwned])

  useEffect(() => {
    setHasPrimaryResidence(hasPrimaryResidence =>
      !!loanData?.reo?.find((reo: any) => reo.property_usage_type === 'primary')
      || !!tableData?.find((reo: any) => reo.property_usage_type === 'primary')
    )
  }, [loanData?.reo, tableData])

  const FinancingDropdown = (props: DropdownProps) => {

    const [field, meta, helpers] = useField(props.name)

    return (
      <Dropdown
        {...props}
        clearable
        fluid
        selection
        selectOnBlur={false}
        upward={false}
        onChange={(e,v) => {
          switch (v.value) {
            case 'firstAndSecondMortgage':
              clearValues(props.values, [
                'thirdMortgageType',
                'thirdMortgageLoanAmount',
                'thirdMortgageInterestRate',
                'thirdMortgagePayment',
                'thirdMortgageImpounded',
                'thirdMortgageBalance',
                'thirdMortgageCreditLimit',
              ]);
              break;
            case 'firstMortgageOnly':
              clearValues(props.values, [
                'secondMortgageType',
                'secondMortgageLoanAmount',
                'secondMortgageInterestRate',
                'secondMortgagePayment',
                'secondMortgageImpounded',
                'secondMortgageBalance',
                'secondMortgageCreditLimit',
                'thirdMortgageType',
                'thirdMortgageLoanAmount',
                'thirdMortgageInterestRate',
                'thirdMortgagePayment',
                'thirdMortgageImpounded',
                'thirdMortgageBalance',
                'thirdMortgageCreditLimit',
              ])
              break;
            case 'freeAndClear':
              clearValues(props.values, [
                'firstMortgageLoanAmount',
                'firstMortgageInterestRate',
                'firstMortgagePayment',
                'firstMortgageImpounded',
                'secondMortgageType',
                'secondMortgageLoanAmount',
                'secondMortgageInterestRate',
                'secondMortgagePayment',
                'secondMortgageImpounded',
                'secondMortgageBalance',
                'secondMortgageCreditLimit',
                'thirdMortgageType',
                'thirdMortgageLoanAmount',
                'thirdMortgageInterestRate',
                'thirdMortgagePayment',
                'thirdMortgageImpounded',
                'thirdMortgageBalance',
                'thirdMortgageCreditLimit',
              ])
              break;
          }
          // Sets the value onChange
          helpers.setValue(v.value)
        }}
      />
    )
  }

  return (
    <>
      <div className="application-step-container real-estate-owned-container">
        <Formik
          initialValues={{
            owner: formData?.owner || '',
            street: formData?.address_street_line || '',
            unit: formData?.address_unit || '',
            city: formData?.address_city || '',
            state: formData?.address_state || '',
            postal: formData?.address_postal || '',
            county: formData?.address_county || '',

            propertyPlans: formData?.property_plans || null,
            sellTimeline: formData?.sell_time_line || null,
            sellNet: formData?.sell_net_value_amount || null,
            isRent: formData?.is_rent?.toString() || null,
            rentalIncomeGrossAmount: formData?.rental_income_gross_amount || null,

            marketValueAmount: formData?.market_value_amount || '',
            propertyType: formData?.gse_property_type || '',
            hasHOA: formData?.has_hoa?.toString() || '',
            hoaDues: formData?.hoa_dues_amount || null,
            propertyUsageType: conciergeMode
              && loanData.loan.loan_purpose_type === 'refinance'
              && loanData.property.id === formData.property_id
              ? loanData.property.usage
              : formData?.property_usage_type || null,
            personalTaxFiled: borrowerData?.tax_filed_year?.toString() || loanData?.borrower?.tax_filed_year?.toString() || null,
            propertyOnCurrentTaxReturn: formData?.property_on_current_tax_return_year?.toString() || null,
            propertyOnPriorTaxReturn: formData?.property_on_prior_tax_return_year?.toString() || null,
            propertyStatus: formData?.property_status || null,

            taxes: formData?.taxes_amount || '',

            propertyFinancing: formData?.property_financing || '',
            firstMortgageLoanAmount: formData?.first_mortgage_loan_amount || '',
            firstMortgageInterestRate: formData?.first_mortgage_interest_rate || '',
            firstMortgagePayment: formData?.first_mortgage_payment || '',
            firstMortgageImpounded: formData?.first_mortgage_impounded || '',
            secondMortgageType: formData?.second_mortgage_type || '',
            secondMortgageLoanAmount: formData?.second_mortgage_loan_amount || '',
            secondMortgageInterestRate: formData?.second_mortgage_interest_rate || '',
            secondMortgagePayment: formData?.second_mortgage_payment || '',
            secondMortgageImpounded: formData?.second_mortgage_impounded || '',
            secondMortgageBalance: formData?.second_mortgage_balance || '',
            secondMortgageCreditLimit: formData?.second_mortgage_credit_limit || '',
            thirdMortgageType: formData?.third_mortgage_type || '',
            thirdMortgageLoanAmount: formData?.third_mortgage_loan_amount || '',
            thirdMortgageInterestRate: formData?.third_mortgage_interest_rate || '',
            thirdMortgagePayment: formData?.third_mortgage_payment || '',
            thirdMortgageImpounded: formData?.third_mortgage_impounded || '',
            thirdMortgageBalance: formData?.third_mortgage_balance || '',
            thirdMortgageCreditLimit: formData?.third_mortgage_credit_limit || '',

            annualPropertyTax: formData?.annual_property_tax || '',
            annualInsurancePremium: formData?.insurance_amount || '',

            submit: null
          }}
          validationSchema={Yup.object().shape({
            // Will always be required
            street: Yup.string().min(2, 'Invalid address entered, minimum of 2 characters required').required(' '),
            unit: Yup.string(),
            city: Yup.string().required(' '),
            state: Yup.string().required(' '),
            postal: Yup.string().required(' ').matches(/^[0-9]{5}$/, 'Zipcode must be exactly 5 digits'),
            marketValueAmount: Yup.string().required(' '),
            propertyType: Yup.string().required(' '),
            hasHOA: Yup.string().nullable().when('propertyType', {
              is: 'sfr',
              then: Yup.string().required(' ')
            }),
            hoaDues: Yup.string().nullable().when(['hasHOA', 'propertyType'], {
              is: (hasHOA: string, propertyType: string) => hasHOA?.toString() === 'true' || propertyType === 'condo',
              then: Yup.string().required(' ')
            }),
            propertyFinancing: Yup.string().required(' '),
            firstMortgageLoanAmount: Yup.string().when(['propertyFinancing'], {
              is: (propertyFinancing: string) => propertyFinancing === 'firstMortgageOnly' || propertyFinancing === 'firstAndSecondMortgage' || propertyFinancing === 'firstSecondAndThirdMortgage',
              then: Yup.string().required(' ')
            }),
            firstMortgagePayment: Yup.string().when(['propertyFinancing'], {
              is: (propertyFinancing: string) => propertyFinancing === 'firstMortgageOnly' || propertyFinancing === 'firstAndSecondMortgage' || propertyFinancing === 'firstSecondAndThirdMortgage',
              then: Yup.string().required(' ')
            }),
            firstMortgageImpounded: Yup.string().when(['propertyFinancing'], {
              is: (propertyFinancing: string) => propertyFinancing === 'firstMortgageOnly' || propertyFinancing === 'firstAndSecondMortgage' || propertyFinancing === 'firstSecondAndThirdMortgage',
              then: Yup.string().required(' ')
            }),
            secondMortgageType: Yup.string().when(['propertyFinancing'], {
              is: (propertyFinancing: string) => propertyFinancing === 'firstAndSecondMortgage' || propertyFinancing === 'firstSecondAndThirdMortgage',
              then: Yup.string().required(' ')
            }),
            secondMortgageLoanAmount: Yup.string().when(['propertyFinancing', 'secondMortgageType'], {
              is: (propertyFinancing: string, secondMortgageType: string) => (propertyFinancing === 'firstAndSecondMortgage' || propertyFinancing === 'firstSecondAndThirdMortgage') && secondMortgageType === 'fixed',
              then: Yup.string().required(' ')
            }),
            secondMortgagePayment: Yup.string().when(['propertyFinancing', 'secondMortgageType'], {
              is: (propertyFinancing: string, secondMortgageType: string) => (propertyFinancing === 'firstAndSecondMortgage' || propertyFinancing === 'firstSecondAndThirdMortgage') && (secondMortgageType === 'fixed' || secondMortgageType === 'heloc'),
              then: Yup.string().required(' ')
            }),
            secondMortgageBalance: Yup.string().when(['propertyFinancing'], {
              is: (propertyFinancing: string) => propertyFinancing === 'firstAndSecondMortgage' || propertyFinancing === 'firstSecondAndThirdMortgage',
              then: Yup.string().required(' ')
            }),
            thirdMortgageType: Yup.string().when('propertyFinancing', {
              is: 'firstSecondAndThirdMortgage',
              then: Yup.string().required(' ')
            }),
            thirdMortgageLoanAmount: Yup.string().when(['propertyFinancing', 'thirdMortgageType'], {
              is: (propertyFinancing: string, thirdMortgageType: string) => (propertyFinancing === 'firstSecondAndThirdMortgage') && thirdMortgageType === 'fixed',
              then: Yup.string().required(' ')
            }),
            thirdMortgagePayment: Yup.string().when(['propertyFinancing', 'thirdMortgageType'], {
              is: (propertyFinancing: string, thirdMortgageType: string) => (propertyFinancing === 'firstSecondAndThirdMortgage') && (thirdMortgageType === 'fixed' || thirdMortgageType === 'heloc'),
              then: Yup.string().required(' ')
            }),
            thirdMortgageBalance: Yup.string().when(['propertyFinancing'], {
              is: (propertyFinancing: string) => propertyFinancing === 'firstSecondAndThirdMortgage',
              then: Yup.string().required(' ')
            }),
            annualPropertyTax: Yup.string().when(['firstMortgageImpounded'], {
              is: (firstMortgageImpounded: string) => firstMortgageImpounded === 'taxes-and-insurance' || firstMortgageImpounded === 'tax-only',
              then: Yup.string(),
              otherwise: Yup.string().required(' ')
            }),
            annualInsurancePremium: Yup.string().when(['firstMortgageImpounded'], {
              is: (firstMortgageImpounded: string) => firstMortgageImpounded === 'taxes-and-insurance' || firstMortgageImpounded === 'insurance-only',
              then: Yup.string(),
              otherwise: Yup.string().required(' ')
            }),


            // Required for Purchase Primary Path
            // Custom test for `pathRef` due to default `.when()` testing against a stale state
            propertyPlans: Yup.string().nullable().test(value => pathRef.current === 'purchasePrimary' ? value !== null : true),
            sellTimeline: Yup.string().nullable().when('propertyPlans', {
              is: 'sell',
              then: Yup.string().required(' ')
            }),
            sellNet: Yup.string().nullable().when('sellTimeline', {
              is: 'before',
              then: Yup.string().required(' ')
            }),
            isRent: Yup.string().nullable().when('propertyPlans', {
              is: 'keep',
              then: Yup.string().required(' ')
            }),
            rentalIncomeGrossAmount: Yup.string().nullable().when(['isRent', 'propertyUsageType'], {
              is: (
                isRent: string,
                propertyUsageType: string
              ) => isRent === 'true'
                || propertyUsageType === 'investment',
              then: Yup.string().required(' ')
            }),
            // firstMortgageInterestRate: Yup.string().when(['path1', 'propertyFinancing'], {
            //   is: (path1: boolean, propertyFinancing: string) => path1 && propertyFinancing === 'firstMortgageOnly' || propertyFinancing === 'firstAndSecondMortgage' || propertyFinancing === 'firstSecondAndThirdMortgage',
            //   then: Yup.string().required(' ')
            // }),
            // secondMortgageInterestRate: Yup.string().when(['path1', 'propertyFinancing', 'secondMortgageType'], {
            //   is: (path1: boolean, propertyFinancing: string, secondMortgageType: string) => (path1 && propertyFinancing === 'firstAndSecondMortgage' || propertyFinancing === 'firstSecondAndThirdMortgage') && (secondMortgageType === 'fixed' || secondMortgageType === 'heloc'),
            //   then: Yup.string().required(' ')
            // }),
            // secondMortgageImpounded: Yup.string().when(['path1', 'propertyFinancing', 'secondMortgageType'], {
            //   is: (path1: boolean, propertyFinancing: string, secondMortgageType: string) => (path1 && propertyFinancing === 'firstAndSecondMortgage' || propertyFinancing === 'firstSecondAndThirdMortgage') && secondMortgageType === 'fixed',
            //   then: Yup.string().required(' ')
            // }),
            // secondMortgageBalance: Yup.string().when(['path1', 'propertyFinancing', 'secondMortgageType'], {
            //   is: (path1: boolean, propertyFinancing: string, secondMortgageType: string) => (path1 && propertyFinancing === 'firstAndSecondMortgage' || propertyFinancing === 'firstSecondAndThirdMortgage') && secondMortgageType === 'heloc',
            //   then: Yup.string().required(' ')
            // }),
            // secondMortgageCreditLimit: Yup.string().when(['path1', 'propertyFinancing', 'secondMortgageType'], {
            //   is: (path1: boolean, propertyFinancing: string, secondMortgageType: string) => (path1 && propertyFinancing === 'firstAndSecondMortgage' || propertyFinancing === 'firstSecondAndThirdMortgage') && secondMortgageType === 'heloc',
            //   then: Yup.string().required(' ')
            // }),
            // thirdMortgageInterestRate: Yup.string().when(['path1', 'propertyFinancing', 'thirdMortgageType'], {
            //   is: (path1: boolean, propertyFinancing: string, thirdMortgageType: string) => (path1 && propertyFinancing === 'firstSecondAndThirdMortgage') && (thirdMortgageType === 'fixed' || thirdMortgageType === 'heloc'),
            //   then: Yup.string().required(' ')
            // }),
            // thirdMortgageImpounded: Yup.string().when(['path1', 'propertyFinancing', 'thirdMortgageType'], {
            //   is: (path1: boolean, propertyFinancing: string, thirdMortgageType: string) => (path1 && propertyFinancing === 'firstSecondAndThirdMortgage') && thirdMortgageType === 'fixed',
            //   then: Yup.string().required(' ')
            // }),
            // thirdMortgageBalance: Yup.string().when(['path1', 'propertyFinancing', 'thirdMortgageType'], {
            //   is: (path1: boolean, propertyFinancing: string, thirdMortgageType: string) => (path1 && propertyFinancing === 'firstSecondAndThirdMortgage') && thirdMortgageType === 'heloc',
            //   then: Yup.string().required(' ')
            // }),
            // thirdMortgageCreditLimit: Yup.string().when(['path1', 'propertyFinancing', 'thirdMortgageType'], {
            //   is: (path1: boolean, propertyFinancing: string, thirdMortgageType: string) => (path1 && propertyFinancing === 'firstSecondAndThirdMortgage') && thirdMortgageType === 'heloc',
            //   then: Yup.string().required(' ')
            // }),


            // Required for Property Usage Path
            // Custom test for `pathRef` due to default `.when()` testing against a stale state
            propertyUsageType: Yup.string().nullable().test(value => pathRef.current === 'propertyUsage' ? value !== null : true),
            personalTaxFiled: Yup.string().nullable().when('propertyUsageType', {
              is: 'investment',
              then: Yup.string().required(' ')
            }),
            propertyOnCurrentTaxReturn: Yup.string().nullable().when(['propertyUsageType', 'personalTaxFiled'], {
              is: (
                propertyUsageType: string,
                personalTaxFiled: string
              ) =>
                propertyUsageType === 'investment'
                && personalTaxFiled === lastYear.toString(),
              then: Yup.string().required(' ')
            }),
            propertyOnPriorTaxReturn: Yup.string().nullable().when(['propertyUsageType', 'personalTaxFiled'], {
              is: (
                propertyUsageType: string,
                personalTaxFiled: string
              ) => propertyUsageType === 'investment'
                && personalTaxFiled === twoYearsAgo.toString(),
              then: Yup.string().required(' ')
            })
          })}
          onSubmit={(values, {
            setErrors,
            setStatus,
            setSubmitting,
            resetForm
          }) => {
            try {
              const changedBorrowerData = {
                tax_filed_year: values.personalTaxFiled
              }

              const changedREOData = {
                id: formData?.id || null,
                owner: values.owner,
                address_street_line: values.street,
                address_unit: values.unit,
                address_city: values.city,
                address_state: values.state,
                address_postal: values.postal,
                address_county: values.county,

                property_plans: values.propertyPlans,
                is_rent: values.isRent,
                sell_time_line: values.sellTimeline?.replace(/[$,]/g, '') || '',
                sell_net_value_amount: values.sellNet?.replace(/[$,]/g, '') || '',
                rental_income_gross_amount: values.rentalIncomeGrossAmount?.replace(/[$,]/g, '') || '',

                market_value_amount: values.marketValueAmount?.replace(/[$,]/g, '') || '',
                gse_property_type: values.propertyType,
                has_hoa: values.hasHOA,
                property_usage_type: values.propertyUsageType,
                hoa_dues_amount: values.hoaDues?.replace(/[$,]/g, '') || '',
                property_status: values.propertyStatus,
                tax_return_year: values.personalTaxFiled,
                property_on_current_tax_return_year: values.propertyOnCurrentTaxReturn,
                property_on_prior_tax_return_year: values.propertyOnPriorTaxReturn,
                taxes_amount: values.taxes?.replace(/[$,]/g, '') || '',

                property_financing: values.propertyFinancing,
                first_mortgage_loan_amount: values.firstMortgageLoanAmount?.replace(/[$,]/g, '') || '',
                first_mortgage_interest_rate: values.firstMortgageInterestRate?.replace(/[%]/g, '') || '',
                first_mortgage_payment: values.firstMortgagePayment?.replace(/[$,]/g, '') || '',
                first_mortgage_impounded: values.firstMortgageImpounded,
                second_mortgage_type: values.secondMortgageType,
                second_mortgage_loan_amount: values.secondMortgageLoanAmount?.replace(/[$,]/g, '') || '',
                second_mortgage_interest_rate: values.secondMortgageInterestRate?.replace(/[%]/g, '') || '',
                second_mortgage_payment: values.secondMortgagePayment?.replace(/[$,]/g, '') || '',
                second_mortgage_impounded: values.secondMortgageImpounded,
                second_mortgage_balance: values.secondMortgageBalance?.replace(/[$,]/g, '') || '',
                second_mortgage_credit_limit: values.secondMortgageCreditLimit?.replace(/[$,]/g, '') || '',
                third_mortgage_type: values.thirdMortgageType,
                third_mortgage_loan_amount: values.thirdMortgageLoanAmount?.replace(/[$,]/g, '') || '',
                third_mortgage_interest_rate: values.thirdMortgageInterestRate?.replace(/[%]/g, '') || '',
                third_mortgage_payment: values.thirdMortgagePayment?.replace(/[$,]/g, '') || '',
                third_mortgage_impounded: values.thirdMortgageImpounded,
                third_mortgage_balance: values.thirdMortgageBalance?.replace(/[$,]/g, '') || '',
                third_mortgage_credit_limit: values.thirdMortgageCreditLimit?.replace(/[$,]/g, '') || '',

                annual_property_tax: values.annualPropertyTax?.replace(/[$,]/g, '') || '',
                insurance_amount: values.annualInsurancePremium?.replace(/[$,]/g, '') || '',
              }

              if (conciergeMode) {
                loanData.reo = [];
                const dataSubmit = _.merge(loanData, {
                  borrower: changedBorrowerData,
                  reo: [changedREOData]
                });
                loansService.updateLoan(activeLoan, dataSubmit).then((res: any) => {
                  if (res.success) {
                    incrementREOIndex();
                    setStatus({ success: true });
                    setSubmitting(false);
                    resetForm({});
                  }
                  else {
                    setStatus({ success: false });
                    setErrors({
                      submit: res.errors?.base[0] === 'Address street line has already been taken'
                        ? `${values.street}${values.unit ? `${values.unit} ` : ''} has already been added.`
                        : res.errors?.base[0] || res.error
                    });
                    setSubmitting(false);
                  }
                })
              }
              else {
                let skipUniquenessCheck = false

                if (formData.address_street_line) {
                  skipUniquenessCheck = valuesAreIdentical(
                    // TODO: Add values below once implemented in hive
                    [formData.address_street_line],
                    [values.street]
                  )
                }

                if (skipUniquenessCheck || (!skipUniquenessCheck && valuesAreUnique(
                  [
                    values.street,
                    // TODO: Uncomment once hive uniqueness constraints is checking for entire address field
                    // values.unit,
                    // values.city,
                    // values.state,
                    // values.postal
                  ],
                  loanData,
                  'reo',
                  [
                    'address_street_line',
                    // TODO: Uncomment once hive uniqueness constraints is checking for entire address field
                    // 'address_unit',
                    // 'address_city',
                    // 'address_state',
                    // 'address_postal'
                  ],
                  tableData
                ))) {
                  saveForm(changedREOData, changedBorrowerData)
                  setStatus({ success: true });
                  setSubmitting(false);
                  resetForm({});
                  setShowForm(false)
                }
                else {
                  setStatus({ success: false });
                  setErrors({ submit: `${values.street}${values.unit ? `${values.unit} ` : ''} has already been added.` });
                  setSubmitting(false);
                }
              }
            } catch (err: any) {
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            touched,
            values
          }) => (
            <form
              ref={formRef}
              onSubmit={handleSubmit}
              className="application-form form-view"
            >
              <div className="form-step">
                <h1 className="title text-light-blue has-subtitle">
                  {path === 'propertyUsage' ? 'Owned Real Estate' : 'Current Residence Information'}
                </h1>
                <h3 className="subtitle text-grey">
                  {path === 'propertyUsage'
                    ? 'We\'ll need some information about this property.'
                    : 'Let\'s talk details about your current primary residence.'
                  }
                </h3>

                <AddressInput
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  touched={touched}
                  errors={errors}
                  showErrorContainer={showErrorContainer}
                  setFieldValue={setFieldValue}
                  values={values}
                />

                {path === 'purchasePrimary' && (
                  <PurchasePrimaryPath
                    values={values}
                    errors={errors}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    touched={touched}
                    showErrorContainer={showErrorContainer}
                  />
                )}

                <Form.Field>
                  <FormikMaskedInput
                    className={`small ${!!(touched.marketValueAmount && errors.marketValueAmount) ? 'error' : ''}`}
                    label={`How much do you anticipate ${values.street !== '' ? values.street : 'this address'} is worth?`}
                    name="marketValueAmount"
                    placeholder="Market Value"
                    type="text"
                    value={values.marketValueAmount}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    mask={currencyMask}
                    error={
                      !!(touched.marketValueAmount && errors.marketValueAmount)
                      || (showErrorContainer && values.marketValueAmount === '')
                    }
                  />
                </Form.Field>
                <Form.Field>
                  <label>What type of property is {values.street !== '' ? values.street : 'this address'}?</label>
                  <FormikDropdown
                    className={`small ${!!(touched.propertyType && errors.propertyType) ? 'error' : ''}`}
                    name="propertyType"
                    placeholder="Property Type"
                    options={propertyTypeOptions}
                    value={values.propertyType}
                    error={
                      !!(touched.propertyType && errors.propertyType)
                      || (showErrorContainer && values.propertyType === '')
                    }
                    onFocus={() => clearValues(
                      values,
                      [
                        'hasHOA',
                        'hoaDues'
                      ]
                    )}
                  />
                </Form.Field>
                {values.propertyType === 'sfr' && (
                  <Form.Field>
                    <label>Got it. Is this residence part of an HOA?</label>
                    <div className={`radio-group ${showErrorContainer && values.hasHOA === '' && 'radio-error'}`}>
                      <Radio
                        id="hoaTrue"
                        label="Yes"
                        name="hasHOA"
                        value="true"
                        checked={values.hasHOA === "true"}
                        className={errors.hasHOA && 'has-error'}
                        onChange={(e) => handleChange(e)}
                      />
                      <Radio
                        id="hoaFalse"
                        label="No"
                        name="hasHOA"
                        value="false"
                        checked={values.hasHOA === "false"}
                        onFocus={() => clearValues(values, ['hoaDues'])}
                        className={errors.hasHOA && 'has-error'}
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                  </Form.Field>
                )}
                {(values.hasHOA === 'true' || values.propertyType === 'condo') && (
                  <Form.Field className="nested">
                    <FormikMaskedInput
                      className={`small ${!!(touched.hoaDues && errors.hoaDues) ? 'error' : ''}`}
                      label="How much are the monthly HOA dues?"
                      name="hoaDues"
                      placeholder="Monthly HOA Amount"
                      type="text"
                      value={values.hoaDues}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      mask={currencyMask}
                      error={
                        !!(touched.hoaDues && errors.hoaDues)
                        || (showErrorContainer && !values.hoaDues)
                      }
                    />
                  </Form.Field>
                )}

                {path === 'propertyUsage' && (
                  <PropertyUsagePath
                    values={values}
                    errors={errors}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    touched={touched}
                    showErrorContainer={showErrorContainer}
                    hasPrimaryResidence={hasPrimaryResidence}
                  />
                )}

                <Form.Field>
                  <label>What financing do you currently have on {values.street !== '' ? values.street : 'this address'}?</label>
                  <FinancingDropdown
                    className="large"
                    name="propertyFinancing"
                    placeholder="Property Financing"
                    options={financingOptions}
                    value={values.propertyFinancing}
                    values={values}
                    error={
                      !!(touched.propertyFinancing && errors.propertyFinancing)
                      || (showErrorContainer && values.propertyFinancing === '')
                    }
                  />
                </Form.Field>
                {values.propertyFinancing !== '' && values.propertyFinancing !== 'freeAndClear' && (
                  <>
                    <Form.Field>
                      <label><span className="text-blue">Great, let's gather some details about your existing first mortgage.</span></label>
                    </Form.Field>
                    <div className="mortgage-payment">
                      <Form.Field className="nested">
                        <div className="label-popup-container">
                          <label>Loan Amount</label>
                          <HelperPopup
                            headerLabel="Loan Amount Description"
                            content={
                              <span>
                                Loan Amount - Represents the total original
                                loan balance. For example you bought your
                                house for $100,000 & put down $20,000. The
                                loan amount you would enter here would be
                                $80,000
                              </span>
                            }
                          />
                        </div>
                        <FormikMaskedInput
                          className={`small ${!!(touched.firstMortgageLoanAmount && errors.firstMortgageLoanAmount) ? 'error' : ''}`}
                          name="firstMortgageLoanAmount"
                          placeholder="Loan Amount"
                          type="text"
                          value={values.firstMortgageLoanAmount}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          mask={currencyMask}
                          error={
                            !!(touched.firstMortgageLoanAmount && errors.firstMortgageLoanAmount)
                            || (showErrorContainer && values.firstMortgageLoanAmount === '')
                          }
                        />
                      </Form.Field>
                      <Form.Field className="nested">
                        <div className="label-popup-container">
                          <label>Payment</label>
                          <HelperPopup
                            headerLabel="Payment Description"
                            content={
                              <span>
                                Payment - Represents the total monthly mortgage payment made each month.
                                This value includes the taxes/insurance/principal/interest payments
                              </span>
                            }
                          />
                        </div>
                        <FormikMaskedInput
                          className={`small ${!!(touched.firstMortgagePayment && errors.firstMortgagePayment) ? 'error' : ''}`}
                          name="firstMortgagePayment"
                          placeholder="Mortgage Payment"
                          type="text"
                          value={values.firstMortgagePayment}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          mask={currencyMask}
                          error={
                            !!(touched.firstMortgagePayment && errors.firstMortgagePayment)
                            || (showErrorContainer && values.firstMortgagePayment === '')
                          }
                        />
                      </Form.Field>
                    </div>
                    <Form.Field className="nested">
                      <label>Got it. Is the mortgage impounded for anything?</label>
                      <FormikDropdown
                        className="small"
                        name="firstMortgageImpounded"
                        placeholder="Mortgage Impounded"
                        options={impoundOptions}
                        value={values.firstMortgageImpounded}
                        error={
                          !!(touched.firstMortgageImpounded && errors.firstMortgageImpounded)
                          || (showErrorContainer && values.firstMortgageImpounded === '')
                        }
                      />
                    </Form.Field>
                  </>
                )}
                {(values.propertyFinancing === 'firstAndSecondMortgage' || values.propertyFinancing === 'firstSecondAndThirdMortgage') && (
                  <>
                    <Form.Field>
                      <label><span className="text-blue">Great, let's gather some details about your existing second mortgage.</span></label>
                    </Form.Field>
                    <Form.Field>
                      <label>Is it a fixed rate second or a home equity line of credit?</label>
                      <div className={`radio-group ${showErrorContainer && values.secondMortgageType === '' && 'radio-error'}`}>
                        <Radio
                          id="secondMortgageTypeFixed"
                          label="Fixed"
                          name="secondMortgageType"
                          value='fixed'
                          checked={values.secondMortgageType === 'fixed'}
                          className={errors.secondMortgageType && 'has-error'}
                          onChange={(e) => handleChange(e)}
                        />
                        <Radio
                          id="secondMortgageTypeHeloc"
                          label="Home Equity Line of Credit"
                          name="secondMortgageType"
                          value='heloc'
                          checked={values.secondMortgageType === 'heloc'}
                          className={errors.secondMortgageType && 'has-error'}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                    </Form.Field>
                    {values.secondMortgageType !== '' && (
                      <>
                        <div className="mortgage-payment">
                          <Form.Field className="nested">
                            <FormikMaskedInput
                              className={`small ${!!(touched.secondMortgageLoanAmount && errors.secondMortgageLoanAmount) ? 'error' : ''}`}
                              label={values.secondMortgageType == 'heloc' ? "Credit Amount" : "Loan Amount"}
                              name="secondMortgageLoanAmount"
                              placeholder={values.secondMortgageType == 'heloc' ? "Credit Amount" : "Loan Amount"}
                              type="text"
                              value={values.secondMortgageLoanAmount}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              mask={currencyMask}
                              error={
                                !!(touched.secondMortgageLoanAmount && errors.secondMortgageLoanAmount)
                                || (showErrorContainer && values.secondMortgageLoanAmount === '')
                              }
                            />
                          </Form.Field>
                          <Form.Field className="nested">
                            <FormikMaskedInput
                              className={`small ${!!(touched.secondMortgagePayment && errors.secondMortgagePayment) ? 'error' : ''}`}
                              label="Payment"
                              name="secondMortgagePayment"
                              placeholder="Mortgage Payment"
                              type="text"
                              value={values.secondMortgagePayment}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              mask={currencyMask}
                              error={
                                !!(touched.secondMortgagePayment && errors.secondMortgagePayment)
                                || (showErrorContainer && values.secondMortgagePayment === '')
                              }
                            />
                          </Form.Field>
                          <Form.Field className="nested">
                            <FormikMaskedInput
                              className={`small ${!!(touched.secondMortgageBalance && errors.secondMortgageBalance) ? 'error' : ''}`}
                              label={values.secondMortgageType == 'heloc' ? "Credit Balance" : "Remaining Balance"}
                              name="secondMortgageBalance"
                              placeholder={values.secondMortgageType == 'heloc' ? "Credit Balance" : "Remaining Balance"}
                              type="text"
                              value={values.secondMortgageBalance}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              mask={currencyMask}
                              error={
                                !!(touched.secondMortgageBalance && errors.secondMortgageBalance)
                                || (showErrorContainer && values.secondMortgageBalance === '')
                              }
                            />
                          </Form.Field>
                        </div>
                      </>
                    )}
                  </>
                )}
                {values.propertyFinancing === 'firstSecondAndThirdMortgage' && (
                  <>
                    <Form.Field>
                      <label><span className="text-blue">Great, let's gather some details about your existing third mortgage.</span></label>
                    </Form.Field>
                    <Form.Field>
                      <label>Is it a fixed rate third or a home equity line of credit?</label>
                      <div className={`radio-group ${showErrorContainer && values.thirdMortgageType === '' && 'radio-error'}`}>
                        <Radio
                          id="thirdMortgageTypeFixed"
                          label="Fixed"
                          name="thirdMortgageType"
                          value='fixed'
                          checked={values.thirdMortgageType === 'fixed'}
                          className={(errors.thirdMortgageType) && 'has-error'}
                          onChange={(e) => handleChange(e)}
                        />
                        <Radio
                          id="thirdMortgageTypeHeloc"
                          label="Home Equity Line of Credit"
                          name="thirdMortgageType"
                          value='heloc'
                          checked={values.thirdMortgageType === 'heloc'}
                          className={(errors.thirdMortgageType) && 'has-error'}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                    </Form.Field>
                    {values.thirdMortgageType !== '' && (
                      <>
                        <div className="mortgage-payment">
                          <Form.Field className="nested">
                            <FormikMaskedInput
                              className={`small ${!!(touched.thirdMortgageLoanAmount && errors.thirdMortgageLoanAmount) ? 'error' : ''}`}
                              label={values.thirdMortgageType == 'heloc' ? "Credit Amount" : "Loan Amount"}
                              name="thirdMortgageLoanAmount"
                              placeholder={values.thirdMortgageType == 'heloc' ? "Credit Amount" : "Loan Amount"}
                              type="text"
                              value={values.thirdMortgageLoanAmount}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              mask={currencyMask}
                              error={
                                !!(touched.thirdMortgageLoanAmount && errors.thirdMortgageLoanAmount)
                                || (showErrorContainer && values.thirdMortgageLoanAmount === '')
                              }
                            />
                          </Form.Field>
                          <Form.Field className="nested">
                            <FormikMaskedInput
                              className={`small ${!!(touched.thirdMortgagePayment && errors.thirdMortgagePayment) ? 'error' : ''}`}
                              label="Payment"
                              name="thirdMortgagePayment"
                              placeholder="Mortgage Payment"
                              type="text"
                              value={values.thirdMortgagePayment}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              mask={currencyMask}
                              error={
                                !!(touched.thirdMortgagePayment && errors.thirdMortgagePayment)
                                || (showErrorContainer && values.thirdMortgagePayment === '')
                              }
                            />
                          </Form.Field>
                          <Form.Field className="nested">
                            <FormikMaskedInput
                              className={`small ${!!(touched.thirdMortgageBalance && errors.thirdMortgageBalance) ? 'error' : ''}`}
                              label={values.thirdMortgageType == 'heloc' ? "Credit Balance" : "Remaining Balance"}
                              name="thirdMortgageBalance"
                              placeholder={values.thirdMortgageType == 'heloc' ? "Credit Balance" : "Remaining Balance"}
                              type="text"
                              value={values.thirdMortgageBalance}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              mask={currencyMask}
                              error={
                                !!(touched.thirdMortgageBalance && errors.thirdMortgageBalance)
                                || (showErrorContainer && values.thirdMortgageBalance === '')
                              }
                            />
                          </Form.Field>
                        </div>
                      </>
                    )}
                  </>
                )}
                <Form.Field>
                  <FormikMaskedInput
                    className={`small ${!!(touched.annualPropertyTax && errors.annualPropertyTax) ? 'error' : ''}`}
                    label="Approximately how much are annual property taxes?"
                    name="annualPropertyTax"
                    placeholder="Annual Property Taxes"
                    type="text"
                    value={values.annualPropertyTax}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    mask={currencyMask}
                    error={
                      !!(touched.annualPropertyTax && errors.annualPropertyTax)
                      || (showErrorContainer && values.annualPropertyTax === '')
                    }
                  />
                </Form.Field>
                <Form.Field>
                  <FormikMaskedInput
                    className={`small ${!!(touched.annualInsurancePremium && errors.annualInsurancePremium) ? 'error' : ''}`}
                    label="What's the approximate annual insurance premium?"
                    name="annualInsurancePremium"
                    placeholder="Insurance Premium"
                    type="text"
                    value={values.annualInsurancePremium}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    mask={currencyMask}
                    error={
                      !!(touched.annualInsurancePremium && errors.annualInsurancePremium)
                      || (showErrorContainer && values.annualInsurancePremium === '')
                    }
                  />
                </Form.Field>
              </div>
              <div className="application-step-footer">
                <Button
                  type="button"
                  onClick={() => handleFormCancelClick()}
                  className="form-previous-button"
                >
                  Cancel
                </Button>
                <ErrorContainer
                  errors={errors}
                  showErrorContainer={showErrorContainer}
                  setDisableOnErrors={setDisableOnErrors}
                  errorMessage={errors.submit ? errors.submit : undefined}
                />
                <Button
                  disabled={isSubmitting || disableOnErrors}
                  color="blue"
                  type="submit"
                  className="save form-save-button"
                  onClick={() => setShowErrorContainer(true)}
                >
                  Save
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </>
  )
})

export default REOPathForm;

