export type AssetWorksheetType =
  'Checking' |
  'Savings' |
  'Stocks/Bonds' |
  'Retirement' |
  'Cryptocurrency' |
  'Gifts' |
  'Other' |
  ''

export type HiveAssetWorksheetType =
  'checking_account' |
  'savings_account' |
  'stock' |
  'retirement_funds' |
  'cryptocurrency' |
  'gifts' |
  'other' |
  ''

export type RetirementType =
  '401(k)' |
  'IRA' |
  '403(b)' |
  '457(b)PERS' |
  'Other' |
  ''

export type HiveRetirementType =
  'retirement_401k' |
  'ira' |
  'retirement_403b' |
  'retirement_457b' |
  'retirement_other' |
  ''

export enum AssetWorksheetTypeEnum {
  'checking_account' = 'Checking Account',
  'savings_account' = 'Savings Account',
  'stock' = 'Stock',
  'retirement_funds' = 'Retirement',
  'retirement_401k' = '401(k)',
  'ira' = 'IRA',
  'retirement_403b' = '403(b)',
  'retirement_457b' = '457(b)PERS',
  'retirement_other' = 'Retirement Other',
  'cryptocurrency' = 'Cryptocurrency',
  'gifts' = 'Gifts',
  'other' = 'Other'
}

export type StatementFrequencyType =
  'Monthly' |
  'Every Two Months' |
  'Quarterly' |
  'Annually' |
  ''

export type HiveStatementFrequencyType =
  'monthly' |
  'every_two_months' |
  'quarterly' |
  'annually' |
  ''
