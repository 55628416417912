export const incomeSourceOptions = [
  {
    text: 'W-2 Wage Earner',
    value: 'W-2 Wage Earner',
  },
  {
    text: 'Self Employed',
    value: 'Self Employed',
  },
  {
    text: 'Social Security',
    value: 'Social Security'
  },
  {
    text: 'Pension',
    value: 'Pension'
  },
  {
    text: 'Annuity',
    value: 'Annuity'
  },
  {
    text: '401k',
    value: '401k'
  },
  {
    text: 'Alimony Income',
    value: 'Alimony',
  },
  {
    text: 'Child Support Income',
    value: 'Child Support',
  },
  {
    text: 'Other Income',
    value: 'Other',
  }
]

export const incomeTypeOptions = [
  {
    text: 'W-2 Wage Earner',
    value: 'W-2 Wage Earner',
  },
  {
    text: 'Self Employed',
    value: 'Self Employed',
  },
  {
    text: 'Retirement',
    value: 'retirement_account'
  },
  {
    text: 'Alimony Income',
    value: 'alimony',
  },
  {
    text: 'Child Support Income',
    value: 'child_support',
  },
  {
    text: 'Other Income',
    value: 'other',
  }
]


export const personalTaxFiledOptions = [
  {
    text: '2018',
    value: 2018
  },
  {
    text: '2019',
    value: 2019
  },
  {
    text: '2020',
    value: 2020
  }
]

export const entityTypeOptions = [
  {
    text: 'Sole Proprietorship',
    value: 'soleProprietor'
  },
  {
    text: 'Partnership',
    value: 'partnership'
  },
  {
    text: 'LLC',
    value: 'llc'
  },
  {
    text: 'S-Corp',
    value: 's-corp'
  },
  {
    text: 'C-Corp',
    value: 'c-corp'
  }
]

export const ownershipPercentOptions = [
  {
    text: '100% ownership',
    value: '100'
  },
  {
    text: '25% or greater but less than 100%',
    value: 'greater than 25'
  },
  {
    text: 'Less than 25%',
    value: 'less than 25'
  }
]

export const salaryFrequencyOptions = [
  {
    text: 'Weekly',
    value: 'weekly'
  },
  {
    text: 'Bi-Weekly',
    value: 'bi-weekly'
  },
  {
    text: 'Semi-Monthly',
    value: 'semi-monthly'
  },
  {
    text: 'Monthly',
    value: 'monthly'
  },
  {
    text: 'Quarterly',
    value: 'quarterly'
  },
]

export const ownershipYearOptions = [
  {
    text: '0',
    value: 0
  },
  {
    text: '1',
    value: 1
  },
  {
    text: '2',
    value: 2
  },
  {
    text: '3',
    value: 3
  },
  {
    text: '4',
    value: 4
  },
  {
    text: '5',
    value: 5
  },
  {
    text: '6',
    value: 6
  },
  {
    text: '7',
    value: 7
  },
  {
    text: '8',
    value: 8
  },
  {
    text: '9',
    value: 9
  },
  {
    text: '10+',
    value: 10
  },
]

export const taxFilingMethodOptions = [
  {
    text: 'File Separate',
    value: 'separate'
  },
  {
    text: 'Personal Tax Return',
    value: 'personal'
  }
]
