import { observer } from 'mobx-react-lite';
import React, { FC, useContext, useEffect, useState } from 'react';
import LoaderOverlay from '../../../components/LoaderOverlay/LoaderOverlay';
import { DataContext } from '../../../context/dataContext';
import { useStore } from '@jmjfinancial/apis/lib';
import EditREOForm from './EditREOForm';
import REOConcierge from './REOConcierge';
import RealEstateOwnedTableView from './TableView';

const RealEstateOwned: FC = observer(() => {
  const store = useStore();
  const { loansService } = store;
  const { activeLoan } = useContext(DataContext);

  const [isLoading, setIsLoading] = useState(true);
  const [loanData, setLoanData] = useState<any>();
  const [conciergeMode, setConciergeMode] = useState(true);
  const [formData, setFormData] = useState({});
  const [refreshREOView, setRefreshREOView] = useState(false);
  const [showREOForm, setShowREOForm] = useState(false);
  const [reoIndex, setReoIndex] = useState<number>(0);
  const [incompleteREOs, setIncompleteREOs] = useState<Array<any>>([]);

  const RealEstateOwnedView: FC = () => {
    if (loanData.reo.length > 0 && !conciergeMode) {
      return (
        <div className="application-step-container real-estate-owned-container">
          <RealEstateOwnedTableView
            loanData={loanData}
            reoIndex={reoIndex}
            setReoIndex={setReoIndex}
            incompleteREOs={incompleteREOs}
            setIncompleteREOs={setIncompleteREOs}
            setRefreshREOView={setRefreshREOView}
            conciergeMode={conciergeMode}
            setFormData={setFormData}
          />
        </div>
      )
    }

    else if (showREOForm) {
      return (
        <div className="application-step-container real-estate-owned-container">
          <EditREOForm
            loanData={loanData}
            setRefreshREOView={setRefreshREOView}
            incompleteREOs={incompleteREOs}
            setIncompleteREOs={setIncompleteREOs}
            reoIndex={reoIndex}
            setReoIndex={setReoIndex}
            conciergeMode={conciergeMode}
            setShowForm={() => { }}
            saveForm={() => { }}
            formData={formData}
            setFormData={setFormData}
          />
        </div>
      )
    }

    else {
      return (
        <REOConcierge
          loanData={loanData}
          showREOForm={setShowREOForm}
        />
      )
    }
  };

  useEffect(() => {
    setIsLoading(true);
    loansService.getLoan(activeLoan).then(l => {
      const loan = l.data.form_data;
      setLoanData(loan);

      setRefreshREOView(false);

      // If there are no REOs, start concierge mode
      setConciergeMode(conciergeMode => loan.reo?.length === 0)

      if (loan.reo?.length > 0) {
        // Are there any REOs missing `market_value_amount`?
        const filteredIncompleteREOs = loan.reo?.filter((value: any) => !value.market_value_amount)
        const hasIncompleteREOs = filteredIncompleteREOs.length > 0

        setConciergeMode(conciergeMode => hasIncompleteREOs)

        if (hasIncompleteREOs) {
          // Set form to first incomplete REO
          setFormData(formData => filteredIncompleteREOs[reoIndex])
          // Show form if REO data is missing
          setShowREOForm(showOwnedResidenceForm => true)

          if (incompleteREOs.length === 0) {
            // Update to set of `incompleteREOs`
            setIncompleteREOs(incompleteREOs => filteredIncompleteREOs);
          }
        }
        else {
          // If there are no incomplete REOs, then show table
          setShowREOForm(showOwnedResidenceForm => false)
        }
      }

      setIsLoading(false);
    })
  }, [activeLoan, refreshREOView])

  return (
    <>
      <LoaderOverlay active={isLoading} />
      {!isLoading && <RealEstateOwnedView />}
    </>
  )
})

export default RealEstateOwned;
