import {
  AssetWorksheetType,
  HiveAssetWorksheetType, HiveRetirementType, HiveStatementFrequencyType, RetirementType,
  StatementFrequencyType
} from './asset-worksheet-types';

export interface AssetWorksheetOption {
  text: AssetWorksheetType
  value: HiveAssetWorksheetType
}

export interface RetirementOption {
  text: RetirementType
  value: HiveRetirementType
}

export interface StatementFrequencyOption {
  text: StatementFrequencyType
  value: HiveStatementFrequencyType
}

export interface OwnerOption {
  text: string
  value: string
  borrowerid: number // Lowercase to prevent error in DOM with prop name
  employmentid?: number // Lowercase to prevent error in DOM with prop name
}

export const assetWorksheetOptions: AssetWorksheetOption[] = [
  {
    text: 'Checking',
    value: 'checking_account'
  },
  {
    text: 'Savings',
    value: 'savings_account'
  },
  {
    text: 'Stocks/Bonds',
    value: 'stock'
  },
  {
    text: 'Retirement',
    value: 'retirement_funds'
  },
  {
    text: 'Cryptocurrency',
    value: 'cryptocurrency'
  },
  {
    text: 'Other',
    value: 'other'
  }
]

export const retirementOptions: RetirementOption[] =[
  {
    text: '401(k)',
    value: 'retirement_401k'
  },
  {
    text: 'IRA',
    value: 'ira'
  },
  {
    text: '403(b)',
    value: 'retirement_403b'
  },
  {
    text: '457(b)PERS',
    value: 'retirement_457b'
  },
  {
    text: 'Other',
    value: 'retirement_other'
  }
]

export const statementFrequencyOptions: StatementFrequencyOption[] = [
  {
    text: 'Monthly',
    value: 'monthly'
  },
  {
    text: 'Every Two Months',
    value: 'every_two_months'
  },
  {
    text: 'Quarterly',
    value: 'quarterly'
  },
  {
    text: 'Annually',
    value: 'annually'
  }
]
