export const militaryOptions = [
  {
    text: 'Currently serving on active duty with projected expiration date of service/tour',
    value: 'expiration',
    key: 'expiration'
  },
  {
    text: 'Currently retired, discharged, or separated from service',
    value: 'retired/discharged/separated',
    key: 'retired'
  },
  {
    text: 'Only period of service was as a non-activated member of the Reserve or National Guard',
    value: 'non-activated',
    key: 'non-activated'
  },
  {
    text: 'Surviving spouse',
    value: 'survivingSpouse',
    key: 'survivingSpouse'
  }
]

export const ethnicityOptions = [
  {
    text: 'Hispanic or Latino',
    value: 'hispanic',
    key: 'hmda_ethnicity_hispanic_latino_indicator'
  },
  {
    text: 'Not Hispanic or Latino',
    value: 'notHispanic',
    key: 'hmda_ethnicity_not_hispanic_latino_indicator'
  },
  {
    text: 'I do not wish to provide this information',
    value: 'defer',
    key: 'hmda_ethnicity_do_not_wish_indicator'
  }
]

export const genderOptions = [
  {
    text: 'Female',
    value: 'female',
    key: 'hmda_gendertype_female_indicator'
  },
  {
    text: 'Male',
    value: 'male',
    key: 'hmda_gendertype_male_indicator'
  },
  {
    text: 'I do not wish to provide this information',
    value: 'defer',
    key: 'hmda_gendertype_do_not_wish_indicator'
  },
  {
    text: 'Not applicable',
    value: 'not applicable',
    key: 'hmda_gendertype_not_applicable_indicator'
  }
]

export const raceOptions = [
  {
    text: 'American Indian or Alaska Native',
    value: 'native',
    key: 'hmda_american_indian_indicator'
  },
  {
    text: 'Asian',
    value: 'asian',
    key: 'hmda_asian_indicator'
  },
  {
    text: 'Black or African American',
    value: 'black',
    key: 'hmda_african_american_indicator'
  },
  {
    text: 'Native Hawaiian or Other Pacific Islander',
    value: 'pacificIslander',
    key: 'hmda_pacific_islander_indicator'
  },
  {
    text: 'White',
    value: 'white',
    key: 'hmda_white_indicator'
  },
  {
    text: 'I do not wish to provide this information',
    value: 'defer',
    key: 'hmda_race_do_not_wish_provide_indicator'
  },
  {
    text: 'Not Applicable',
    value: 'notApplicable',
    key: 'hmda_not_applicable_indicator'
  }
]

export const ethnicityHispanicOptions = [
  {
    text: 'Mexican',
    value: 'mexican',
    key: 'hmda_mexican_indicator',
  },
  {
    text: 'Puerto Rican',
    value: 'puerto rican',
    key: 'hmda_puerto_rican_indicator',
  },
  {
    text: 'Cuban',
    value: 'cuban',
    key: 'hmda_cuban_indicator',
  },
  {
    text: 'Other',
    value: 'other',
    key: 'hmda_hispanic_latino_other_origin_indicator',
  },
]

export const raceAsianOptions = [
  {
    text: 'Asian Indian',
    value: 'asian indian',
    key: 'hmda_asian_indian_indicator'
  },
  {
    text: 'Chinese',
    value: 'chinese',
    key: 'hmda_chinese_indicator'
  },
  {
    text: 'Filipino',
    value: 'filipino',
    key: 'hmda_filipino_indicator'
  },
  {
    text: 'Japanese',
    value: 'japanese',
    key: 'hmda_japanese_indicator'
  },
  {
    text: 'Korean',
    value: 'korean',
    key: 'hmda_korean_indicator'
  },
  {
    text: 'Vietnamese',
    value: 'vietnamese',
    key: 'hmda_vietnamese_indicator'
  },
  {
    text: 'Other',
    value: 'other',
    key: 'hmda_asian_other_race_indicator'
  }
]

export const racePacificIslanderOptions = [
  {
    text: 'Native Hawaiian',
    value: 'native hawaiian',
    key: 'hmda_native_hawaiian_indicator'
  },
  {
    text: 'Guamanian or Chamorro',
    value: 'guamanian or chamorro',
    key: 'hmda_guamanian_or_chamorro_indicator'
  },
  {
    text: 'Samoan',
    value: 'samoan',
    key: 'hmda_samoan_indicator'
  },
  {
    text: 'Other',
    value: 'other',
    key: 'hmda_other_pacific_islander_race'
  }
]
