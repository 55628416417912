export const propertyTypeOptions = [
    {
      text: 'Primary Residence (PR)',
      value: 'primaryResidence'
    },
    {
      text: 'FHA secondary residence (SR)',
      value: 'secondaryResidence'
    },
    {
      text: 'Investment Property (IP)',
      value: 'investment'
    }
  ]

  export const bankruptcyTypesOptions = [
    'Chapter 7',
    'Chapter 11',
    'Chapter 12',
    'Chapter 13'
  ]
