import React, { FC, useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import { Button, Form, Popup } from 'semantic-ui-react';
import * as Yup from 'yup';
import _ from 'lodash';
import '../../../components/PageHeader/index.scss';
import './section0.scss';
import { observer } from 'mobx-react-lite';
import { DataContext } from '../../../context/dataContext';
import { useStore } from '@jmjfinancial/apis/lib';
import { ApplicationRoutes } from '..';
import AddressInput from '../../../components/AddressInput/AddressInput';
import ErrorContainer from '../../../components/ErrorContainer';
import LoaderOverlay from '../../../components/LoaderOverlay/LoaderOverlay';
import {scrollToDefaultOptions} from '../../../helpers/scroll-to-options';
import { currencyMask } from '../../../helpers/currency-mask-input';
import FormikMaskedInput from '../../../components/Formik/FormikMaskedInput';
import HelperPopup from '../../../components/Popup/Popup';

const usageOptions = [
  {
    text: 'Primary residence',
    value: 'primary',
    key: 'usage'
  },
  {
    text: 'Second home',
    value: 'secondary',
    key: 'usage'
  },
  {
    text: 'Investment',
    value: 'investment',
    key: 'usage'
  }
]

const refiGoalOptions = [
  {
    text: 'Lower rate',
    value: 'lower-rate',
    key: 'refiGoal'
  },
  {
    text: 'Lower term',
    value: 'lower-term',
    key: 'refiGoal'
  },
  {
    text: 'Debt Consolidation',
    value: 'debt-consolidate',
    key: 'refiGoal',
    tooltipContent: 'Consolidation means that your various debts, whether they are credit card bills or loan payments, are rolled into one monthly payment. If you have multiple credit card accounts or loans, consolidation may be a way to simplify or lower payments'
  },
  {
    text: 'Consolidate mortgages',
    value: 'consolidate',
    key: 'refiGoal',
    tooltipContent: 'A mortgage that one takes out in order to pay off two or more mortgages. The mortgages may or may not be for the same piece of property. A mortgage holder may take out a consolidated mortgage to lower monthly payments or for some other reason.'
  },
  {
    text: 'Take cash out',
    value: 'cash-out',
    key: 'refiGoal'
  }
]

const Step2Refinance: FC = observer(() => {
  const store = useStore();
  const { loansService } = store;
  const {
    activeLoan,
    pathname,
    setDarkThemeOverride,
    setOverrideTrackerVisibility
  } = useContext(DataContext);

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [loanData, setLoanData] = useState<any>();
  const [usageSelected, setUsageSelected] = useState<number>();
  const [refiGoalSelected, setRefiGoalSelected] = useState<number>();
  const [disableOnErrors, setDisableOnErrors] = useState<boolean>(false);
  const [showErrorContainer, setShowErrorContainer] = useState<boolean>(false);

  const history = useHistory()

  setDarkThemeOverride(true);
  setOverrideTrackerVisibility(true);

  useEffect(() => {
    setIsLoading(true)
    loansService.getLoan(activeLoan)
      .then((l: any) => {
        const loan = l.data.form_data
        if (loan.borrower.id !== loan.loan.principal_borrower_id) {
          history.push('/application/borrower-info')
        }
        setLoanData({ ...loan })
        setIsLoading(false)
      })
  }, [activeLoan, loansService])

  useEffect(() => {
    window.scrollTo(scrollToDefaultOptions)
  }, [])

  return (
    <>
      <LoaderOverlay active={isLoading} />
      {!isLoading && (
        <Formik
          initialValues={{
            usage: undefined,
            refiGoal: undefined,
            loanAmount: '',

            // Address Input
            street: '',
            unit: '',
            city: '',
            state: '',
            postal: '',
            county: '',

            // Submit
            submit: null
          }}
          validationSchema={Yup.object().shape({
            usage: Yup.string().required(' '),
            refiGoal: Yup.string().required(' '),
            loanAmount: Yup.string().required(' '),
            street: Yup.string().min(2, 'Invalid address entered, minimum of 2 characters required').required(' '),
            city: Yup.string().required(' '),
            state: Yup.string().required(' '),
            postal: Yup.string().required(' ').matches(/^[0-9]{5}$/, 'Zipcode must be exactly 5 digits')
          })}
          onSubmit={async (values, {
            setErrors,
            setStatus,
            setSubmitting
          }) => {
            try {

            const changedData = {
              application: {
                current_step: ApplicationRoutes[pathname as keyof typeof ApplicationRoutes] + 1
              },
              loan: {
                loan_purpose_type: "refinance",
                base_loan_amount: values.loanAmount.replace(/[[$,]/g, ''),
                refi_goal: values.refiGoal,

                  // resets values from Purchase tract
                  purchase_plan: null,
                  purchase_timeframe: null
                },
                property: {
                  usage: values.usage,
                  address_street_line: values.street,
                  address_unit: values.unit,
                  address_city: values.city,
                  address_state: values.state,
                  address_postal: values.postal,
                  address_county: values.county
                }
              }

              const dataSubmit = _.merge(loanData, changedData)
              await loansService.updateLoan(activeLoan, dataSubmit);

              setDarkThemeOverride(false);
              setOverrideTrackerVisibility(false);

              history.push('/application/borrower-info')
            } catch (err: any) {
              console.error('Application error: ', err.message);
              setStatus({ success: false })
              setErrors({ submit: err.message })
              setSubmitting(false)
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            setFieldValue,
            values
          }) => (
            <>
              <form
                className="choice-form section-0"
                onSubmit={handleSubmit}
              >
                <div className="question-container">
                  <div className="form-step">
                    <Form.Field>
                      <h1 className="title-instructions">
                        How do you use this home?
                      </h1>
                      <div className="choice-container">
                        {usageOptions.map((choice: any, index: number) => (
                          <button
                            type="button"
                            className={`choice-answers ${choice.value} ${usageSelected === index ? 'selected' : 'unselected'}`}
                            tabIndex={0}
                            onKeyPress={() => {
                              setFieldValue(choice.key, choice.value)
                              setUsageSelected(index)
                            }}
                            onClick={() => {
                              setFieldValue(choice.key, choice.value)
                              setUsageSelected(index)
                            }}
                          >
                            <span>{choice.text}</span>
                            <i className={`icon small ${usageSelected === index ? 'check selected' : 'angle right unselected'}`} />
                          </button>
                        ))}
                      </div>
                    </Form.Field>
                  </div>
                  {values.usage !== undefined && (
                    <div className="form-step">
                      <Form.Field>
                        <h1 className="title-instructions">
                          What is your goal for this refinance?
                        </h1>
                        <div className="choice-container">
                          {refiGoalOptions.map((choice: any, index: number) => (
                            <button
                              type="button"
                              className={`choice-answers ${choice.value} ${refiGoalSelected === index ? 'selected' : 'unselected'}`}
                              tabIndex={0}
                              onKeyPress={() => {
                                setFieldValue(choice.key, choice.value)
                                setRefiGoalSelected(index)
                              }}                              onClick={() => {
                                setFieldValue(choice.key, choice.value)
                                setRefiGoalSelected(index)
                              }}
                            >
                              {choice.tooltipContent ?
                                <>
                                  <span>{choice.text}</span>
                                  <HelperPopup
                                    on="hover"
                                    additionalClass="section-0-popup"
                                    headerLabel={choice.text}
                                    content={choice.tooltipContent}
                                    offset={[0, 20]}
                                  />
                                </>
                                :
                                <span>{choice.text}</span>
                              }
                              <i className={`icon small ${refiGoalSelected === index ? 'check selected' : 'angle right unselected'}`} />
                            </button>
                          ))}
                        </div>
                      </Form.Field>
                    </div>
                  )}
                  {(values.usage) && (values.refiGoal) && (
                    <div className="form-step">
                      <Form.Field>
                        <h1 className="title-instructions">
                          What is the approximate loan amount <br/>you would like to apply for?
                        </h1>
                        <FormikMaskedInput
                          className={`loan-amount ${!!(touched.loanAmount && errors.loanAmount) ? 'error' : ''}`}
                          name="loanAmount"
                          placeholder="Loan Amount"
                          type="text"
                          value={values.loanAmount}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          mask={currencyMask}
                          error={!!(touched.loanAmount && errors.loanAmount) || !!(showErrorContainer === true && values.loanAmount === '')}
                        />
                        <h1 className="title-instructions">
                          What is the address of the home <br /> you want to refinance?
                        </h1>
                        <AddressInput
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          touched={touched}
                          errors={errors}
                          showLabel={false}
                          darkTheme={true}
                          showErrorContainer={showErrorContainer}
                          setFieldValue={setFieldValue}
                          values={values}
                        />
                      </Form.Field>
                    </div>
                  )}
                  <div className="purchase-footer">
                    <Button
                      disabled={
                        values.usage === undefined ||
                        values.refiGoal === undefined ||
                        disableOnErrors
                      }
                      type="submit"
                      onClick={() => setShowErrorContainer(true)}
                    >
                      Save &amp; Continue
                    </Button>
                    <div className="error-container">
                      <ErrorContainer
                        errors={errors}
                        showErrorContainer={showErrorContainer}
                        setDisableOnErrors={setDisableOnErrors}
                      />
                    </div>
                  </div>
                </div>
              </form>
            </>
          )}
        </Formik>
      )}
    </>
  )
})

export default Step2Refinance;
