import React, {FC, useContext} from 'react';
import {Link} from 'react-router-dom';
import {DataContext} from '../../context/dataContext';

export interface PageHeaderLinkProps {
  label: string;
  onClick?: any;
  to?: any;
  href?: string;
  target?: string;
  inverted?: boolean;
  active?: boolean;
  id?: string;
}

const PageHeaderLink: FC<PageHeaderLinkProps> = ({
  label,
  onClick,
  to,
  href,
  target = '_blank',
  inverted = false,
  active,
  id
}) => {
  const { pathname } = useContext(DataContext)

  const className = `
    header-button-link
    ${inverted && 'inverted'}
    ${(to === pathname || active) && 'is-active'}
  `

  if (onClick) {
    return (
      <a
        onClick={onClick}
        className={className}
        id={id}
        href={href}
      >
        {label}
      </a>
    )
  }
  else if (href) {
    return (
      <a
        href={href}
        target={target}
        className={className}
      >
        {label}
      </a>
    )
  }
  else {
    return (
      <Link
        to={to}
        className={className}
      >
        {label}
      </Link>
    )
  }
}

export default PageHeaderLink;
