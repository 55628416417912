import React, { FC, useContext, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Button, Form } from 'semantic-ui-react';
import { Field, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import { DataContext } from '../../../context/dataContext';
import _ from 'lodash';
import { scrollToDefaultOptions } from '../../../helpers/scroll-to-options';

interface ResidenceConciergeProps {
  loanData: any;
  coBorrowerConcierge: boolean;
  setShowResidenceForm: Function;
  hasResidences: boolean
}

const ResidenceConcierge: FC<ResidenceConciergeProps> = observer(({
  loanData,
  coBorrowerConcierge,
  setShowResidenceForm,
  hasResidences
}) => {
  const { setDarkThemeOverride, setOverrideTrackerVisibility } = useContext(DataContext);
  const [ownerName, setOwnerName] = useState<string>('');

  setDarkThemeOverride(true);
  setOverrideTrackerVisibility(true);

  useEffect(() => {
    setOwnerName(coBorrowerConcierge ? loanData.coborrower.first_name : loanData.borrower.first_name);
  }, [coBorrowerConcierge]);

  useEffect(() => {
    window.scrollTo(scrollToDefaultOptions)
  }, [])

  return (
    <Formik
      enableReinitialize
      initialValues={{
        submit: null
      }}
      validationSchema={Yup.object().shape({})}
      onSubmit={(values, {
        setErrors,
        setStatus,
        setSubmitting,
        resetForm
      }) => {
        try {

          setStatus({ success: true });
          setSubmitting(false);
          resetForm({});

          setShowResidenceForm(true)
        } catch (err: any) {
          setStatus({ success: false })
          setErrors({ submit: err.message })
          setSubmitting(false)
        }
      }}
    >
      {({
        handleSubmit
      }) => {
        return (
          <div className="application-step-container residences-container">
            <form className="application-form" onSubmit={handleSubmit}>
              <div className="form-step">
                <Form.Field>
                  <h1 className="title choice-form concierge">
                    Next, let's collect some info about your <br /> housing history over the past 2 years.
                  </h1>
                </Form.Field>
              </div>
              <Button
                className="concierge-button"
                type="submit"
              >
                Save &amp; Continue
              </Button>
            </form>
          </div>
        )
      }}
    </Formik>
  )
})

export default ResidenceConcierge;
