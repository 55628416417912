/**
 * Checks values against loanData and optionally tableData for uniqueness
 *
 * @param values the values to check for uniqueness
 * @param loanData Hive data set
 * @param sectionKey Section value of loanData to compare against
 * @param validationKeys Specific keys in section to compare against
 * @param tableData Optionally compare against tableData for uniqueness
 */
export const valuesAreUnique = (
  values: Array<string>,
  loanData: any,
  sectionKey: string,
  validationKeys: Array<string>,
  tableData?: Array<any>,
) => {
  const checkUniqueness = (data: any, sectionKey?: string) => {
    let dataToBeFiltered = data

    if (sectionKey) {
      dataToBeFiltered = data[sectionKey]
    }

    return (dataToBeFiltered.filter((row: any) => {
      return JSON.stringify([
        ...validationKeys.map((key: string) => row[key])
      ]).toLocaleLowerCase() === JSON.stringify(values).toLocaleLowerCase()
    }).length === 0)
  }

  return tableData ? checkUniqueness(tableData) && checkUniqueness(loanData, sectionKey) : checkUniqueness(loanData, sectionKey)
}

/**
 * Checks originalValues against newValues to confirm they are identical
 *
 * @param originalValues the original set of values to compare against
 * @param newValues the new set of values to compare against
 */
export const valuesAreIdentical = (
  originalValues: Array<string>,
  newValues: Array<string>
) => {
  console.log('JSON.stringify(originalValues).toLocaleLowerCase(): ', JSON.stringify(originalValues).toLocaleLowerCase())

  console.log('JSON.stringify(newValues).toLocaleLowerCase(): ', JSON.stringify(newValues).toLocaleLowerCase())

  return JSON.stringify(originalValues).toLocaleLowerCase() === JSON.stringify(newValues).toLocaleLowerCase()
}
