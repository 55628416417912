import React, { FC } from 'react';
import { Form, Input } from 'semantic-ui-react';
import FormikRadio from '../../../components/Formik/FormikRadio';
import {
  propertyTypeOptions,
  outstandingLoansType,
  mortgageImpoundedOptions
} from './data'
import { currencyMask } from '../../../helpers/currency-mask-input';
import { percentMask } from '../../../helpers/percent-mask-input';
import MaskedInput from 'react-text-mask';
import { BorrowerInfoProps } from './index'
import AddressInput from '../../../components/AddressInput/AddressInput';
import FormikDropdown from '../../../components/Formik/FormikDropdown';
import { generateYearOptions } from '../../../helpers/date-helpers';
import { clearValues } from '../../../helpers/clear-values-helper';

const RefiBase: FC<BorrowerInfoProps> = ({
  errors,
  values,
  touched,
  handleBlur,
  handleChange,
  showErrorContainer,
  coapplicantMode,
  setFieldValue
}) => {

  return values.homeUse === 'primary' || values.homeUse === 'secondary' ? (
    <>
      <div className="form-step">
        <AddressInput
          handleBlur={handleBlur}
          handleChange={handleChange}
          touched={touched}
          errors={errors}
          disabled={coapplicantMode}
          setFieldValue={setFieldValue}
          values={values}
        />
        <Form.Field>
          <label>What is the approximate property value?</label>
          <Input
            className="small"
            error={!!(touched.approxPropertyValue && errors.approxPropertyValue) || !!(showErrorContainer && values.approxPropertyValue === '')}
          >
            <MaskedInput
              name="approxPropertyValue"
              disabled={coapplicantMode}
              placeholder="$"
              type="text"
              value={values.approxPropertyValue}
              onBlur={handleBlur}
              onChange={(e: any) => handleChange(e)}
              mask={currencyMask}
            />
          </Input>
        </Form.Field>

        <Form.Field>
          <label>What year did you purchase this property?</label>
          <FormikDropdown
            className="small"
            disabled={coapplicantMode}
            search
            name="yearPurchased"
            placeholder="YYYY"
            options={generateYearOptions(1920)}
            value={values.yearPurchased}
            error={!!(touched.yearPurchased && errors.yearPurchased) || !!(showErrorContainer && values.yearPurchased === '')}
          />
        </Form.Field>
        <Form.Field>
          <label>What was the approximate original purchase price?</label>
          <Input
            className="small"
            error={!!(touched.originalPrice && errors.originalPrice) || !!(showErrorContainer && values.originalPrice === '')}
          >
            <MaskedInput
              name="originalPrice"
              disabled={coapplicantMode}
              placeholder="$"
              type="text"
              value={values.originalPrice}
              onBlur={handleBlur}
              onChange={(e: any) => handleChange(e)}
              mask={currencyMask}
            />
          </Input>
        </Form.Field>
        <Form.Field>
          <label>What is the property type?</label>
          <FormikDropdown
            className="large"
            disabled={coapplicantMode}
            key={propertyTypeOptions}
            name="propertyType"
            placeholder="..."
            options={propertyTypeOptions}
            value={values.propertyType}
            error={!!(touched.propertyType && errors.propertyType) || !!(showErrorContainer && values.propertyType === '')}
            onClick={() => clearValues(values, [
              'propertyType',
              'commonWalls',
              'hoaDues'
            ])}
          />
        </Form.Field>

        {values.propertyType === 'sfr-planned-development'
          || values.propertyType === 'condo' ? (
            <>
              <Form.Field className="nested">
                <label>Is this property attached or detached?</label>
                <div className={`radio-group ${showErrorContainer && values.commonWalls === '' && 'radio-error'}`}>
                  <FormikRadio
                    label="Attached"
                    disabled={coapplicantMode}
                    name="commonWalls"
                    value="attached"
                    checked={values.commonWalls === 'attached'}
                    className={errors.commonWalls && 'has-error'}
                  />
                  <FormikRadio
                    label="Detached"
                    disabled={coapplicantMode}
                    name="commonWalls"
                    value="detached"
                    checked={values.commonWalls === 'detached'}
                    className={errors.commonWalls && 'has-error'}
                  />
                </div>
              </Form.Field>
              <Form.Field className="nested">
                <label>What are the monthly HOA dues?</label>
                <Input
                  className="small"
                  error={!!(touched.hoaDues && errors.hoaDues) || !!(showErrorContainer && values.hoaDues === '')}
                >
                  <MaskedInput
                    name="hoaDues"
                    disabled={coapplicantMode}
                    placeholder="HOA Dues"
                    type="text"
                    value={values.hoaDues}
                    onBlur={handleBlur}
                    onChange={(e: any) => handleChange(e)}
                    mask={currencyMask}
                  />
                </Input>
              </Form.Field>
            </>
          ) : null}
        {/* <Form.Field >
          <label>In what year was the property structure built?</label>
            <FormikDropdown
              className={`small ${!!(touched.structureYearBuilt && errors.structureYearBuilt) ? 'error' : ''}`}
              search
              placeholder="YYYY"
              name="structureYearBuilt"
              disabled={coapplicantMode}
              options={generateYearOptions(1920)}
              value={values.structureYearBuilt}
              onBlur={handleBlur}
            onChange={(e: any) => handleChange(e)}
              error={!!(touched.structureYearBuilt && errors.structureYearBuilt) || !!(showErrorContainer && values.structureYearBuilt === '')}
            />
        </Form.Field> */}
        <Form.Field>
          <label>What are the approximate annual property taxes?</label>
          <Input
            className="small"
            error={!!(touched.propertyTaxes && errors.propertyTaxes) || !!(showErrorContainer && values.propertyTaxes === '')}
          >
            <MaskedInput
              name="propertyTaxes"
              disabled={coapplicantMode}
              placeholder="Property Taxes"
              type="text"
              value={values.propertyTaxes}
              onBlur={handleBlur}
              onChange={(e: any) => handleChange(e)}
              mask={currencyMask}
            />
          </Input>
        </Form.Field>
        <Form.Field>
          <label>What is the approximate annual insurance premium?</label>
          <Input
            className="small"
            error={!!(touched.insurancePremium && errors.insurancePremium) || !!(showErrorContainer && values.insurancePremium === '')}
          >
            <MaskedInput
              name="insurancePremium"
              disabled={coapplicantMode}
              placeholder="Annual Insurance Premium"
              type="text"
              value={values.insurancePremium}
              onBlur={handleBlur}
              onChange={(e: any) => handleChange(e)}
              mask={currencyMask}
            />
          </Input>
        </Form.Field>
        <Form.Field>
          <label>How many loans are outstanding against the property currently?</label>
          <FormikDropdown
            className="large"
            disabled={coapplicantMode}
            name="outstandingLoans"
            placeholder="Outstanding Loans"
            options={outstandingLoansType}
            onClick={() => clearValues(
              values, [
              'firstMortgageLoanAmount',
              'firstMortgageTaxAndInsurance',
              'firstMortgagePayment',
              'firstMortgageImpounded',
              'secondMortgageType',
              'secondMortgageLoanAmount',
              'secondMortgageTaxAndInsurance',
              'secondMortgagePayment',
              'secondMortgageBalance',
              'secondMortgageCreditLimit',
              'secondMortgageInterestRate',
              'secondMortgageEquityPayment',
              'thirdMortgageType',
              'thirdMortgageLoanAmount',
              'thirdMortgageTaxAndInsurance',
              'thirdMortgagePayment',
              'thirdMortgageBalance',
              'thirdMortgageCreditLimit',
              'thirdMortgageInterestRate',
              'thirdMortgageEquityPayment'
            ])}
            value={values.outstandingLoans}
            error={!!(touched.outstandingLoans && errors.outstandingLoans) || !!(showErrorContainer && values.outstandingLoans === '')}
          />
        </Form.Field>
      </div>
      {(
        values.outstandingLoans === "firstMortgageOnly" || values.outstandingLoans === "firstAndSecondMortgage" || values.outstandingLoans === "firstSecondAndThirdMortgage"
      ) && (
          <div className="form-step">
            <Form.Field>
              <label>Tell us a little more about your existing first mortgage</label>
            </Form.Field>
            <Form.Field className="nested">
            <label>Loan Amount</label>
              <Input
                className="small"
                error={!!(touched.firstMortgageLoanAmount && errors.firstMortgageLoanAmount) || !!(showErrorContainer && values.firstMortgageLoanAmount === '')}
              >
                <MaskedInput
                  name="firstMortgageLoanAmount"
                  disabled={coapplicantMode}
                  placeholder="$"
                  type="text"
                  value={values.firstMortgageLoanAmount}
                  onBlur={handleBlur}
                onChange={(e: any) => handleChange(e)}
                  mask={currencyMask}
                />
              </Input>
            </Form.Field>
            <Form.Field className="nested">
            <label>Taxes and insurance</label>
              <Input
                className="small"
                error={!!(touched.firstMortgageTaxAndInsurance && errors.firstMortgageTaxAndInsurance) || !!(showErrorContainer && values.firstMortgageTaxAndInsurance === '')}
              >
                <MaskedInput
                  name="firstMortgageTaxAndInsurance"
                  disabled={coapplicantMode}
                  placeholder="$"
                  type="text"
                  value={values.firstMortgageTaxAndInsurance}
                  onBlur={handleBlur}
                onChange={(e: any) => handleChange(e)}
                  mask={currencyMask}
                />
              </Input>
            </Form.Field>
            <Form.Field className="nested">
            <label>Payment</label>
              <Input
                className="small"
                error={!!(touched.firstMortgagePayment && errors.firstMortgagePayment) || !!(showErrorContainer && values.firstMortgagePayment === '')}
              >
                <MaskedInput
                  name="firstMortgagePayment"
                  disabled={coapplicantMode}
                  placeholder="$"
                  type="text"
                  value={values.firstMortgagePayment}
                  onBlur={handleBlur}
                onChange={(e: any) => handleChange(e)}
                  mask={currencyMask}
                />
              </Input>
            </Form.Field>
            <Form.Field className="nested">
            <label>Is this mortgage impounded for?</label>
              <FormikDropdown
                className="small"
                disabled={coapplicantMode}
                name="firstMortgageImpounded"
                placeholder="Impound Status"
                options={mortgageImpoundedOptions}
                value={values.firstMortgageImpounded}
                error={!!(touched.firstMortgageImpounded && errors.firstMortgageImpounded) || !!(showErrorContainer && values.firstMortgageImpounded === '')}
              />
            </Form.Field>
          </div>
        )}
      {(
        values.outstandingLoans === "firstAndSecondMortgage" || values.outstandingLoans === "firstSecondAndThirdMortgage"
      ) && (
          <div className="form-step">
            <Form.Field>
              <label>Tell us a little more about your existing second mortgage</label>
            </Form.Field>
            <Form.Field className="nested">
            <label>Is it a fixed rate second or a home equity line of credit?</label>
              <div className={`radio-group ${(showErrorContainer
                && (values.outstandingLoans === 'firstAndSecondMortgage' || values.outstandingLoans === 'firstSecondAndThirdMortgage')
                && values.secondMortgageType === '') && 'radio-error'}`}>
                <FormikRadio
                  label="Fixed Rate Second"
                  disabled={coapplicantMode}
                  name="secondMortgageType"
                  value="fixed"
                  checked={values.secondMortgageType === 'fixed'}
                  onClick={() => clearValues(
                    values, [
                    'secondMortgageBalance',
                    'secondMortgageCreditLimit',
                    'secondMortgageInterestRate',
                    'secondMortgageEquityPayment',
                  ])}
                  className={errors.secondMortgageType && 'has-error'}
                />
                <FormikRadio
                  label="Home Equity Line of Credit"
                  disabled={coapplicantMode}
                  name="secondMortgageType"
                  value="heloc"
                  checked={values.secondMortgageType === 'heloc'}
                  onClick={() => clearValues(
                    values, [
                    'secondMortgageLoanAmount',
                    'secondMortgageTaxAndInsurance',
                    'secondMortgagePayment'
                  ])}
                  className={errors.secondMortgageType && 'has-error'}
                />
              </div>
            </Form.Field>
            {values.secondMortgageType === 'fixed' && (
              <>
                <Form.Field className="nested">
                <label>Loan Amount</label>
                  <Input
                    className="small"
                    error={!!(touched.secondMortgageLoanAmount && errors.secondMortgageLoanAmount) || !!(showErrorContainer && values.secondMortgageLoanAmount === '')}
                  >
                    <MaskedInput
                      name="secondMortgageLoanAmount"
                      disabled={coapplicantMode}
                      placeholder="$"
                      type="text"
                      value={values.secondMortgageLoanAmount}
                      onBlur={handleBlur}
                    onChange={(e: any) => handleChange(e)}
                      mask={currencyMask}
                    />
                  </Input>
                </Form.Field>
                <Form.Field className="nested">
                <label>Taxes and insurance</label>
                  <Input
                    className="small"
                    error={!!(touched.secondMortgageTaxAndInsurance && errors.secondMortgageTaxAndInsurance) || !!(showErrorContainer && values.secondMortgageTaxAndInsurance === '')}
                  >
                    <MaskedInput
                      name="secondMortgageTaxAndInsurance"
                      disabled={coapplicantMode}
                      placeholder="$"
                      type="text"
                      value={values.secondMortgageTaxAndInsurance}
                      onBlur={handleBlur}
                    onChange={(e: any) => handleChange(e)}
                      mask={currencyMask}
                    />
                  </Input>
                </Form.Field>
                <Form.Field className="nested">
                <label>Payment</label>
                  <Input
                    className="small"
                    error={!!(touched.secondMortgagePayment && errors.secondMortgagePayment) || !!(showErrorContainer && values.secondMortgagePayment === '')}
                  >
                    <MaskedInput
                      name="secondMortgagePayment"
                      disabled={coapplicantMode}
                      placeholder="$"
                      type="text"
                      value={values.secondMortgagePayment}
                      onBlur={handleBlur}
                    onChange={(e: any) => handleChange(e)}
                      mask={currencyMask}
                    />
                  </Input>
                </Form.Field>
              </>
            )}
            {values.secondMortgageType === 'heloc' && (
              <>
                <Form.Field className="nested">
                <label>Current Balance</label>
                  <Input
                    className="small"
                    error={!!(touched.secondMortgageBalance && errors.secondMortgageBalance) || !!(showErrorContainer && values.secondMortgageBalance === '')}
                  >
                    <MaskedInput
                      name="secondMortgageBalance"
                      disabled={coapplicantMode}
                      placeholder="$"
                      type="text"
                      value={values.secondMortgageBalance}
                      onBlur={handleBlur}
                    onChange={(e: any) => handleChange(e)}
                      mask={currencyMask}
                    />
                  </Input>
                </Form.Field>
                <Form.Field className="nested">
                <label>Credit Limit</label>
                  <Input
                    className="small"
                    error={!!(touched.secondMortgageCreditLimit && errors.secondMortgageCreditLimit) || !!(showErrorContainer && values.secondMortgageCreditLimit === '')}
                  >
                    <MaskedInput
                      name="secondMortgageCreditLimit"
                      disabled={coapplicantMode}
                      placeholder="Credit Limit"
                      type="text"
                      value={values.secondMortgageCreditLimit}
                      onBlur={handleBlur}
                    onChange={(e: any) => handleChange(e)}
                      mask={currencyMask}
                    />
                  </Input>
                </Form.Field>
                <Form.Field className="nested">
                <label>Interest Rate</label>
                  <Input
                    className="small"
                    error={!!(touched.secondMortgageInterestRate && errors.secondMortgageInterestRate) || !!(showErrorContainer && values.secondMortgageInterestRate === '')}
                  >
                    <MaskedInput
                      name="secondMortgageInterestRate"
                      disabled={coapplicantMode}
                      placeholder="%"
                      type="text"
                      value={values.secondMortgageInterestRate}
                      onBlur={handleBlur}
                    onChange={(e: any) => handleChange(e)}
                      mask={percentMask}
                    />
                  </Input>
                </Form.Field>
                <Form.Field className="nested">
                <label>Payment</label>
                  <Input
                    className="small"
                    error={!!(touched.secondMortgageEquityPayment && errors.secondMortgageEquityPayment) || !!(showErrorContainer && values.secondMortgageEquityPayment === '')}
                  >
                    <MaskedInput
                      name="secondMortgageEquityPayment"
                      disabled={coapplicantMode}
                      placeholder="$"
                      type="text"
                      value={values.secondMortgageEquityPayment}
                      onBlur={handleBlur}
                    onChange={(e: any) => handleChange(e)}
                      mask={currencyMask}
                    />
                  </Input>
                </Form.Field>
              </>
            )}
          </div>
        )}
      {
        (values.outstandingLoans === "firstSecondAndThirdMortgage") && (
          <div className="form-step">
            <Form.Field>
              <label>Tell us a little more about your existing third mortgage</label>
            </Form.Field>
            <Form.Field className="nested">
              <label>Is it a fixed third or a home equity line of credit?</label>
              <div className={`radio-group ${(showErrorContainer
                && values.outstandingLoans === 'firstSecondAndThirdMortgage'
                && values.thirdMortgageType === '') && 'radio-error'}`}>
                <FormikRadio
                  label="Fixed Rate Third"
                  disabled={coapplicantMode}
                  name="thirdMortgageType"
                  value="fixed"
                  checked={values.thirdMortgageType === 'fixed'}
                  onClick={() => clearValues(
                    values, [
                    'thirdMortgageBalance',
                    'thirdMortgageCreditLimit',
                    'thirdMortgageInterestRate',
                    'thirdMortgageEquityPayment',
                  ])}
                  className={errors.thirdMortgageType && 'has-error'}
                />
                <FormikRadio
                  label="Home Equity Line of Credit"
                  disabled={coapplicantMode}
                  name="thirdMortgageType"
                  value="heloc"
                  checked={values.thirdMortgageType === 'heloc'}
                  onClick={() => clearValues(
                    values, [
                    'thirdMortgageLoanAmount',
                    'thirdMortgageTaxAndInsurance',
                    'thirdMortgagePayment'
                  ])}
                  className={errors.thirdMortgageType && 'has-error'}
                />
              </div>
            </Form.Field>
            {values.thirdMortgageType === 'fixed' && (
              <>
                <Form.Field className="nested">
                  <label>Loan Amount</label>
                  <Input
                    className="small"
                    error={!!(touched.thirdMortgageLoanAmount && errors.thirdMortgageLoanAmount) || !!(showErrorContainer && values.thirdMortgageLoanAmount === '')}
                  >
                    <MaskedInput
                      name="thirdMortgageLoanAmount"
                      disabled={coapplicantMode}
                      placeholder="$"
                      type="text"
                      value={values.thirdMortgageLoanAmount}
                      onBlur={handleBlur}
                      onChange={(e: any) => handleChange(e)}
                      mask={currencyMask}
                    />
                  </Input>
                </Form.Field>
                <Form.Field className="nested">
                  <label>Taxes and insurance</label>
                  <Input
                    className="small"
                    error={!!(touched.thirdMortgageTaxAndInsurance && errors.thirdMortgageTaxAndInsurance) || !!(showErrorContainer && values.thirdMortgageTaxAndInsurance === '')}
                  >
                    <MaskedInput
                      name="thirdMortgageTaxAndInsurance"
                      disabled={coapplicantMode}
                      placeholder="$"
                      type="text"
                      value={values.thirdMortgageTaxAndInsurance}
                      onBlur={handleBlur}
                      onChange={(e: any) => handleChange(e)}
                      mask={currencyMask}
                    />
                  </Input>
                </Form.Field>
                <Form.Field className="nested">
                  <label>Payment</label>
                  <Input
                    className="small"
                    error={!!(touched.thirdMortgagePayment && errors.thirdMortgagePayment) || !!(showErrorContainer && values.thirdMortgagePayment === '')}
                  >
                    <MaskedInput
                      name="thirdMortgagePayment"
                      disabled={coapplicantMode}
                      placeholder="$"
                      type="text"
                      value={values.thirdMortgagePayment}
                      onBlur={handleBlur}
                      onChange={(e: any) => handleChange(e)}
                      mask={currencyMask}
                    />
                  </Input>
                </Form.Field>
              </>
            )}
            {values.thirdMortgageType === 'heloc' && (
              <>
                <Form.Field className="nested">
                  <label>Current Balance</label>
                  <Input
                    className="small"
                    error={!!(touched.thirdMortgageBalance && errors.thirdMortgageBalance) || !!(showErrorContainer && values.thirdMortgageBalance === '')}
                  >
                    <MaskedInput
                      name="thirdMortgageBalance"
                      disabled={coapplicantMode}
                      placeholder="$"
                      type="text"
                      value={values.thirdMortgageBalance}
                      onBlur={handleBlur}
                      onChange={(e: any) => handleChange(e)}
                      mask={currencyMask}
                    />
                  </Input>
                </Form.Field>
                <Form.Field className="nested">
                  <label>Credit Limit</label>
                  <Input
                    className="small"
                    error={!!(touched.thirdMortgageCreditLimit && errors.thirdMortgageCreditLimit) || !!(showErrorContainer && values.thirdMortgageCreditLimit === '')}
                  >
                    <MaskedInput
                      name="thirdMortgageCreditLimit"
                      disabled={coapplicantMode}
                      placeholder="Credit Limit"
                      type="text"
                      value={values.thirdMortgageCreditLimit}
                      onBlur={handleBlur}
                      onChange={(e: any) => handleChange(e)}
                      mask={currencyMask}
                    />
                  </Input>
                </Form.Field>
                <Form.Field className="nested">
                  <label>Interest Rate</label>
                  <Input
                    className="small"
                    error={!!(touched.thirdMortgageInterestRate && errors.thirdMortgageInterestRate) || !!(showErrorContainer && values.thirdMortgageInterestRate === '')}
                  >
                    <MaskedInput
                      name="thirdMortgageInterestRate"
                      disabled={coapplicantMode}
                      placeholder="%"
                      type="text"
                      value={values.thirdMortgageInterestRate}
                      onBlur={handleBlur}
                      onChange={(e: any) => handleChange(e)}
                      mask={percentMask}
                    />
                  </Input>
                </Form.Field>
                <Form.Field className="nested">
                  <label>Payment</label>
                  <Input
                    className="small"
                    error={!!(touched.thirdMortgageEquityPayment && errors.thirdMortgageEquityPayment) || !!(showErrorContainer && values.thirdMortgageEquityPayment === '')}
                  >
                    <MaskedInput
                      name="thirdMortgageEquityPayment"
                      disabled={coapplicantMode}
                      placeholder="$"
                      type="text"
                      value={values.thirdMortgageEquityPayment}
                      onBlur={handleBlur}
                      onChange={(e: any) => handleChange(e)}
                      mask={currencyMask}
                    />
                  </Input>
                </Form.Field>
              </>
            )}
          </div>
        )}
    </>
  ) : null;
}

export default RefiBase;
