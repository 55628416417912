import {observer} from 'mobx-react-lite';
import React, {FC, useContext, useEffect, useState} from 'react';
import {useStore} from '@jmjfinancial/apis/lib';
import {DataContext} from '../../context/dataContext';
import LoaderOverlay from '../../components/LoaderOverlay/LoaderOverlay';
import {useParams} from 'react-router-dom';
import './invoice.scss'
import InvoiceComponent from './InvoiceComponent';
import PaymentComponent from './PaymentComponent';

export interface InvoiceProps {
  loanData: any
  invoice: any
  invoiceId: number
}

const Invoice: FC = observer(() => {
  const store = useStore()
  const { loansService, invoicesService } = store

  const { activeLoan, clearStaleUser } = useContext(DataContext)

  const { invoiceId } = useParams<any>()

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [loanData, setLoanData] = useState<any>()
  const [invoice, setInvoice] = useState<any>()
  const [refreshInvoicePage, setRefreshInvoicePage] = useState<boolean>(false)


  useEffect(() => {
    loansService.getLoan(activeLoan)
      .then(res => {
        const loan = res?.data?.form_data

        if (loan) {
          setLoanData((loanData: any) => loan)

          invoicesService.getInvoice(loan?.borrower.borrower_pair_id, invoiceId)
            .then(res => {
              setInvoice((invoice: any) => res.data)
            })
        }
        else {
          clearStaleUser()
        }

        setIsLoading(isLoading => false)
      })
  }, [activeLoan, refreshInvoicePage])

  return (
    <div className="invoice-page">
      <LoaderOverlay active={isLoading} />
      <InvoiceComponent
        loanData={loanData}
        invoice={invoice}
        invoiceId={invoiceId}
      />
      {invoice && invoice?.status !== 'paid' && (
        <PaymentComponent
          loanData={loanData}
          invoice={invoice}
          invoiceId={invoiceId}
          setRefreshInvoicePage={setRefreshInvoicePage}
          setIsLoading={setIsLoading}
        />
      )}
    </div>
  )
})

export default Invoice
