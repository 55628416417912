import React, { FC, useEffect, useState } from 'react';
import {Switch, Route, useLocation} from 'react-router-dom';
import { DataContext } from './context/dataContext';

import { useLoadScript } from '@react-google-maps/api';

import LandingPage from './views/LandingPage/LandingPage';
import Login from './views/Login/Login';
import Dashboard from './views/Dashboard/index';
import PageHeader from './components/PageHeader';
import EmployeeEsign from './views/EmployeeEsign/EmployeeEsign'

import './App.scss';
import Registration from './views/Registration/Registration';
import LoanApplication from './views/LoanApplication';
import Step1 from './views/LoanApplication/Section0/index';
import Step2Refinance from './views/LoanApplication/Section0/Refinance';
import Step2Purchase from './views/LoanApplication/Section0/Purchase';

import { Customer, User } from './types/user';
import { observer } from 'mobx-react-lite';
import { useStore } from '@jmjfinancial/apis/lib';
import BorrowerInformation from './views/LoanApplication/BorrowerInformation';
import AuthorizationAndConsent from './views/LoanApplication/AuthAndConsent';
import LoanDetailsV2 from './views/LoanApplication/LoanDetails/index';
import Demographics from './views/LoanApplication/Demographics';
import ReviewAndSubmit from './views/LoanApplication/ReviewAndSubmit';
import CoBorrowerInformation from './views/LoanApplication/BorrowerInformation/CoBorrowerInformation';
import Declarations from './views/LoanApplication/Declarations';
import PageFooter from './components/PageFooter';
import PasswordReset from './views/PasswordReset/PasswordReset';
import ForgotPassword from './views/ForgotPassword/ForgotPassword';
import EmploymentAndIncomeV2 from './views/LoanApplication/EmploymentAndIncome';
import ResidenceV2 from './views/LoanApplication/Residences';
import AddCoApplicantV2 from './views/LoanApplication/AddCoApplicant/index';
import AssetsV3 from './views/LoanApplication/Assets';
import RealEstateOwned from './views/LoanApplication/RealEstateOwned';
import MyAccount from './views/MyAccount';
import MyLoans from './views/MyLoans';
import { useHistory } from 'react-router';
import { Sidebar } from 'semantic-ui-react';
import { isMobile, isMobileOnly, isTablet } from 'react-device-detect';
import AssetsWorksheet from './views/Worksheets/Assets';
import * as Sentry from '@sentry/react'
import {ErrorBoundary} from './components/ErrorBoundary/ErrorBoundary';
import Invoice from './views/Invoices';

type LibraryType = 'places'

const VERSION = process.env.REACT_APP_VERSION;
const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY || ''
const libraries: LibraryType[] = ['places']

const bodyClass = 'no-scroll'

const App: FC = observer(() => {
  const store = useStore();
  const history = useHistory();
  const { authService, loansService } = store;

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: GOOGLE_API_KEY,
    libraries
  })

  const pathname = useLocation().pathname;


  const [darkThemeOverride, setDarkThemeOverride] = useState(false)
  const [sidebarVisible, setSidebarVisible] = useState<boolean>(false)
  const [referrer, setReferrer] = useState<string>('')

  const darkThemeURLs = [
    '/',
    '/login',
    '/registration',
    '/forgot-password',
    '/password-reset',
    '/application/start',
    '/application/purchase',
    '/application/refinance'
  ]
  const splitThemeURLs = [
    '/application/start',
  ]

  const darkTheme = darkThemeURLs.includes(pathname) ||  darkThemeOverride
  const splitTheme = splitThemeURLs.includes(pathname)

  const [user, setUser] = useState<User | null>(authService.getCurrentUser());
  const [customer, setCustomer] = useState<Customer | null>()
  const [activeLoan, setActiveLoan] = useState(loansService.getActiveLoan());
  const [allLoans, setAllLoans] = useState<Array<any>>([]);

  const clearStaleUser = () => {
    setUser(null)
    authService.logout()
    history.push('/login')
  }

  const appContext = {
    user,
    setUser,
    customer,
    setCustomer,
    activeLoan,
    setActiveLoan,
    pathname,
    setDarkThemeOverride,
    allLoans,
    setAllLoans,
    clearStaleUser,
    sidebarVisible,
    setSidebarVisible,
    isMobile,
    isMobileOnly,
    isTablet,
    referrer,
    setReferrer
  }

  useEffect(() => {
    // Resets theme override on path change
    setDarkThemeOverride(false)

    loansService.getCustomer().then(customer => {
      if (customer.data) {
        setCustomer(customer.data.customer)
      }
    })
  }, [pathname])

  useEffect(() => {
    if (sidebarVisible) {
      document.body.classList.add(bodyClass)
    }
    else {
      document.body.classList.remove(bodyClass)
    }
    // Removes class on unmount
    return () => document.body.classList.remove(bodyClass)
  }, [sidebarVisible])

  return (
    <Sentry.ErrorBoundary
      fallback={<ErrorBoundary />}
      showDialog={!!process.env.REACT_APP_SENTRY_ENV}
    >
      <DataContext.Provider value={appContext}>
        <Sidebar.Pushable>
          <Sidebar.Pusher dimmed={sidebarVisible}>
            <div className={`page-background${darkTheme ? ' dark' : ''}${splitTheme ? ' split' : ''}`}>
              <div className="page-container">
                <span className="version">Version: {VERSION}</span>
                <PageHeader darkTheme={darkTheme} splitTheme={splitTheme} />
                <Switch>
                  <Route
                    path="/application"
                    render={({ match: { url } }) => (
                      <LoanApplication>
                        <Route path={`${url}/start`} component={Step1} />
                        <Route path={`${url}/purchase`} component={Step2Purchase} />
                        <Route path={`${url}/refinance`} component={Step2Refinance} />
                        <Route path={`${url}/borrower-info`} component={BorrowerInformation} />
                        <Route path={`${url}/auth-and-consent`} component={AuthorizationAndConsent} />
                        <Route path={`${url}/add-co-applicant`} component={AddCoApplicantV2} />
                        <Route path={`${url}/auth-and-consent-co-borrower`} component={AuthorizationAndConsent} />
                        <Route path={`${url}/co-borrower-info`} component={CoBorrowerInformation} />
                        <Route path={`${url}/residences`} component={ResidenceV2} />
                        <Route path={`${url}/loan-details`} component={LoanDetailsV2} />
                        <Route path={`${url}/employment-and-income`} component={EmploymentAndIncomeV2} />
                        <Route path={`${url}/assets`} component={AssetsV3} />
                        <Route path={`${url}/reo`} component={RealEstateOwned} />
                        <Route path={`${url}/declarations`} component={Declarations} />
                        <Route path={`${url}/declarations-coborrower`} component={Declarations} />
                        <Route path={`${url}/demographics`} component={Demographics} />
                        <Route path={`${url}/demographics-coborrower`} component={Demographics} />
                        <Route path={`${url}/review-and-submit`} component={ReviewAndSubmit} />
                      </LoanApplication>
                    )}
                  />
                  <Route
                    path="/worksheet"
                    render={({ match: { url } }) => (
                      <Route
                        path={`${url}/assets`}
                        component={AssetsWorksheet}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/invoice/:invoiceId"
                    component={Invoice}
                  />
                  <Route
                    exact
                    path="/refer/:referrer"
                    component={LandingPage}
                  />
                  <Route
                    exact
                    path="/registration"
                    component={Registration}
                  />
                  <Route
                    exact
                    path="/login"
                    component={Login}
                  />
                  <Route
                    exact
                    path="/password-reset"
                    component={PasswordReset}
                  />
                  <Route
                    exact
                    path="/forgot-password"
                    component={ForgotPassword}
                  />
                  <Route
                    exact
                    path="/employee-esign"
                    component={EmployeeEsign}
                  />
                  <Route
                    exact
                    path="/dashboard"
                    component={Dashboard}
                  />
                  <Route
                    exact
                    path="/my-loans"
                    component={MyLoans}
                  />
                  <Route
                    exact
                    path="/my-account"
                    component={MyAccount}
                  />
                  <Route
                    exact
                    path="/"
                    component={LandingPage}
                  />
                  <Route
                    component={Sentry.ErrorBoundary}
                  />
                </Switch>
              </div>
              <PageFooter darkTheme={darkTheme} />
            </div>
          </Sidebar.Pusher>
        </Sidebar.Pushable>
      </DataContext.Provider>
    </Sentry.ErrorBoundary>
  );
});

export default App;
