import React, {FC, RefObject} from 'react';
import {Form, Input} from 'semantic-ui-react';
import MaskedInput from 'react-text-mask';
import {currencyMask} from '../../../../../helpers/currency-mask-input';
import {FormikValues} from 'formik';

interface W2YearlyTotalFieldsProps {
  values: FormikValues
  handleBlur: any
  handleChange: any
  showErrorContainer: boolean
  companyText: string,
  refStartingIncomeYear: RefObject<string>
  refStartDate: RefObject<any>
}

const W2YearlyTotalFields: FC<W2YearlyTotalFieldsProps> = ({
  values,
  handleBlur,
  handleChange,
  showErrorContainer,
  companyText,
  refStartingIncomeYear,
  refStartDate
}) => {
  return (values.personalTaxFiled || values.taxFiledYearBusiness) && (
    <Form.Field>
      {values.entityType === 'soleProprietor' ? (
        <label>Okay, for each of the following years, enter the estimated total gross income made from {values.companyName ? values.companyName : 'the company'}:</label>
      ) : (
        <label>How much in total did you make from {companyText} including any W2 for the following years?</label>
      )}
      {refStartingIncomeYear.current === '0'
      || (refStartDate.current && parseInt(refStartingIncomeYear.current as string) < refStartDate.current.getFullYear()) ? (
        <div className="monthly-average previous-year">
          <label>Year to Date</label>
          <Input
            className="small"
            error={(showErrorContainer && values.yearToDateAmount === '')}
          >
            <MaskedInput
              mask={currencyMask}
              name="yearToDateAmount"
              placeholder="$"
              type="text"
              value={values.yearToDateAmount}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </Input>
        </div>
      ) : (
        <>
          <div className="monthly-average">
            <label>{refStartingIncomeYear.current}</label>
            <Input
              className="small"
              error={(showErrorContainer && values.previousYearIncome === '')}
            >
              <MaskedInput
                mask={currencyMask}
                name="previousYearIncome"
                placeholder="$"
                type="text"
                value={values.previousYearIncome}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </Input>
          </div>
          {refStartDate.current && (parseInt(refStartingIncomeYear.current as string) - 1) >= refStartDate.current.getFullYear() && (
            <div className="monthly-average">
              <label>{`${parseInt(refStartingIncomeYear.current as string) - 1}`}</label>
              <Input
                className="small"
                error={(showErrorContainer && values.previousTwoYearsIncome === '')}
              >
                <MaskedInput
                  mask={currencyMask}
                  name="previousTwoYearsIncome"
                  placeholder="$"
                  type="text"
                  value={values.previousTwoYearsIncome}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Input>
            </div>
          )}
        </>
      )}
    </Form.Field>
  )
}

export default W2YearlyTotalFields
