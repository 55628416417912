import { Dimmer, Loader, Transition } from 'semantic-ui-react';
import React, {FC, RefObject, useEffect, useRef, useState} from 'react';
import './loader-overlay.scss';

interface LoaderOverlayProps {
  active: boolean;
  label?: string;
  light?: boolean;
  dark?: boolean;
  fixed?: boolean;
  modal?: boolean;
}

const bodyClass = 'no-scroll'

const LoaderOverlay: FC<LoaderOverlayProps> = ({
  active,
  label = 'Loading...',
  light,
  dark,
  fixed = true,
  modal = false,
}) => {
  const [top, setTop] = useState<number>(0)
  const [left, setLeft] = useState<number>(0)

  const refTop = useRef(top)
  const refLeft = useRef(left)

  const loaderContentRef: RefObject<any> = React.createRef()

  const handleScroll = () => {
    setTop(top => window.scrollY)
    setLeft(left => window.scrollX)
  }

  useEffect(() => {
    refTop.current = top
    refLeft.current = left
  })

  useEffect(() => {
    if (active) {
      document.body.classList.add(bodyClass)
      window.addEventListener('scroll', handleScroll)
    }
    else {
      document.body.classList.remove(bodyClass)
      window.removeEventListener('scroll', handleScroll)
    }

    // Removes class on unmount
    return () => {
      document.body.classList.remove(bodyClass)
      window.removeEventListener('scroll', handleScroll)
    }
  }, [active])

  return (
    <Transition
      animation="fade"
      duration={300}
      visible={active}
    >
      <Dimmer
        active={active}
        className={`
            loader-overlay
            ${light ? 'is-light ' : ''}
            ${dark ? 'is-dark ' : ''}
            ${fixed ? 'is-fixed ' : ''}
            ${modal ? 'is-modal ' : ''}
          `}
      >
        <div
          className="loader-container"
          style={{
            top: `${refTop.current}px`,
            left: `${refLeft.current}px`
          }}
        >
          <Loader ref={loaderContentRef}>{label}</Loader>
        </div>
      </Dimmer>
    </Transition>
  )
}

export default LoaderOverlay;
