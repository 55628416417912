import React, {FC, useContext, useEffect} from 'react';
import * as Yup from 'yup';
import { Button, Form, Radio } from 'semantic-ui-react';
import { Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import { DataContext } from '../../../context/dataContext';
import FormikErrorMessage from '../../../components/Formik/ErrorMessage';
import FormikRadio from '../../../components/Formik/FormikRadio';
import './add-co-applicant.scss';
import {scrollToDefaultOptions} from '../../../helpers/scroll-to-options';

interface AddCoApplicantConciergeProps {
  isSpouse: boolean
  setIsSpouse: Function
  setShowCoApplicantForm: Function
  setShowDuplicateAddCoApplicantForm: Function
  setRefreshAddCoApplicantView: Function
}

const AddCoApplicantConcierge: FC<AddCoApplicantConciergeProps> = observer(({
  isSpouse,
  setIsSpouse,
  setShowCoApplicantForm,
  setShowDuplicateAddCoApplicantForm,
  setRefreshAddCoApplicantView
}) => {
  const { setDarkThemeOverride, setOverrideTrackerVisibility } = useContext(DataContext);

  setDarkThemeOverride(true)
  setOverrideTrackerVisibility(true)

  const headerCopy = (): string => {
    return `${isSpouse ? 'Would you like your spouse included on' : 'Will anyone else be joining you on'} this loan application?`
  }

  useEffect(() => {
    window.scrollTo(scrollToDefaultOptions)
  }, [])

  return (
    <Formik
      initialValues={{
        additionalApplicant: null,

        submit: null
      }}
      validationSchema={Yup.object().shape({
        additionalApplicant: Yup.string().required('This field is required')
      })}
      onSubmit={(values, {
        setErrors,
        setStatus,
        setSubmitting,
        resetForm
      }) => {
        try {
          // Maintains spouse flag for co applicant form
          if (isSpouse) {
            setIsSpouse(values.additionalApplicant === 'true')
          }
          // When not spouse, show duplicate form based on answer
          else {
            setShowDuplicateAddCoApplicantForm(values.additionalApplicant === 'true')

            // Refresh index view if not spouse and no additional applicant
            setRefreshAddCoApplicantView(values.additionalApplicant === 'false')
          }

          // Show co applicant form based on answer
          setShowCoApplicantForm(values.additionalApplicant === 'true')

          resetForm({})
        } catch (err: any) {
          console.error('InitialAddCoApplicantForm: ', err.message);
          setStatus({ success: false });
          setErrors({ submit: err.message });
          setSubmitting(false);
        }
      }}
    >
      {({
        handleSubmit,
        handleChange,
        isSubmitting,
        values
      }) => (
        <div className="application-step-container add-co-applicant-form">
          <form
            onSubmit={handleSubmit}
            className="application-form"
          >
            <div className="form-step add-co-applicant">
              <Form.Field>
                <h1 className="title concierge">{headerCopy()}</h1>
                <div className="radio-group">
                  <Radio
                    id="additionalApplicantTrue"
                    label="Yes"
                    name="additionalApplicant"
                    value="true"
                    checked={values.additionalApplicant === 'true'}
                    onChange={handleChange}
                  />
                  <Radio
                    id="additionalApplicantFalse"
                    label="No"
                    name="additionalApplicant"
                    value="false"
                    checked={values.additionalApplicant === 'false'}
                    onChange={handleChange}
                  />
                </div>
                <FormikErrorMessage name="additionalApplicant" />
              </Form.Field>
            </div>
            <div>
              <Button
                disabled={isSubmitting || values.additionalApplicant === null}
                className="concierge-button"
                type="submit"
              >
                Save &amp; Continue
              </Button>
            </div>
          </form>
        </div>
      )}
    </Formik>
  )
})

export default AddCoApplicantConcierge;
