import React, {FC, useContext, useEffect, useRef, useState} from 'react';
import './dashboard.scss';
import SectionComponent, { Tab } from '../../components/Section';
import TaskTab from '../../components/Section/Tabs/TaskTab';
import DashItemComponent, { DashItem } from '../../components/DashItem';
import { observer } from 'mobx-react-lite';
import { useStore } from '@jmjfinancial/apis/lib';
import { DataContext } from '../../context/dataContext';
import _ from 'lodash';
import { DashSubItem } from '../../components/DashItem/DashSubItem';
import LoaderOverlay from '../../components/LoaderOverlay/LoaderOverlay';
import { useHistory } from 'react-router-dom';
import { ApplicationRoutes } from '../LoanApplication';
import FileUploadModal from '../../components/Modals/FileUploadModal';
import {PageHeaderLinkProps} from '../../components/PageHeader/PageHeaderLink';
// import { Button, Popup } from 'semantic-ui-react';
// import { NavHashLink } from 'react-router-hash-link';
import DisclosureModal from '../../components/Modals/DisclosureModal/DisclosureModal';
import {getPluralizedName} from '../../helpers/pluralize-name';
import {scrollToDefaultOptions} from '../../helpers/scroll-to-options';
// import {OwnerType} from '../../types/loan';
import LabelPopupComponent from '../../components/DashItem/LabelPopup';
// import {getTruncatedMonth} from '../../helpers/truncate-month';
import DownloadDocumentsModal from '../../components/Modals/DownloadDocumentsModal';
import RejectedTasksModal from '../../components/Modals/RejectedTasksModal';

export type TaskCategory = 'assets' | 'borrower' | 'credit' | 'disclosures' | 'identity' | 'employment_and_income' | 'loan_property' | 'incomes' | 'property'
export type MilestonesGroup =
  'file_started' |
  'prequalified' |
  'preapproved' |
  'application_disclosed' |
  'submitted_to_processing' |
  'processing' |
  'submitted_to_underwriting' |
  'underwriting' |
  'approved_with_conditions' |
  'submitted_to_closing' |
  'cleared_to_close' |
  'docs_out' |
  'docs_back' |
  'funded'

export const TASK_CATEGORIES: TaskCategory[] = ['assets', 'borrower', 'credit', 'disclosures', 'identity', 'employment_and_income', 'loan_property', 'incomes', 'property']
export const MILESTONE_STATUSES: MilestonesGroup[] = [
  'file_started',
  'prequalified',
  'preapproved',
  'application_disclosed',
  'submitted_to_processing',
  'processing',
  'submitted_to_underwriting',
  'underwriting',
  'approved_with_conditions',
  'submitted_to_closing',
  'cleared_to_close',
  'docs_out',
  'docs_back',
  'funded',
]

const TASK_POLLING_DELAY = 3000

const Dashboard: FC = observer(() => {
  const store = useStore();
  const { authService, loansService, tasksService } = store;
  const history = useHistory()
  const {
    activeLoan,
    setActiveLoan,
    clearStaleUser,
    isMobileOnly,
    isTablet
  } = useContext(DataContext)

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingTasks, setIsLoadingTasks] = useState(false);
  const [loanData, setLoanData] = useState<any>()
  const [property, setProperty] = useState<string>('')
  const [lastUpdated, setLastUpdated] = useState<string>()
  const [loanStarted, setLoanStarted] = useState<string>()
  const [showUploadModal, setShowUploadModal] = useState<boolean>(false)
  const [showDownloadModal, setShowDownloadModal] = useState<boolean>(false)
  const [showDisclosureModal, setShowDisclosureModal] = useState<boolean>(false)
  const [modalHeader, setModalHeader] = useState<string>('')
  const [modalSubheader, setModalSubheader] = useState<string>('')
  const [taskId, setTaskId] = useState<number>(0)
  const [activeTask, setActiveTask] = useState<any>()
  const [applicants, setApplicants] = useState<Array<any>>([]);
  const [tasksCompleted, setTasksCompleted] = useState<boolean>(false);
  const [refreshTasks, setRefreshTasks] = useState<boolean>(false);
  const [realtorRequested, setRealtorRequested] = useState<boolean>(false);
  const [realtor, setRealtor] = useState<Array<any>>([]);
  const [contacts, setContacts] = useState<Array<any>>([]);
  const [borrowerPairId, setBorrowerPairId] = useState<number>(0);
  const borrowerTasksCompletedRef = useRef<boolean>(false);
  const coBorrowerTasksCompletedRef = useRef<boolean>(false);
  const [completeActionItems, setCompleteActionItems] = useState<DashItem[]>([])
  const [incompleteActionItems, setIncompleteActionItems] = useState<DashItem[]>([])
  const [rejectedTasksArray, setRejectedTasksArray] = useState<Array<any>>([]);
  const [rejectedModalViewed, setRejectedModalViewed] = useState<boolean>(false)
  const [showRejectedModal, setShowRejectedModal] = useState<boolean>(false)
  const [isDisclosureTask, setIsDisclosureTask] = useState<boolean>(false)

  const [showCoborrowerTasks, setShowCoborrowerTasks] = useState<boolean>(false)
  const [borrowerTasks, setBorrowerTasks] = useState<any>({})
  const [coborrowerTasks, setCoborrowerTasks] = useState<any>({})
  const [totalCompletedBorrowerTasks, setTotalCompletedBorrowerTasks] = useState<number>(0)
  const [totalBorrowerTasks, setTotalBorrowerTasks] = useState<number>(0)
  const [totalCompletedCoborrowerTasks, setTotalCompletedCoborrowerTasks] = useState<number>(0)
  const [totalCoborrowerTasks, setTotalCoborrowerTasks] = useState<number>(0)

  const refLoanData = useRef(loanData)
  const refBorrowerTasks = useRef(borrowerTasks)
  const refCoborrowerTasks = useRef(coborrowerTasks)
  const taskInterval = useRef()
  const hasRejectedTasksRef = useRef(false);

  const dashboardContext = {
    ...useContext(DataContext),
    showUploadModal,
    setShowUploadModal,
    setShowDownloadModal,
    showDisclosureModal,
    setShowDisclosureModal,
    modalHeader,
    setModalHeader,
    modalSubheader,
    setModalSubheader,
    taskId,
    setTaskId,
    activeTask,
    setActiveTask,
    setRefreshTasks,
    showCoborrowerTasks,
    isDisclosureTask,
    setIsDisclosureTask
  }

  const resumeLoan = (loanData: any) => {
    const loanId = loanData.loan.id

    loansService.setActiveLoan(loanId);
    setActiveLoan(loanId)
    const loanCurrentStep = loanData.application.current_step

    setShowUploadModal(false)
    history.push(ApplicationRoutes[loanCurrentStep])
  }

  /**
   * Filters task data based on `taskCategory` param, then groups all filtered
   * tasks into an array based on array name or deliverable label, then maps each
   * filtered/grouped task as `actions` for each `subItem`
   * @param taskCategory TaskCategory to filter and category tasks
   * @param allTasks an array of task data
   * @param calcCoborrowerTotals Force setting of coborrower totals
   */
  const mapTaskItems = (taskCategory: TaskCategory, allTasks: any, calcCoborrowerTotals = false): DashItem | undefined => {

    const taskItems = allTasks && allTasks[taskCategory]

    if (taskItems?.length > 0) {
      let completedTasks = 0
      let totalTasks = 0

      taskItems.forEach((taskItem: any) => {
        completedTasks += taskItem.tasks?.filter((task: any) => task.status === "completed" || task.status === "canceled").length
        totalTasks += taskItem.tasks?.length
      })


      // Sets borrower and coborrower total values
      if (showCoborrowerTasks || calcCoborrowerTotals) {
        setTotalCompletedCoborrowerTasks(totalCompletedCoborrowerTasks => totalCompletedCoborrowerTasks + completedTasks)
        setTotalCoborrowerTasks(totalCoborrowerTasks => totalCoborrowerTasks + totalTasks)
      }
      else {
        setTotalCompletedBorrowerTasks(totalCompletedBorrowerTasks => totalCompletedBorrowerTasks + completedTasks)
        setTotalBorrowerTasks(totalBorrowerTasks => totalBorrowerTasks + totalTasks)
      }

      // Uses key of groupedTasks as subItem label, then adds each
      // task as a subItem action
      const subItems: DashSubItem[] = taskItems?.map((task: any) => {
          const items = task.tasks

          return ({
            label: (
              <LabelPopupComponent
                label={task.name === "fund_401k" ? "401k" : task.name?.replace(/_/g, " ")}
                position={'bottom left'}
              />
            ),
            actions: items?.map((item: any) => ({
              label: (
                <LabelPopupComponent
                  label={item.label}
                  verboseLabel={
                    item.review_result === "rejected"
                      ? `Rejected: ${item.reason}`
                      : item.description
                  }
                />
              ),
              type: item.linked_entity_type,
              completed: item.status === 'completed' || item.status === "canceled",
              task: item,
              rejected: item.review_result === "rejected" || item.status === "canceled"
            }))
          })
        })

      return {
        name: `${taskCategory.replace(/s+$/, "").replace(/_/g, " ")}`,
        totalTasksRemaining: totalTasks - completedTasks,
        completedTasks: completedTasks,
        subItems: subItems,
        completed: totalTasks > 0 && completedTasks === totalTasks,
      }
    }
  }

  const rejectedTasks: any = [];

  const populateRejectedTasksArray = (allTasks: any) => {

    allTasks && Object.values(allTasks).filter((item: any) =>
    // First check if the item is an array
    _.isArray(item))
    // If so, flatten the arrays and map through each task and return the task.tasks
    .flat().map((tasks: any) =>
    // Once all tasks arrays are returned, flatten the arrays into a single depth array
    tasks.tasks).flat()
    // Loop through that array and only set the rejected tasks to the rejectedTasksArray
    .forEach((task: any) => (task.review_result === 'rejected' && task.acknowledge_rejection !== true) && rejectedTasks.push(task));

    setRejectedTasksArray(rejectedTasksArray => rejectedTasks)
  }

  hasRejectedTasksRef.current = rejectedTasksArray.length > 0;

  // Maps each task group as a DashItem, filtering out undefined task groups
  const handleSetDashItems = () => {
    // Reset total values
    if (showCoborrowerTasks) {
      setTotalCompletedCoborrowerTasks(totalCompletedCoborrowerTasks => 0)
      setTotalCoborrowerTasks(totalCoborrowerTasks => 0)
    }
    else {
      setTotalCompletedBorrowerTasks(totalCompletedBorrowerTasks => 0)
      setTotalBorrowerTasks(totalBorrowerTasks => 0)
    }

    const dashItems = TASK_CATEGORIES
      .map(category => mapTaskItems(category, showCoborrowerTasks ? refCoborrowerTasks.current : refBorrowerTasks.current))
      //To test the Disclosure Preview Modal, comment the line of code above and uncomment the line below.
      // .map(taskCategory => buildTaskItems(taskCategory, testDisclosureArray, loan))
      .filter(item => item !== undefined)

    if (totalCoborrowerTasks === 0 && refLoanData.current.application.coborrower_id) {
      const countItems = TASK_CATEGORIES.map(category => mapTaskItems(category, refCoborrowerTasks.current, true))
    }

    const mapFilteredDashItems = (isCompleted: boolean) => {
      return dashItems.map((item: any) => {
        const filteredItem = {...item}
        filteredItem.subItems = item.subItems.map((subItem: any) => {
          const filteredSubItem = {...subItem}
          filteredSubItem.actions = subItem.actions.filter((action: any) => action.completed === isCompleted)
          return filteredSubItem.actions && filteredSubItem
        })
        // Get the total number of tasks for each array of actions or tasks in a subitem
        const allTasks = item.subItems.map((subItem: any) => subItem.actions.length).reduce((a: number,b: number) => a + b);

        return !isCompleted
          // if isCompleted === false, check to see if the completed key === isCompleted
          ? (filteredItem.completed === isCompleted && filteredItem)
          // if isCompleted === true, make sure allTasks !== the totalTasksRemaining for that entity type
          : (filteredItem.totalTasksRemaining !== allTasks && filteredItem)
      })
    }

    const completeDashItems = mapFilteredDashItems(true)
    const incompleteDashItems = mapFilteredDashItems(false)

    // @ts-ignore
    setCompleteActionItems(completeActionItems => completeDashItems)
    setIncompleteActionItems(incompleteActionItems => incompleteDashItems)
  }

  const handleSetRealtor = () => {
    const loan = refLoanData.current
    let newRealtor: any[] = [...realtor]

    if (loan.realtor !== null && loan.realtor.first_name !== '') {
      if (!newRealtor.find(({ name }) => name === `${loan.realtor.first_name} ${loan.realtor.last_name}`)) {
        newRealtor.push(
          {
            name: `${loan.realtor.first_name} ${loan.realtor.last_name}`,
            email: loan.realtor.email,
            phone_number: loan.realtor.phone_number.replace(/^\+[0-9]/, ''),
          }
        )
      }
    }

    setRealtor(realtor => newRealtor)
  }

  const handleSetApplicants = () => {
    const loan = refLoanData.current
    let newApplicants: any[] = [...applicants]

    // Check for borrower name, if not then add it
    if (!newApplicants.find(({ name }) => name === `${loan.borrower.first_name} ${loan.borrower.last_name}`)) {
      newApplicants.push(
        {
          name: `${loan.borrower.first_name} ${loan.borrower.last_name}`,
          email: loan.borrower.email
        }
      );
    }

    // Check for coborrower name, if not then add it
    if (loan.coborrower && !newApplicants.find(({ name }) => name === `${loan.coborrower.first_name} ${loan.coborrower.last_name}`)) {
      newApplicants.push(
        {
          name: `${loan.coborrower.first_name} ${loan.coborrower.last_name}`,
          email: loan.coborrower.email
        }
      );
    }

    // Check for coapplicant, if exists then loop through co-applicant array
    if (loan.coapplicants.length > 0) {
      loan.coapplicants.forEach((coapp: any) => {
        // Check for each coapplicant, if not then add it.
        if (!newApplicants.find(({ name }) => name === `${coapp.first_name} ${coapp.last_name}`)) {
          newApplicants.push(
            {
              name: `${coapp.first_name} ${coapp.last_name}`,
              email: coapp.email
            }
          );
        }
      })
    }

    setApplicants(applicants => newApplicants)
  };

  // Determine if a loan has been submitted && if the borrower and/or coborrower have completed all of their tasks
  const displayDashComponent: any = (refLoanData: any, borrowerTasks: boolean, coBorrowerTasks: boolean, showCoBorrower: boolean) => {
    if (!refLoanData?.current?.application?.submitted) {
      return (
        <DashItemComponent
          name="Complete Loan Application"
          subtext={`Last Updated: ${lastUpdated}`}
          onClick={() => resumeLoan(refLoanData.current)}
          v2
        />
      )
    } else {
      if (borrowerTasks && !showCoBorrower) {
        return (
          <DashItemComponent
            name={`${getPluralizedName(refLoanData.current.borrower.first_name)} Tasks Completed!`}
            subtext="No further actions required"
            v2
          />
        )
      } else if (coBorrowerTasks && showCoBorrower) {
        return (
          <DashItemComponent
            name={`${getPluralizedName(refLoanData.current.coborrower?.first_name)} Tasks Completed!`}
            subtext="No further actions required"
            v2
          />
        )
      } else {
        return <TaskTab items={incompleteActionItems} v2 />
      }
    }
  }

  const dashboardTasksTabs: Tab[] = [
    {
      menuItem: 'To Do',
      render: () => isLoadingTasks ? (
        <DashItemComponent
          name="Application Submitted"
          subtext="Generating your tasks..."
          v2
          isLoading
        />
      ) : displayDashComponent(refLoanData, borrowerTasksCompletedRef.current, coBorrowerTasksCompletedRef.current, showCoborrowerTasks)
    },
    {
      menuItem: `${tasksCompleted ? 'Completed' : ''}`,
      render: () => (
        <>
        {(refLoanData.current?.application?.submitted) && (
          <DashItemComponent
            name="Completed Loan Application"
            subtext={`Last Updated: ${lastUpdated}`}
            onClick={() => resumeLoan(refLoanData.current)}
            completed
            v2
          />
        )}
          <TaskTab
            items={completeActionItems}
            v2
            isCompletedTab
          />
        </>
      )
    }
  ]

  const ownerFilterLinks: PageHeaderLinkProps[] = refLoanData.current ? [
    {
      // Prevents "0/0" from being displayed on load
      label: totalBorrowerTasks > 0
        ? `${totalCompletedBorrowerTasks}/${totalBorrowerTasks} ${getPluralizedName(refLoanData.current.borrower.first_name)} Tasks`
        : `${getPluralizedName(refLoanData.current.borrower.first_name)} Tasks`,
      onClick: () => setShowCoborrowerTasks(showCoborrowerTasks => false),
      active: !showCoborrowerTasks
    },
    refLoanData.current.application.coborrower_id && {
      // Prevents "0/0" from being displayed on load
      label: totalCoborrowerTasks > 0
        ? `${totalCompletedCoborrowerTasks}/${totalCoborrowerTasks} ${getPluralizedName(refLoanData.current.coborrower?.first_name)} Tasks`
        : `${getPluralizedName(refLoanData.current.coborrower?.first_name)} Tasks`,
      onClick: () => setShowCoborrowerTasks(showCoborrowerTasks => true),
      active: showCoborrowerTasks
    }
  ] : []

  useEffect(() => {
    setShowRejectedModal(showRejectedModal => hasRejectedTasksRef.current && !rejectedModalViewed)
  }, [hasRejectedTasksRef.current, rejectedModalViewed])

  useEffect(() => {
    refLoanData.current = loanData
    refBorrowerTasks.current = borrowerTasks
    refCoborrowerTasks.current = coborrowerTasks


    setTasksCompleted(tasksCompleted => refLoanData.current?.application?.submitted)
    // Set borrowerTasksCompletedRef & coBorrowerTasksCompletedRef current values to true if tasks exist and are all completed
    totalBorrowerTasks > 0 && totalBorrowerTasks === totalCompletedBorrowerTasks ? borrowerTasksCompletedRef.current = true : borrowerTasksCompletedRef.current = false;
    totalCoborrowerTasks > 0 && totalCoborrowerTasks === totalCompletedCoborrowerTasks ? coBorrowerTasksCompletedRef.current = true : coBorrowerTasksCompletedRef.current = false;
  })

  useEffect(() => {
    if (borrowerTasks?.id){
      handleSetDashItems()
    }
  }, [showCoborrowerTasks])

  useEffect(() => {
    loansService.getLoan(activeLoan).then(l => {
      const loan = l?.data?.form_data

      if (loan) {
        setLoanData((loanData: any) => loan)
        setProperty(property =>
          loan.property?.address_street_line !== "" ? loan.property?.address_street_line :
          `${loan.property?.address_city}, ${loan.property?.address_state}`
        )
        setContacts(loan.contacts)
        setBorrowerPairId(loan.borrower.borrower_pair_id);

        const dateTimeOptions: Intl.DateTimeFormatOptions = { month: 'numeric', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' }
        setLastUpdated(Intl.DateTimeFormat('en-US', dateTimeOptions).format(new Date(loan.application.updated_at)))
        setLoanStarted(Intl.DateTimeFormat('en-US', dateTimeOptions).format(new Date(loan.application.created_at)))

        tasksService.getTasks(loan.application.id).then(res => {

          setBorrowerTasks((borrowerTasks: any) => res.data?.primary_borrower)
          // populated rejectedTasksArray with borrower's rejected tasks
          populateRejectedTasksArray(res.data?.primary_borrower)

          if (loan.application.coborrower_id) {
            setCoborrowerTasks((coborrowerTasks: any) => res.data?.coborrower)
            // Populate rejected tasks array with coborrower tasks
            populateRejectedTasksArray(res.data?.coborrower)
          }

          handleSetDashItems()

          if (loan.application?.submitted && refBorrowerTasks.current.length === 0 && !taskInterval.current) {
            setIsLoadingTasks(isLoadingTasks => true)

            // ts-ignore is needed to prevent nodejs related type error
            // @ts-ignore
            taskInterval.current = setInterval(() => {
              if (refBorrowerTasks.current.length > 0) {
                console.log(`${refBorrowerTasks.current.length} Tasks found. Task polling complete.`)

                handleSetDashItems()

                setIsLoadingTasks(isLoadingTasks => false)
                // @ts-ignore
                clearInterval(taskInterval.current)
              } else {
                console.log('polling for new tasks...')

                tasksService.getTasks(loan.application.id).then(res => {
                  setBorrowerTasks((borrowerTasks: any) => res.data?.primary_borrower)
                  if (loan.application.coborrower_id) {
                    setCoborrowerTasks((coborrowerTasks: any) => res.data?.coborrower)
                  }
                })
              }
            }, TASK_POLLING_DELAY)
          }
        })

        if (loan.borrower.request_realtor_referral && !loan.borrower.realtor_exists) {
          setRealtorRequested(true)
        }

        handleSetRealtor()
        handleSetApplicants()
        setRefreshTasks(false)

        setIsLoading(false)
      }
      else {
        clearStaleUser()
      }
    })

    return () => clearInterval(taskInterval.current)
  }, [loansService, authService, activeLoan, showUploadModal, refreshTasks])

  useEffect(() => {
    window.scrollTo(scrollToDefaultOptions)
  }, [])

  return (
    <DataContext.Provider value={dashboardContext}>
      <div className="dashboard-container">
        <LoaderOverlay active={isLoading} label="Loading tasks..." />
        {!isLoading && (
          <>
            <div className="current-loan-label">
              <span className="text-italicize">{property ? property : 'No Address Added'}</span>
              <span className="text-italicize milestone-label">{refLoanData.current.loan.milestone ? '-' : ''}</span>
              <span className="text-italicize milestone-label">{refLoanData.current.loan.milestone.replace(/[^a-zA-Z ]/g, " ")}</span>
            </div>
            <div className="section-container">
              <div className="section-column tasks-container">
                <div className="section-column">
                  <SectionComponent
                    headerLabel="Tasks"
                    tabs={dashboardTasksTabs}
                    links={refLoanData.current.application.coborrower_id ? ownerFilterLinks : undefined}
                    v2
                  />
                </div>
                {/*TODO: Either remove or convert to v2 styles*/}
                {/*<div className="section-column">*/}
                {/*  {realtor.length > 0 && (*/}
                {/*    <>*/}
                {/*      <SectionComponent*/}
                {/*        headerLabel="Realtor Info"*/}
                {/*      />*/}
                {/*      <div className="realtor-container">*/}
                {/*        {realtor.map((realtor: any) => {*/}
                {/*          return (*/}
                {/*            <>*/}
                {/*              <DashItemComponent*/}
                {/*                key={realtor.name}*/}
                {/*                name={`${realtor.name}`}*/}
                {/*                actions={[*/}
                {/*                  {*/}
                {/*                    label: realtor.email,*/}
                {/*                    href: `mailto:${realtor.email}`*/}
                {/*                  },*/}
                {/*                  {*/}
                {/*                    label: realtor.phone_number.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3"),*/}
                {/*                    href: `tel:+${realtor.phone_number}`,*/}
                {/*                    location: 'self'*/}
                {/*                  }*/}
                {/*                ]}*/}
                {/*              />*/}
                {/*              <NavHashLink*/}
                {/*                to="/application/loan-details#realtor-fields"*/}
                {/*              >*/}
                {/*                <Button*/}
                {/*                  className="alternate-button"*/}
                {/*                >*/}
                {/*                  Edit*/}
                {/*                </Button>*/}
                {/*              </NavHashLink>*/}
                {/*            </>*/}
                {/*          )*/}
                {/*        })}*/}
                {/*      </div>*/}
                {/*    </>*/}
                {/*  )}*/}
                {/*  {realtorRequested && (*/}
                {/*    <>*/}
                {/*      <SectionComponent*/}
                {/*        headerLabel="Realtor Info"*/}
                {/*      />*/}
                {/*      <DashItemComponent*/}
                {/*        key="realtor-requested"*/}
                {/*        name="Realtor Assignment - Pending"*/}
                {/*      />*/}
                {/*    </>*/}
                {/*  )}*/}
                {/*</div>*/}
              </div>
            </div>
          </>
        )}
        {showUploadModal && (
          <FileUploadModal
            borrowerPairId={borrowerPairId}
          />
        )}
        {showDownloadModal && (
          <DownloadDocumentsModal
            showModal={showDownloadModal}
            setShowModal={setShowDownloadModal}
            taskId={taskId}
            subHeaderOverride={modalSubheader}
            borrowerPairId={borrowerPairId}
            isDisclosureTask={isDisclosureTask}
          />
        )}
        {showRejectedModal && (
          <RejectedTasksModal
            showModal={showRejectedModal}
            setShowModal={setShowRejectedModal}
            taskId={taskId}
            borrowerPairId={borrowerPairId}
            rejectedTasksArray={rejectedTasksArray}
            setRejectedModalViewed={setRejectedModalViewed}
          />
        )}
        {showDisclosureModal && <DisclosureModal />}
      </div>
    </DataContext.Provider>
  )
})

export default Dashboard
