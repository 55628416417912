import React, {FC, useContext, useEffect, useRef, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {useStore} from '@jmjfinancial/apis/lib';
import {DataContext} from '../../../context/dataContext';
import LoaderOverlay from '../../../components/LoaderOverlay/LoaderOverlay';
import IncomesConcierge from './IncomesConcierge';
import IncomesTableView from './IncomesTableView';
import EditIncomeForm from './EditIncomeForm';
import './employment-and-income.scss';

export type IncomeType =
  'W-2 Wage Earner' |
  'Self Employed' |
  'retirement_account' |
  'social_security' |
  'pension' |
  'annuity' |
  'fund_401k' |
  'ira' |
  'ira_roth' |
  'rental_income' |
  'alimony' |
  'child_support' |
  'other' |
  'None' |
  ''

export enum IncomeCopy {
  'W-2 Wage Earner' = 'W-2 Employment',
  'Self Employed' = 'Self Employment',
  'retirement_account' = 'Retirement',
  'social_security' = 'Social Security',
  'pension' = 'Pension',
  'annuity' = 'Annuity',
  'fund_401k' = '401k',
  'ira' = 'IRA',
  'ira_roth' = 'Roth IRA',
  'rental_income' = 'Rental Income',
  'alimony' = 'Alimony',
  'child_support' = 'Child Support',
  'other' = 'Other Income'
}

const EmploymentAndIncomeV2: FC = observer(() => {
  const store = useStore();
  const { loansService } = store;
  const {activeLoan, setDarkThemeOverride, setOverrideTrackerVisibility} = useContext(DataContext);

  const [isLoading, setIsLoading] = useState(true);
  const [loanData, setLoanData] = useState<any>();
  const [hasIncomes, setHasIncomes] = useState(false);
  // Controls how forms display/handle data
  const [conciergeMode, setConciergeMode] = useState(!hasIncomes);
  // Used as a flag to force soft refreshes of the view
  const [refreshIncomesView, setRefreshIncomesView] = useState(false);
  // Array of income types set in concierge to iterate through
  const [incomeTypes, setIncomeTypes] = useState<IncomeType[]>([])
  // Index controlling the current income type from `incomeTypes`
  const [incomeIndex, setIncomeIndex] = useState<number>(0)
  // Used to show the "Do you want to add another?" after W2/Self Employment
  const [showDuplicateIncomeForm, setShowDuplicateIncomeForm] = useState(false)
  const [hasCoborrower, setHasCoborrower] = useState(false);
  // Forces concierge view if coborrower exists and there are no incomes
  const [coborrowerConcierge, setCoborrowerConcierge] = useState(false)
  const [showEndDate, setShowEndDate] = useState<boolean>(false);
  const refIncomeIndex = useRef<number>(incomeIndex);

  setDarkThemeOverride(true)
  setOverrideTrackerVisibility(true)

  const IncomesView: FC = () => {
    // If incomes exist, show table view
    if (hasIncomes && !coborrowerConcierge) {
      return (

        <div className="application-step-container employment-and-income-step-container">
          <IncomesTableView
            loanData={loanData}
            incomeTypes={incomeTypes}
            setIncomeTypes={setIncomeTypes}
            refIncomeIndex={refIncomeIndex}
            setIncomeIndex={setIncomeIndex}
            showDuplicateIncomeForm={showDuplicateIncomeForm}
            setShowDuplicateIncomeForm={setShowDuplicateIncomeForm}
            setRefreshIncomesView={setRefreshIncomesView}
            conciergeMode={conciergeMode}
            hasCoborrower={hasCoborrower}
            setCoborrowerConcierge={setCoborrowerConcierge}
            setShowEndDate={setShowEndDate}
            showEndDate={showEndDate}
            updateIncomeFormView={() => {}}
          />
        </div>
      )
    }
    // If incomeTypes exist, show form
    else if (incomeTypes.length > 0) {
      return (
        <div className="application-step-container employment-and-income-step-container">
          <EditIncomeForm
            loanData={loanData}
            incomeTypes={incomeTypes}
            setIncomeTypes={setIncomeTypes}
            refIncomeIndex={refIncomeIndex}
            setIncomeIndex={setIncomeIndex}
            showDuplicateIncomeForm={showDuplicateIncomeForm}
            setShowDuplicateIncomeForm={setShowDuplicateIncomeForm}
            setRefreshIncomesView={setRefreshIncomesView}
            conciergeMode={conciergeMode}
            hasCoborrower={hasCoborrower}
            coborrowerConcierge={coborrowerConcierge}
            setCoborrowerConcierge={setCoborrowerConcierge}
            saveForm={() => {}}
            setShowForm={() => {}}
            showEndDate={showEndDate}
            setShowEndDate={setShowEndDate}
          />
        </div>
      )
    }
    // If neither incomes nor incomeTypes exist, start concierge
    else {
      return (
        <div className="employment-and-income-concierge">
          <IncomesConcierge
            loanData={loanData}
            setIncomeTypes={setIncomeTypes}
            coborrowerConcierge={coborrowerConcierge}
          />
        </div>
      )
    }
  }

  useEffect(() => {
    // Updates refIncomeIndex.current to lasted state value of incomeIndex
    refIncomeIndex.current = incomeIndex;
  })

  useEffect(() => {
    setIsLoading(true)
    loansService.getLoan(activeLoan).then(l => {
      const loan = l.data.form_data
      setLoanData(loan)
      setHasIncomes(loan.incomes?.length > 0 || loan.employments?.length > 0)
      setHasCoborrower(!!loan.coborrower)
      setConciergeMode(coborrowerConcierge || (!(loan.incomes?.length > 0) && !(loan.employments?.length > 0)))
      setRefreshIncomesView(false)
      setIsLoading(false)
    })
  }, [loansService, activeLoan, refreshIncomesView])

  return (
    <>
      <LoaderOverlay active={isLoading} />
      {!isLoading && <IncomesView />}
    </>
  )
})

export default EmploymentAndIncomeV2
