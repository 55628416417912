import React, { FC, useContext, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from '@jmjfinancial/apis/lib';
import { DataContext } from '../../../../context/dataContext';
import { Formik } from 'formik';
import IncomeFormHeader from './IncomeFormHeader';
import * as Yup from 'yup';
import _ from 'lodash';
import { Form, Input } from 'semantic-ui-react';
import MaskedInput from 'react-text-mask';
import { currencyMask } from '../../../../helpers/currency-mask-input';
import ApplicationFormFooter from '../../shared/ApplicationFormFooter';
import { IncomeFormProps } from '../EditIncomeForm';

const AlimonyIncomeForm: FC<IncomeFormProps> = observer(({
  loanData,
  onCancelClick,
  updateIncomeFormView,
  owner,
  ownerName,
  incomeType,
  duplicateIncomeType,
  conciergeMode,
  saveForm,
  setShowForm,
  formData
}) => {
  const store = useStore();
  const { loansService } = store;

  const { activeLoan } = useContext(DataContext);

  const [disableOnErrors, setDisableOnErrors] = useState<boolean>(false)
  const [showErrorContainer, setShowErrorContainer] = useState<boolean>(false)

  return (
    <>
      <IncomeFormHeader
        ownerName={ownerName}
        incomeType={incomeType}
        duplicateIncomeType={duplicateIncomeType}
        conciergeMode={conciergeMode}
      />
      <Formik
        initialValues={{
          monthlyIncome: formData?.amount || '',

          submit: null
        }}
        validationSchema={Yup.object().shape({
          monthlyIncome: Yup.string().required('This field is required')
        })}
        onSubmit={async (values, {
          setErrors,
          setStatus,
          setSubmitting,
          resetForm
        }) => {
          try {
            const changedData = {
              owner: owner,
              income_type: 'alimony',
              amount: values.monthlyIncome.replace(/[$,]/g, '')
            }

            if (conciergeMode) {
              loanData.incomes = []
              loanData.employments = []
              let newIncomes = _.concat(loanData.incomes, changedData)
              const dataSubmit = _.merge(loanData, { incomes: newIncomes })
              await loansService.updateLoan(activeLoan, dataSubmit)
              // Clear incomes to prevent duplicates

              updateIncomeFormView()
            }
            else {
              saveForm(changedData)
              setShowForm(false);
            }

            setStatus({ success: true });
            setSubmitting(false);
            resetForm()
          } catch (err: any) {
            console.error('Income source error: ', err.message);
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values
        }) => (
          <form
            onSubmit={handleSubmit}
            className="application-form"
          >
            <div className="form-step">
              <Form.Field>
                <label>Monthly Amount</label>
                <Input
                  error={Boolean(touched.monthlyIncome && errors.monthlyIncome)}
                >
                  <MaskedInput
                    name="monthlyIncome"
                    placeholder="$"
                    type="text"
                    value={values.monthlyIncome}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    mask={currencyMask}
                  />
                </Input>
              </Form.Field>
            </div>
            <ApplicationFormFooter
              onCancelClick={onCancelClick}
              nextDisabled={isSubmitting || disableOnErrors}
              errors={errors}
              showErrorContainer={showErrorContainer}
              setShowErrorContainer={setShowErrorContainer}
              setDisableOnErrors={setDisableOnErrors}
            />
          </form>
        )}
      </Formik>
    </>
  )
})

export default AlimonyIncomeForm
