export const today = new Date()
export const currentYear = today.getFullYear()
export const lastYear = currentYear - 1
export const twoYearsAgo = currentYear - 2
export const currentMonth = today.getMonth()
export const currentDay = today.getDate()

/**
 * Generates an array of text/value objects, used in year selection
 * dropdowns
 * @param maxYear a number value used as the lower limit of options
 */
 export const generateYearOptions = (maxYear: number): Array<any> => {
  let generatedYears: Array<any> = []

  for (let year = currentYear; year >= maxYear; year--) {
    generatedYears.push({ text: year.toString(), value: year })
  }

  return generatedYears
}

export const getMaxBirthdate = () => {
  return new Date(currentYear - 18, currentMonth, currentDay)
}

export const getMinBirthdate = () => {
  return new Date(currentYear - 120, currentMonth, currentDay)
}

export const pastDate = () => {
  return new Date(currentYear, currentMonth, currentDay)
}

export const futureDate = () => {
  return new Date(currentYear, currentMonth, currentDay +1)
}


