export type AssetTypeText =
  'Checking & Savings Account' |
  'Liquidating Stocks and/or Bonds' |
  'Liquidating or borrowing from a retirement account' |
  'Gift' |
  ''

export type AssetType =
  'checkingOrSavings' |
  'stocksOrBonds' |
  'retirement' |
  'gift' |
  'none' |
  ''

export type BasicAssetType = Omit<AssetType, 'none' | ''>

export type SimpleAssetType =
  'estimated_bank_accounts_amount' |
  'estimated_stocks_and_bonds_amount' |
  'estimated_retirement_accounts_amount' |
  'estimated_gifts_amount'

export enum AssetTypeLabelEnum {
  'checkingOrSavings'= 'Checking & Savings Accounts',
  'stocksOrBonds' = 'Stocks and/or Bonds',
  'retirement' = 'Retirement Accounts',
  'gift' = 'Gifts'
}

export enum AssetTypeEnum {
  'estimated_bank_accounts_amount' = 'checkingOrSavings',
  'estimated_stocks_and_bonds_amount' = 'stocksOrBonds',
  'estimated_retirement_accounts_amount' = 'retirement',
  'estimated_gifts_amount' = 'gift'
}

export enum AssetTypeEnumReverse {
  'checkingOrSavings'= 'estimated_bank_accounts_amount',
  'stocksOrBonds' = 'estimated_stocks_and_bonds_amount',
  'retirement' = 'estimated_retirement_accounts_amount',
  'gift' ='estimated_gifts_amount'
}
