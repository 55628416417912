import React, { FC, useContext, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { DataContext } from '../../../context/dataContext';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Button, Form } from 'semantic-ui-react';
import { scrollToDefaultOptions } from '../../../helpers/scroll-to-options';

interface AssetsWorksheetConciergeProps {
  loanData: any,
  setShowLandingPage: Function
}

const AssetsWorksheetConcierge: FC<AssetsWorksheetConciergeProps> = observer(({
  loanData,
  setShowLandingPage
}) => {
  const { setDarkThemeOverride } = useContext(DataContext);

  setDarkThemeOverride(true)

  useEffect(() => {
    window.scrollTo(scrollToDefaultOptions)
  }, [])

  return (
    <Formik
      enableReinitialize
      initialValues={{
        submit: null
      }}
      validationSchema={Yup.object().shape({})}
      onSubmit={(values, {
        setErrors,
        setStatus,
        setSubmitting,
        resetForm
      }) => {
        try {
          setStatus({ success: true })
          setSubmitting(false)
          resetForm({})
          setShowLandingPage(false)
        } catch (err: any) {
          console.error('AssetsWorksheetConcierge error: ', err.message);
          setStatus({ success: false })
          setErrors({ submit: err.message })
          setSubmitting(false)
        }
      }}
    >
      {({
        handleSubmit,
        isSubmitting
      }) => (
        <form
          className="choice-form application-step-container assets-form asset-worksheet-concierge"
          onSubmit={handleSubmit}
        >
          <div className="question-container">
            <div className="form-step">
              <Form.Field>
                <h1 className="title assets concierge">Next, to create an accurate list of asset documentation, we'll need to gather more details.</h1>
              </Form.Field>
            </div>
            <Button
              disabled={isSubmitting}
              className="concierge-button"
              type="submit"
            >
              Continue
            </Button>
          </div>
        </form>
      )}
    </Formik>
  )
})

export default AssetsWorksheetConcierge
