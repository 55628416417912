import { FC } from 'react';
import './index.scss'
export interface PageFooterProps {
  darkTheme: boolean;
}

const linkData = [
  {
    text: 'COMPANY STATE LICENSES',
    source: "https://web.jmj.me/CompanyStateLicenses.html",
  },
  {
    text: 'PRIVACY POLICY',
    source: "https://web.jmj.me/PrivacyPolicy.html",
  },
  {
    text: 'TERMS OF USE',
    source: "https://web.jmj.me/TermsofUse.html",
  }
]

const PageFooter: FC<PageFooterProps> = ({
  darkTheme
}) => {
  return (
    <footer className={`page-footer${darkTheme ? ' dark' : ''}`}>
      <div className="content-container">
        <div className="links-container">
          {linkData.map((link: any, index: number) => {
            return (
              <a
                key={`${link.text}-${index}`}
                href={link.source}
                target='_blank'
                rel='noopener'
              >
                {link.text}
              </a>
            )
          })}
        </div>
        <div className="licensing-container">
          <div className="info">
            <span>JMJ Financial Group 	&#169; 2021 All Rights Reserved</span>
            <span className="footer-divider"> | </span>
            <span>NMLS # 167867</span>
          </div>
          <div className="info">
            <a
              href="http://maps.google.com/?q=26800 Aliso Viejo Pkwy, Suite 200, Aliso Viejo, CA 92656"
              target="_blank"
            >
              <span>26800 Aliso Viejo Pkwy, Suite 200</span>
              <span className="footer-divider"> | </span>
              <span className="address">Aliso Viejo, CA 92656</span>
            </a>
          </div>
        </div>
        <div className="licensing-container">
          <div className="info">
            <span className="licensing-message">Licensed by the Department of Business Oversight under the California Residential Mortgage Lending Act,
            Licensee Number 41DBO-46011
            </span>
            <span className="footer-divider"> | </span>
            <span>California Residential Mortgage Lending Act DBO 603F427</span>
          </div>
          <div className="info contact-info">
            <a href="tel:+19493406336">949.340.6336</a>
            <span className="footer-divider"> | </span>
            <a href="mailto: info@jmj.me">info@jmj.me</a>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default PageFooter;
