import React, {FC, useContext, useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {useStore} from '@jmjfinancial/apis/lib';
import {DataContext} from '../../../context/dataContext';
import LoaderOverlay from '../../../components/LoaderOverlay/LoaderOverlay';
import AssetsWorksheetConcierge from './AssetsWorksheetConcierge';
import EditAssetWorksheetForm from './EditAssetWorksheetForm';
import './assets-worksheet.scss';
import AssetsTableView from './AssetsTableView';

const AssetsWorksheet: FC = observer(() => {
  const store = useStore();
  const { loansService } = store;
  const { activeLoan, setDarkThemeOverride, clearStaleUser } = useContext(DataContext);

  const [isLoading, setIsLoading] = useState(true);
  const [loanData, setLoanData] = useState<any>();
  const [loanHasAssets, setLoanHasAssets] = useState(false);
  // Controls how forms display/handle data
  const [conciergeMode, setConciergeMode] = useState(!loanHasAssets);
  const [showLandingPage, setShowLandingPage] = useState(true)
  // Used as a flag to force soft refreshes of the view
  const [refreshAssetsView, setRefreshAssetsView] = useState(false)

  setDarkThemeOverride(true)

  const AssetsWorksheetView: FC = () => {
    // If assets exist, show table view
    // Set loanHasAssets if the array is full or data in borrower model
    if (loanHasAssets) {
      return (
        <AssetsTableView
          loanData={loanData}
          setRefreshAssetsView={setRefreshAssetsView}
        />
      )
    }
    // If assets exist, show form
    else if (!showLandingPage) {
      return (
        <div className="application-step-container assets-step-container">
          <EditAssetWorksheetForm
            loanData={loanData}
            setRefreshAssetsView={setRefreshAssetsView}
            conciergeMode={conciergeMode}
            setShowForm={() => { }}
            saveForm={() => { }}
          />
        </div>
      )
    }
    // If neither assets nor assetTypes exist, start concierge
    else {
      return (
        <div className="assets-concierge">
          <AssetsWorksheetConcierge
            loanData={loanData}
            setShowLandingPage={setShowLandingPage}
          />
        </div>
      )
    }
  }

  useEffect(() => {
    console.log('Assets Worksheet index useEffect()')
    setIsLoading(true)
    loansService.getLoan(activeLoan).then(l => {
      const loan = l?.data?.form_data

      if (loan) {
        setLoanData((loanData: any) => loan)
      }
      else {
        clearStaleUser()
      }

      setLoanHasAssets(loanHasAssets => loan.assets?.length > 0)
      setConciergeMode(conciergeMode => !(loan.assets?.length > 0))

      setShowLandingPage(showLandingPage => !(loan.assets?.length > 0))

      setRefreshAssetsView(refreshAssetsView => false)
      setIsLoading(isLoading => false)
    })
  }, [loansService, activeLoan, refreshAssetsView])

  return (
    <>
      <LoaderOverlay active={isLoading} />
      {!isLoading && (
        // TODO: Replace class name with worksheet specific
        <div className="loan-application-page">
          <AssetsWorksheetView />
        </div>
      )}
    </>
  )
})

export default AssetsWorksheet
