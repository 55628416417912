import React, { FC, useState } from 'react';
import { Form, Input } from 'semantic-ui-react';
import usStates from '../../data/us-states';
import FormikDropdown from '../Formik/FormikDropdown';
import { FormikErrors, FormikTouched } from 'formik';
import FormikMaskedInput from '../Formik/FormikMaskedInput';
import FormikErrorMessage from '../Formik/ErrorMessage';
import {POSTAL_CODE_MASK} from '../../helpers/regex-helper';

interface AddressInputProps {
  handleBlur: Function
  handleChange: any
  touched: FormikTouched<any>
  errors: FormikErrors<any>
  label?: string
  showLabel?: boolean
  street?: string
  unit?: string
  city?: string
  state?: string
  postal?: string
  showErrorContainer?: boolean
  darkTheme?: boolean
  disabled?: boolean
  disableFormikError?: boolean
  // TODO: Update setFieldValue type to Formik type value
  setFieldValue: Function
  values?: any
  companyAddress?: boolean
  paymentStyle?: boolean
  autoFillOverride?: boolean
}

const AddressInputSection0: FC<AddressInputProps> = ({
  handleBlur,
  handleChange,
  touched,
  errors,
  label = 'Address Details',
  showLabel = true,
  street = 'street',
  unit = 'unit',
  city = 'city',
  state = 'state',
  postal = 'postal',
  showErrorContainer,
  darkTheme,
  disabled,
  disableFormikError,
  setFieldValue,
  values,
  companyAddress = false,
  paymentStyle = false,
  autoFillOverride
}) => {
  const [streetFill, setStreetFill] = useState<string>('')
  const [cityFill, setCityFill] = useState<string>('')
  const [stateFill, setStateFill] = useState<string>('')
  const [postalFill, setPostalFill] = useState<string>('')
  const [unitFill, setUnitFill] = useState<string>('')
  const [autofill, setAutofill] = useState<boolean>(false)

  let autocomplete: google.maps.places.Autocomplete;
  let streetAddress: HTMLInputElement
  let unitInput: HTMLInputElement

  let streetNum: string = ''
  let streetName: string = ''

  const ENTER_KEY = 13

  const initAutocomplete = () => {
    setAutofill(autofill => false)
    streetAddress = document.querySelector('#street-address') as HTMLInputElement;
    unitInput = document.querySelector('#unit-field') as HTMLInputElement;

    autocomplete = new google.maps.places.Autocomplete(streetAddress, {
      componentRestrictions: { country: ['us'] },
      fields: ['address_components', 'geometry'],
      types: companyAddress ? [] : ['address'],
    });

    let input: any = document.querySelector('#street-address');
    google.maps.event.addDomListener(input, 'keydown', (event: {
      keyCode: number;
      preventDefault: () => void;
    }) => event.keyCode === ENTER_KEY ? event?.preventDefault() : null)

    autocomplete.addListener('place_changed', fillInAddress);
  }

  const fillInAddress = () => {
    // setting autofill to true so the form fields display the useState values and not the Formik values
    // but ONLY when the user clicks on an autofill selection
    setAutofill(autofill => true)

    const place = autocomplete.getPlace().address_components || "";

    for (let component of place as google.maps.GeocoderAddressComponent[]) {
      const componentType = component.types[0]

      switch (componentType) {
        case 'street_number': {
          streetNum = component.short_name
          break
        }
        case 'route': {
          streetName = component.short_name
          break
        }
        case 'locality': {
          setFieldValue('city', component.short_name)
          setCityFill(cityFill => component.short_name)
          break
        }
        case 'administrative_area_level_1': {
          setFieldValue('state', component.short_name)
          setStateFill(stateFill => component.short_name)
          break
        }
        case 'administrative_area_level_2': {
          setFieldValue('county', component.short_name)
          break
        }
        case 'postal_code': {
          setFieldValue('postal', component.short_name)
          setPostalFill(postalFill => component.short_name)
          break
        }
        case 'subpremise': {
          setFieldValue('unit', component.short_name)
          setUnitFill(unitFill => component.short_name)
          break
        }
      }
    }

    // sets the Formik values to the autofilled values
    setFieldValue('street', `${streetNum} ${streetName}`)

    // Sets useState values so the UI is updates and reflects the autofilled values
    setStreetFill(streetFill => `${streetNum} ${streetName}`)

    // Focuses on unitInput (set to the #unit-field field id above) after
    // an autocomplete selection is selected, encouraging user to enter Unit if applicable
    unitInput.focus()
  }

  const streetAddressUnitFields = () => {
    return (
      <>
        <Input
          id="street-address"
          className={`
            residence-input
            ${darkTheme && (!!(touched.street && errors.street)
              || !!(showErrorContainer && values.street === ''))
              && 'dark-theme-error'}
            ${paymentStyle && 'payment-field'}
          `}
          disabled={disabled}
          name={street}
          placeholder="Address"
          type="text"
          value={autofill && !autoFillOverride ? streetFill : values.street}
          onBlur={handleBlur}
          onChange={(e) => {
            handleChange(e)
            initAutocomplete()
          }}
          error={!!(touched.street && errors.street) || !!(showErrorContainer && values.street === '')}
        />
        <Input
          id="unit-field"
          className={`
            unit-input
            ${paymentStyle && 'payment-field'}
          `}
          disabled={disabled}
          name={unit}
          placeholder="Apt/Unit"
          type="text"
          value={autofill && !autoFillOverride ? unitFill : values.unit}
          onBlur={handleBlur}
          onChange={(e) => {
            handleChange(e)
            initAutocomplete()
          }}
          error={!!(touched.unit && errors.unit)}
        />
      </>
    )
  }

  const cityStatePostalFields = () => {
    return (
      <>
        <Input
          className={`
            city-input
            ${darkTheme && (!!(touched.city && errors.city)
              || !!(showErrorContainer && values.city === ''))
              && 'dark-theme-error'}
            ${paymentStyle && 'payment-field'}
          `}
          disabled={disabled}
          name={city}
          placeholder="City"
          type="text"
          value={autofill && !autoFillOverride ? cityFill : values.city}
          onBlur={handleBlur}
          onChange={(e) => {
            handleChange(e)
            initAutocomplete()
          }}
          error={!!(touched.city && errors.city) || !!(showErrorContainer && values.city === '')}
        />
        <FormikDropdown
          className={`
            state-input
            ${darkTheme && (!!(touched.state && errors.state)
              || !!(showErrorContainer && values.state === ''))
              && 'dark-theme-error'}
            ${paymentStyle && 'payment-field'}
          `}
          disabled={disabled}
          fluid
          search
          selection
          clearable
          name={state}
          placeholder="State"
          value={autofill && !autoFillOverride ? stateFill : values.state}
          onChange={(e: any) => {
            handleChange(e)
            initAutocomplete()
          }}
          options={usStates}
          error={!!(showErrorContainer && values.state === '')}
        />
        <FormikMaskedInput
          className={`
            small
            zip-code-input
            ${darkTheme && (!!(touched.postal && errors.postal)
              || !!(showErrorContainer && values.postal === ''))
              && 'dark-theme-error'}
            ${paymentStyle && 'payment-field'}
          `}
          disabled={disabled}
          name={postal}
          placeholder="Zip Code"
          type="text"
          value={autofill && !autoFillOverride ? postalFill : values.postal}
          onBlur={handleBlur}
          onChange={(e: any) => {
            handleChange(e)
            initAutocomplete()
          }}
          mask={POSTAL_CODE_MASK}
          guide={false}
          error={!!(touched.postal && errors.postal) || !!(showErrorContainer && (values.postal === '' || errors.postal))}
        />
        {disableFormikError ? <></> : <FormikErrorMessage name="postal" />}
      </>
    )
  }

  return paymentStyle ? (
    <div className="payment-field-container">
      <div className="payment-row">
        {streetAddressUnitFields()}
      </div>
      <div className="payment-row">
        {cityStatePostalFields()}
      </div>
    </div>
  ) : (
    <div className="address-container">
      <div className="form-step address-top-row">
        <Form.Field>
          {showLabel && <label>{label}</label>}
          <div className="address-fields">
            {streetAddressUnitFields()}
          </div>
        </Form.Field>
      </div>
      <div className="form-step">
        <Form.Field className="bottom">
          <div className="address-fields">
            {cityStatePostalFields()}
          </div>
        </Form.Field>
      </div>
    </div>
  )
}

export default AddressInputSection0;
