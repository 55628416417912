import {FC} from 'react';
import {Progress, Transition} from 'semantic-ui-react';
import './progress-overlay.scss'

interface ProgressOverlayProps {
  active: boolean
  percent: number
  label?: string
  top?: number
}

const ProgressOverlay: FC<ProgressOverlayProps> = ({
  active,
  percent,
  label,
  top = 0
}) => {
  return (
    <Transition
      animation="fade"
      duration={300}
      visible={active}
    >
      <div
        className="progress-overlay-container"
        style={{top: `${top}px`}}
      >
        <Progress
          className="progress-overlay"
          percent={percent}
          progress
          active
        >
          {label && label}
        </Progress>
      </div>
    </Transition>
  )
}

export default ProgressOverlay
