import React, { FC, useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import { DataContext } from '../../../context/dataContext';
import { observer } from 'mobx-react-lite';
import LoaderOverlay from '../../../components/LoaderOverlay/LoaderOverlay';
import { useStore } from '@jmjfinancial/apis/lib';
import './review-and-submit.scss'
import { conformToMask } from 'react-text-mask';
import { currencyMask } from '../../../helpers/currency-mask-input';
import SummarySection from './SummarySection';
import SummaryTable from './SummaryTable';
import { TableRow } from '../../../components/AddRemoveTable/AddRemoveTable';
import _ from 'lodash';
import {scrollToDefaultOptions} from '../../../helpers/scroll-to-options';
import {LoanType} from '../index';
import {simpleAssets} from '../Assets';
import {AssetTypeEnum} from '../Assets/asset-types';

interface SectionDetail {
  label: string;
  value: string | number | Date | null;
}

export interface SectionTable {
  headerLabels: string[];
  tableData: Array<TableRow>;
}

export interface ReviewSection {
  title: string;
  sectionDetails?: SectionDetail[];
  sectionTable?: SectionTable;
  route?: string;
  subSection?: ReviewSection;
}

interface Asset {
  basic_asset_type: string
  amount: string
}

const ReviwAndSubmit: FC = observer(() => {
  const store = useStore();
  const history = useHistory();
  const { loansService } = store;

  const {
    activeLoan,
    setReviewMode,
    coapplicantMode,
  } = useContext(DataContext)
  const [loanData, setLoanData] = useState<any>()
  const [loaderLabel, setLoaderLabel] = useState<any>('Loading...')
  const [isLoading, setIsLoading] = useState(true)
  const [errorMessage, setErrorMessage] = useState<string>()
  const [applicationSections, setApplicationSections] = useState<ReviewSection[]>([]);

  const setSimpleAssetsTable = (loanData: any): Array<Asset | undefined> => {
    return simpleAssets.map(key => {
      // check if the key exists in loanData.borrower
      if (key in loanData.borrower) {
        return {
          basic_asset_type: AssetTypeEnum[key as keyof typeof AssetTypeEnum],
          amount: loanData.borrower[key]
        }
      }
    }).filter(value => value !== undefined)
  }

  const setWorksheetAssetsTableSection = (loanData: any): SectionTable => {
    let headerLabels = ['Owner', 'Institution', 'Type', 'Number', 'Value']
    let columnKeys = ['owner_display_name', 'institution', 'asset_type', 'account_number', 'total_value']
    // Returns only the data whose key name matches one of the strings in the array
    let tableData = loanData.assets?.map((employment: any) => _.pick(employment, columnKeys))

    const assetsHasKey = loanData.assets.find((row: any) => row['other_asset_type'])

    if (assetsHasKey) {
      headerLabels.splice(3, 0, 'Other Type')

      tableData = loanData.assets?.map((asset: any) => ({
        owner_display_name: asset.owner_display_name,
        institution: asset.institution,
        asset_type: asset.asset_type,
        other_asset_type: asset.other_asset_type || '-',
        account_number: asset.account_number,
        total_value: asset.total_value
      }))
    }

    return ({
      headerLabels: headerLabels,
      tableData: tableData
    })
  }

  const handleSubmit = async () => {
    setErrorMessage('')
    setLoaderLabel('Submitting application...')
    setIsLoading(true)

    const changedData = {
      application: {
        completed_steps: {
          review_and_submit_done: true
        }
      },
    }

    const dataSubmit = _.merge(loanData, changedData)
    loansService.updateLoan(activeLoan, dataSubmit, true).then(res => {

      setIsLoading(false)
      // @ts-ignore
      if (res.success) {
        setReviewMode(false)
        history.push('/dashboard')
      }
      else {
        setErrorMessage('There was a problem submitting your loan. Please try again.')
      }
    })
  }

  const getCurrencyConversion = (value: any): string | null => {
    return value ? conformToMask(value, currencyMask).conformedValue : null
  }

  const getSSNConversion = (value: any): string | null => {
    return value ? conformToMask(value, [/[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/,]).conformedValue : null
  }

  const getCitizenshipLabel = (value: string): string => {
    switch (value) {
      case 'usCitizen':
        return 'U.S. Citizen'
      case 'permanentAlien':
        return 'Permanent Resident Alien'
      case 'nonPermanentAlien':
        return 'Foreign National'
      default:
        return ''
    }
  }

  const handleFormatApplicationData = (loanData: any) => {
    const formattedApplicationSections: ReviewSection[] = [
      {
        title: 'Loan Details',
        sectionDetails: [
          {
            label: 'Loan Type',
            value: loanData.loan?.loan_purpose_type
          },
          {
            label: 'Loan Amount',
            value: getCurrencyConversion(loanData.loan?.base_loan_amount)
          },
          {
            label: 'Down Payment',
            value: getCurrencyConversion(loanData.loan?.down_payment_amount)
          },
          {
            label: 'Percentage',
            value: loanData.loan?.down_payment_percent ? `${loanData.loan?.down_payment_percent}%` : ''
          },
          {
            label: 'Property Value',
            value: getCurrencyConversion(loanData.property?.purchase_price_amount)
          },
          {
            label: 'Outstanding Loans',
            // TODO: Add value
            value: ''
          },
          {
            label: 'Annual Taxes',
            // TODO: Add Value
            value: ''
          },
          {
            label: 'Working w/ Realtor',
            value: loanData.realtor?.first_name ? 'Yes' : 'No'
          }
        ],
        route: loanData?.loan.loan_purpose_type === LoanType.refinance ? undefined : '/application/loan-details',
      },
      {
        title: 'Property',
        sectionDetails: [
          {
            label: 'Property Address',
            value: loanData.property.address_unit ? `${loanData.property.address_street_line}, Unit ${loanData.property.address_unit}` : loanData.property.address_street_line || ''
          },
          {
            label: 'City',
            value: loanData.property?.address_city
          },
          {
            label: 'State',
            value: loanData.property?.address_state
          },
          {
            label: 'Zip',
            value: loanData.property?.address_postal
          }
        ],
        route: '/application/reo',
      },
      {
        title: 'Borrower Info',
        sectionDetails: [
          {
            label: 'Name',
            value: `${loanData.borrower.first_name} ${loanData.borrower.last_name}`
          },
          {
            label: 'SSN',
            value: getSSNConversion(loanData.borrower.ssn)
          },
          {
            label: 'Date of Birth',
            value: loanData.borrower.date_of_birth ? new Date(loanData.borrower.date_of_birth).toLocaleDateString('en-US', {timeZone: 'UTC'}) : ''
          },
          {
            label: 'Citizenship',
            value: getCitizenshipLabel(loanData.borrower.citizenship)
          },
          {
            label: 'Loan Milestone',
            value: loanData.application.state
          },
          {
            // TODO: Add real label/value
            label: 'Term',
            value: ''
          },
          {
            label: 'Down Payment',
            value: getCurrencyConversion(loanData.loan?.down_payment_amount)
          }
        ],
        route: '/application/borrower-info',
        subSection: loanData.coborrower && {
          title: 'Co-Borrower Info',
          sectionDetails: [
            {
              label: 'Name',
              value: `${loanData.coborrower?.first_name} ${loanData.coborrower?.last_name}`
            },
            {
              label: 'SSN',
              value: getSSNConversion(loanData.coborrower?.ssn)
            },
            {
              label: 'Date of Birth',
              value: loanData.coborrower?.date_of_birth ? new Date(loanData.coborrower?.date_of_birth).toLocaleDateString('en-US', {timeZone: 'UTC'}) : ''
            },
            {
              label: 'Citizenship',
              value: getCitizenshipLabel(loanData.coborrower?.citizenship)
            },
            {
              label: 'Loan Milestone',
              value: loanData.application.state
            },
            {
              // TODO: Add value
              label: 'Term',
              value: ''
            },
            {
              label: 'Down Payment',
              value: getCurrencyConversion(loanData.loan?.down_payment_amount)
            }
          ],
          route: '/application/co-borrower-info',
        }
      },
      {
        title: 'Declarations',
        sectionDetails: [
          {
            label: 'Occupy as Primary',
            value: loanData.borrower.intent_to_occupy_indicator
              ? 'Yes'
              : loanData.borrower.intent_to_occupy_indicator === false
                ? 'No'
                : ''
          },
          {
            label: 'Outstanding Judgements',
            value: loanData.borrower.outstanding_judgements_indicator
              ? 'Yes'
              : loanData.borrower.outstanding_judgements_indicator === false
                ? 'No'
                : ''
          },
          {
            label: 'Delinquent Federal Debt',
            value: loanData.borrower.presently_delinquent_indicator
              ? 'Yes'
              : loanData.borrower.presently_delinquent_indicator === false
                ? 'No'
                : ''
          }
        ],
        route: '/application/declarations',
      },
      {
        title: 'Employment & Income',
        sectionTable: {
          // TODO: Replace empty string with style fix
          headerLabels: loanData.employments.length === 0
            ? ['Owner', 'Type', 'Monthly Gross Income', '', '']
            : ['Owner', 'Type', 'Monthly Gross Income', 'Company Name', 'Current Employment'],
          tableData: [
            ...loanData.incomes?.map((income: any) => {
              // Returns only the data whose key name matches one of the strings in the array
              const pickedValues = _.pick(income, ['owner', 'income_type', 'amount', '', '']) // output: {owner: <income.owner>, income_type: <income.income_type>, amount: <income.amount>}
              // If there are no employments, we return the pickedValues object from above.
              // If there are employments, then we're adding employer_name and currently_employed to the object. But these two static keys can really be anything.
              return loanData.employments.length === 0 ? pickedValues : {...pickedValues, employer_name: '-', currently_employed: '-'}
            }),
            ...loanData.employments?.map((employment: any) => (
              // Returns only the data whose key name matches one of the strings in the array
              _.pick(employment, ['owner', 'income_type', 'amount', 'employer_name', 'currently_employed'])
            ))
          ]
        },
        route: '/application/employment-and-income',
      },
      (loanData.assets.length > 0 ? ({
        title: 'Worksheet Assets',
        sectionTable: setWorksheetAssetsTableSection(loanData),
        route: '/worksheet/assets'
      }) : ({
        title: 'Assets',
        sectionTable: {
          headerLabels: ['Account Type', 'Estimated Balance'],
          tableData: setSimpleAssetsTable(loanData)
        },
        route: '/application/assets',
      })),
      {
        title: 'Real Estate Owned',
        sectionTable: {
          headerLabels: ['Property Address', 'City', 'State', 'Property Type'],
          tableData: [
            ...loanData.reo?.map((reo: any) => (
              // Returns only the data whose key name matches one of the strings in the array
              _.pick(reo, ['address_street_line', 'address_city', 'address_state', 'gse_property_type'])
            )),
          ]
        },
        route: '/application/reo'
      }
    ]

    setApplicationSections(formattedApplicationSections)
  }

  useEffect(() => {
    setReviewMode(true)

    loansService.getLoan(activeLoan)
      .then(loan => {
        setLoanData(loan?.data?.form_data)
        handleFormatApplicationData(loan?.data?.form_data)
        setIsLoading(false);
      })
  }, [activeLoan, loansService])

  useEffect(() => {
    window.scrollTo(scrollToDefaultOptions)
  }, [])

  return (
    <div className="application-step-container review-and-submit-container">
      <LoaderOverlay active={isLoading} label={loaderLabel} fixed />
      {!isLoading && (
        <>
          <h1 className="title text-light-blue">Review &#38; Submit</h1>
          <h2 className="subtitle text-grey">
            Ready to submit the loan application? Review your information
            below. You can make edits or simply hit submit to finish the process!
          </h2>
          <div className="review-section-container">
            {applicationSections.map(section => {
              if (section.sectionDetails) {
                return (
                  <SummarySection
                    key={section.title}
                    section={section}
                    coapplicantMode={coapplicantMode}
                  />
                )
              }
              else if (section.sectionTable) {
                return (
                  <SummaryTable
                    key={section.title}
                    loanData={loanData}
                    title={section.title}
                    sectionTable={section.sectionTable}
                    route={section.route}
                  />
                )
              }
            })}
          </div>
          <div className="review-footer">
            {errorMessage && (
              <div className="error-container">
                <span>{errorMessage}</span>
              </div>
            )}
            <Button
              disabled={isLoading}
              color="blue"
              className="submit-button"
              onClick={() => handleSubmit()}
            >
              {loanData?.application?.submitted ? 'Resubmit' : 'Submit'}
            </Button>
          </div>
        </>
      )}
    </div>
  )
})

export default ReviwAndSubmit
