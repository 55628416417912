import {lastYear, twoYearsAgo} from '../helpers/date-helpers';

export interface YearOption {
  text?: string
  value: string
  checked?: boolean
}

export const taxReturnOptions: YearOption[] = [
  {
    text: `${lastYear}`,
    value: `${lastYear}`,
    checked: false
  },
  {
    text: `${twoYearsAgo}`,
    value: `${twoYearsAgo}`,
    checked: false
  },
]
