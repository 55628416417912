import { Button, Form, Icon, Input, Radio } from 'semantic-ui-react';
import FormikRadio from '../../../components/Formik/FormikRadio';
import FormikErrorMessage from '../../../components/Formik/ErrorMessage';
import {
  Field,
  FieldArray,
  FormikErrors,
  FormikHandlers,
  FormikTouched,
  FormikValues,
  getIn
} from 'formik';
import React, {FC} from 'react';
import {ArrayHelpers} from 'formik/dist/FieldArray';

interface DependentFieldsProps {
  values: FormikValues
  touched: FormikTouched<any>
  errors: FormikErrors<any>
  handleBlur: FormikHandlers['handleBlur']
  handleChange: FormikHandlers['handleChange']
  setFieldValue: any
  showErrorContainer: boolean
  hasCoborrower?: boolean
  coborrowerName?: string
}

const DependentFields: FC<DependentFieldsProps> = ({
  values,
  touched,
  errors,
  handleBlur,
  handleChange,
  setFieldValue,
  showErrorContainer,
  hasCoborrower,
  coborrowerName,
}) => {
  const handleSetHasDependents = (arrayHelpers: ArrayHelpers) => {
    if (values.dependents && values.dependents.length === 0) {
      arrayHelpers.push('')
      setFieldValue('dependentCount', 1)
    }
  }

  const handleSetNoDependents = (arrayHelpers: ArrayHelpers) => {
    handleRemoveMultipleDependents(0, arrayHelpers)
    setFieldValue('dependentCount', 0)
  }

  const handleUpdateDependentCount = (
    arrayHelpers: ArrayHelpers,
    props: FormikValues
  ) => {
    const numberValue = props.value !== '' ? parseInt(props.value) : props.value

    setFieldValue('dependentCount', numberValue)

    if (numberValue <= 20) {
      if (numberValue === 0) {
        handleRemoveMultipleDependents(0, arrayHelpers)
        setFieldValue('hasDependents', 'false')
      }

      if (numberValue > values.dependents.length) {
        for (let i = numberValue; i > values.dependents.length; i--) {
          arrayHelpers.push('')
        }
      }

      if (numberValue < values.dependents.length) {
        handleRemoveMultipleDependents(numberValue, arrayHelpers)
      }
    }
  }

  const handleAddDependent = (arrayHelpers: ArrayHelpers) => {
    setFieldValue('dependentCount', values.dependents.length + 1)

    if (values.dependents.length < 20) {
      arrayHelpers.push('')
    }
  }

  const handleRemoveDependent = (
    index: number,
    arrayHelpers: ArrayHelpers,
    values: FormikValues
  ) => {
    if (values.dependents.length === 1) {
      arrayHelpers.pop()
      setFieldValue('hasDependents', 'false')
      setFieldValue('dependentCount', 0)
    }
    else {
      arrayHelpers.remove(index)
      setFieldValue('dependentCount', values.dependents.length - 1)
    }
  }

  const handleRemoveMultipleDependents = (targetTotalDependents: number, arrayHelpers: ArrayHelpers) => {
    for (let i = values.dependents.length; i > targetTotalDependents; i--) {
      arrayHelpers.pop()
    }
  }

  return (
    <FieldArray
      name="dependents"
      render={arrayHelpers => (
        <div className="form-step">
          <Form.Field>
            <label>{hasCoborrower ? 'Does your spouse' : 'Do you'} have any dependents?</label>
            <div className={`radio-group ${showErrorContainer && values.hasDependents === '' && 'radio-error'}`}>
              <Radio
                id="hasDependentsTrue"
                type="radio"
                label="Yes"
                name="hasDependents"
                value="true"
                checked={values.hasDependents === "true"}
                className={errors.hasDependents && 'has-error'}
                onClick={() => handleSetHasDependents(arrayHelpers)}
                onChange={handleChange}
              />
              <Radio
                id="hasDependentsFalse"
                type="radio"
                label="No"
                name="hasDependents"
                value="false"
                checked={values.hasDependents === "false"}
                className={errors.hasDependents && 'has-error'}
                onClick={() => handleSetNoDependents(arrayHelpers)}
                onChange={handleChange}
              />
              <FormikErrorMessage name="hasDependents" />
            </div>
          </Form.Field>
          {values.hasDependents === 'true' && values.dependentCount !== 0 && (
            <>
              <Form.Field className="dependent-count-field">
                <label>How many dependents {hasCoborrower ? `does ${coborrowerName}` : 'do you'} have?</label>
                <Input
                  name="dependentCount"
                  placeholder="Maximum of 20"
                  type="number"
                  className="small"
                  value={values.dependentCount}
                  onBlur={handleBlur}
                  onChange={(e: any, props: FormikValues) => handleUpdateDependentCount(arrayHelpers, props)}
                  error={!!errors.dependentCount}
                />
                <FormikErrorMessage name="dependentCount" />
              </Form.Field>
              {values.dependents.length > 0 && values.dependents.map((dependent: any, index: number) => (
                <Form.Field
                  key={index}
                  className="nested dependent-field"
                >
                  <div className="dependent-field-actions">
                    <Button
                      type="button"
                      basic
                      icon
                      className="remove-button borderless no-padding"
                      onClick={() => handleRemoveDependent(index, arrayHelpers, values)}
                    >
                      <Icon
                        size="large"
                        name="minus circle"
                        className="grey"
                      />
                    </Button>
                    <Input
                      className="small dependent-age"
                      name={`dependents[${index}]`}
                      placeholder={`Dependent ${index + 1} Age`}
                      type="number"
                      value={values.dependents[index]}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={
                        !!(getIn(touched, `dependents[${index}]`) && getIn(errors, `dependents[${index}]`))
                        || (showErrorContainer && values.dependents[index] === '')}
                    />
                  </div>
                  <FormikErrorMessage
                    name={`dependents[${index}]`}
                  />
                </Form.Field>
              ))}
              <Button
                type="button"
                basic
                compact
                labelPosition="right"
                className="dependent-button"
                onClick={() => handleAddDependent(arrayHelpers)}
                disabled={values.dependentCount >= 20}
              >
                <Icon
                  size="large"
                  name="plus circle"
                />
                {values.dependentCount >= 20 ? 'Max Dependents Reached' : 'Add Another Dependent'}
              </Button>
            </>
          )}
        </div>
      )}
    />
  )
}

export default DependentFields;
