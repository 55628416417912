import React, { FC, useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from '@jmjfinancial/apis/lib';
import { DataContext } from '../../../context/dataContext';
import LoaderOverlay from '../../../components/LoaderOverlay/LoaderOverlay';
import AddCoApplicantTableView from './AddCoApplicantTableView';
import EditAddCoApplicantForm from './EditAddCoApplicantForm';
import AddCoApplicantConcierge from './AddCoApplicantConcierge';
import { useHistory } from 'react-router-dom';
import { Button, Modal } from 'semantic-ui-react';
import _ from 'lodash';

const AddCoApplicantV2: FC = observer(() => {
  const store = useStore();
  const history = useHistory();
  const { loansService } = store;
  const {
    activeLoan,
    setDarkThemeOverride,
    setOverrideTrackerVisibility,
    reviewMode,
    coapplicantMode,
  } = useContext(DataContext);

  const [isLoading, setIsLoading] = useState(true);
  const [loanData, setLoanData] = useState<any>();
  const [refreshAddCoApplicantView, setRefreshAddCoApplicantView] = useState(false);

  const [showCoApplicantForm, setShowCoApplicantForm] = useState(false)
  const [isSpouse, setIsSpouse] = useState(true)
  const [showDuplicateAddCoApplicantForm, setShowDuplicateAddCoApplicantForm] = useState(false);
  const [coapplicantCount, setCoapplicantCount] = useState<number>(0);
  const [conciergeMode, setConciergeMode] = useState(!(loanData?.coapplicants.length > 0))
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
  const [modalErrorMessage, setModalErrorMessage] = useState<string>('');

  setDarkThemeOverride(true)
  setOverrideTrackerVisibility(true)

  const AddCoApplicantView: FC = () => {

    // display 2nd concierge view immediately after Spouse question asked
    if ((loanData.coapplicants.length > 0 && !showDuplicateAddCoApplicantForm) || coapplicantCount >= 4 || coapplicantMode) {
      console.log('AddCoApplicantView: loanData.coapplicants.length > 0 && !showDuplicateAddCoApplicantForm ', loanData.coapplicants.length > 0, !showDuplicateAddCoApplicantForm)
      return (
        <div className="application-step-container add-co-applicant-form">
          <AddCoApplicantTableView
            loanData={loanData}
            isSpouse={isSpouse}
            setIsSpouse={setIsSpouse}
            setShowCoApplicantForm={setShowCoApplicantForm}
            coapplicantCount={coapplicantCount}
            setCoapplicantCount={setCoapplicantCount}
            conciergeMode={conciergeMode}
            setRefreshAddCoApplicantView={setRefreshAddCoApplicantView}
            setShowErrorModal={setShowErrorModal}
            setModalErrorMessage={setModalErrorMessage}
            coapplicantMode={coapplicantMode}
          />
        </div>
      )
    }
    // If a user clicks NO for "will your spouse be joining" and then YES for "will anyone else be joining"
    else if (showCoApplicantForm) {
      console.log('AddCoApplicantView: showCoApplicantForm ', showCoApplicantForm)
      return (
        <div className="application-step-container add-co-applicant-form">
          <EditAddCoApplicantForm
            loanData={loanData}
            isSpouse={isSpouse}
            setIsSpouse={setIsSpouse}
            setShowCoApplicantForm={setShowCoApplicantForm}
            coapplicantCount={coapplicantCount}
            setCoapplicantCount={setCoapplicantCount}
            setShowForm={() => { }}
            saveForm={() => { }}
            conciergeMode={conciergeMode}
          />
        </div>
      )
    }
    else {
      return (
        <AddCoApplicantConcierge
          isSpouse={isSpouse}
          setIsSpouse={setIsSpouse}
          setShowCoApplicantForm={setShowCoApplicantForm}
          setShowDuplicateAddCoApplicantForm={setShowDuplicateAddCoApplicantForm}
          setRefreshAddCoApplicantView={setRefreshAddCoApplicantView}
        />
      )
    }
  }

  useEffect(() => {
    console.log('index useEffect()')
    setIsLoading(true)
    loansService.getLoan(activeLoan).then(l => {
      const loan = l.data.form_data
      const spouse = (loan.borrower.marital_status_type === 'married' && loan.coapplicants.length === 0)

      setLoanData(loan)
      console.log({loan})

      setCoapplicantCount(loan.coapplicants.length)
      setConciergeMode(!(loan.coapplicants?.length > 0))

      setRefreshAddCoApplicantView(false)

      setIsSpouse(spouse)

      setIsLoading(false)


      // Checks to see if user has already selected "No" for "additional" applicants
      // If so, route to next step
      if (loan.coapplicants.length === 0 && !isSpouse && !showCoApplicantForm) {
        const updatedLoan = {
          application: {
            completed_steps: {
              add_co_applicant_done: true
            }
          }
        }

        const dataSubmit = _.merge(loanData, updatedLoan)
        setIsLoading(true)
        loansService.updateLoan(activeLoan, dataSubmit).then(() => {
          setIsLoading(false)

          if (reviewMode) {
            history.push('/application/review-and-submit')
          }
          else {
            history.push('/application/residences')
          }
        }, () => setIsLoading(false))

      }
    })
  }, [loansService, activeLoan, refreshAddCoApplicantView, coapplicantCount >= 4])

  return (
    <>
      <LoaderOverlay active={isLoading} />
      {!isLoading && (
        <>
          <AddCoApplicantView />
          {showErrorModal && (
            <Modal
              onClose={() => setShowErrorModal(false)}
              onOpen={() => setShowErrorModal(true)}
              open={showErrorModal}
              className="error-modal add-co-applicant-modal"
            >
              <Modal.Content>
                <span className="title text-light-blue">Unable to Add Co-Applicant</span>
                <span className="error-message subtitle">{modalErrorMessage}</span>
                <Button
                  color="blue"
                  className="form-save-button"
                  onClick={() => setShowErrorModal(false)}
                  type="button"
                >
                  Continue
                </Button>
              </Modal.Content>
            </Modal>
          )}
        </>
      )}
    </>
  )
})

export default AddCoApplicantV2
