import React, { FC, useState } from 'react';
import { Button, Confirm, Icon, Modal, Table } from 'semantic-ui-react';
import { tableValuesPipe } from '../../helpers/table-values-pipe';
import './add-remove-table.scss';

export interface TableRow {
  [key: string]: any;
}

export interface HeaderLabels {
  text: string;
  value: string;
}

interface AddRemoveTableProps {
  headerLabels: string[];
  table: Array<TableRow>;
  removeTableRow?: Function;
  editTableRow?: Function;
  addButtonLabel?: string;
  showForm: boolean;
  setShowForm: Function;
  form: React.ReactNode;
  loanData: any;
  disableAddRow?: boolean
  coapplicantMode?: boolean
  tableData?: any;
}

export interface AddRemoveFormProps {
  setShowForm: Function;
  saveForm: Function;
  formData?: any;
}

const AddRemoveTable: FC<AddRemoveTableProps> = ({
  headerLabels,
  table,
  removeTableRow,
  editTableRow,
  addButtonLabel,
  showForm,
  setShowForm,
  form,
  loanData,
  disableAddRow,
  coapplicantMode,
  tableData
}) => {
  const [showRemoveConfirm, setShowRemoveConfirm] = useState(false);
  const [currentDeleteIndex, setCurrentDeleteIndex] = useState<any>();
  const [showModal, setShowModal] = useState<boolean>(false);

  const handleShowRemoveConfirm = () => {
    setShowRemoveConfirm(true)
  }

  const handleConfirmRemove = (index: number) => {
    setShowRemoveConfirm(false);
    removeTableRow && removeTableRow(index)
  }

  const handleCancelRemove = () => {
    setShowRemoveConfirm(false);
  }

  const handleDelete = (index: number) => {
    // console.log(tableData[index].property_id);
    if (tableData && tableData[index].property_id) {
      setShowModal(true);
    } else {
      handleShowRemoveConfirm()
      setCurrentDeleteIndex(index)
    }
  }

  return (
    <div className="add-remove-table-container">
      <Table
        basic='very'
        className="add-remove-table"
      >
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell />
            {headerLabels.map((label) => <Table.HeaderCell key={label}>{label}</Table.HeaderCell>)}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {table.map((row, index) => (
            <Table.Row
              key={`${JSON.stringify(row)}-${index}`}
              className={`${row.to_be_deleted ? 'to-be-deleted' : ''}`}
            >
              <Table.Cell collapsing>
                {removeTableRow && (
                  <Button
                    disabled={showForm || row.to_be_deleted}
                    basic
                    icon
                    className="remove-button borderless no-padding"
                    onClick={() => handleDelete(index)}
                  >
                    <Icon
                      size="large"
                      name="minus circle"
                    />
                  </Button>
                )}
                {editTableRow && (
                  <Button
                    disabled={showForm || row.to_be_deleted}
                    basic
                    icon
                    className="edit-button borderless no-padding"
                    onClick={() => editTableRow(index)}
                  >
                    <Icon
                      size="large"
                      name="edit outline"
                    />
                    <span className="edit-text">Edit</span>
                  </Button>
                )}
              </Table.Cell>
              {Object.entries(row).map(([key, value], index) => {
                // Only display as many table cells as there are header cells
                return index < headerLabels.length ? (
                  <Table.Cell key={`${key?.toString()}-${index}`}>
                    {tableValuesPipe(key, value, loanData)}
                  </Table.Cell>
                ) : null
              })}
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      <div className="add-remove-mobile-container">
        {table.map((row, rowIndex) => (
          <div
            key={`${JSON.stringify(row)}-${rowIndex}`}
            className={`
              summary-section
              ${editTableRow || removeTableRow ? ' has-footer' : ''}
              ${row.to_be_deleted ? ' to-be-deleted' : ''}
            `}
          >
            <div className="details-container">
              {Object.entries(row).map(([key, value], objectIndex) => {
                return objectIndex < headerLabels.length ? (
                  <div
                    key={`${key?.toString()}-${objectIndex}`}
                    className="detail"
                  >
                    <label>{headerLabels[objectIndex]}</label>
                    <span className={`value ${key}`}>{tableValuesPipe(key, value, loanData) || '-'}</span>
                  </div>
                ) : null
              })}
            </div>
            <div className="summary-section-footer">
              {editTableRow && (
                <Button
                  type="button"
                  className="alternate-button"
                  onClick={() => editTableRow(rowIndex)}
                  disabled={showForm || row.to_be_deleted}
                >
                  Edit
                </Button>
              )}
              {removeTableRow && (
                <Button
                  type="button"
                  className="alternate-button remove-button"
                  onClick={() => handleDelete(rowIndex)}
                  disabled={showForm || row.to_be_deleted}
                >
                  Remove
                </Button>
              )}
            </div>
          </div>
        ))}
      </div>
      <Modal
        open={showModal}
        size="small"
        dimmer="blurring"
        className="error-modal"
      >
        <Modal.Content>
          <span className="title text-light-blue">Error Deleting Residence</span>
          <span className='error-message subtitle'>Unable to delete residence being refinanced</span>
          <Button
            color="blue"
            onClick={() => setShowModal(false)}
            type="button"
          >
            Continue
          </Button>
        </Modal.Content>
      </Modal>
      <Confirm
        className="confirmation-modal add-remove-confirmation"
        header="Are you sure you want to remove this row?"
        content={`This row will be permanently deleted when you click "Save & Continue"`}
        confirmButton="Remove"
        size="tiny"
        open={showRemoveConfirm}
        onCancel={handleCancelRemove}
        onConfirm={() => handleConfirmRemove(currentDeleteIndex)}
      />
      {addButtonLabel && (
        <Button
        disabled={showForm || disableAddRow || coapplicantMode}
        basic
        compact
        labelPosition="right"
        className="add-button"
        onClick={() => setShowForm(true)}
        >
          <Icon
            size="large"
            name="plus circle"
          />
          {addButtonLabel}
        </Button>  
      )}
      {showForm ? form : null}
    </div>
  );
}

export default AddRemoveTable;
